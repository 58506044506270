import React, { useEffect, useState } from 'react'
import TokenPackage from '../../models/tokenPakage.model'
import { checkPromoCodeToken } from '../../services/shop.services'
import ErrorMessageToast from '../Items/ErrorMessageToast'
import SuccessMessageToast from '../Items/SuccessMessageToast'
import { useAuth } from '../../providers/AuthProvider'

interface Props {
  planDetails: TokenPackage
  onUpdatePrice: (newPrice: string) => void // New prop to update price
  onUpdatePromo: (promo: string) => void
}

const PlanTokenCard: React.FC<Props> = ({
  planDetails,
  onUpdatePrice,
  onUpdatePromo,
}) => {
  const { userProfile } = useAuth()
  const [finalPrice, setFinalPrice] = useState(planDetails.tkPkgPrice)
  const [promoCode, setPromoCode] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  useEffect(() => {
    onUpdatePromo(promoCode.trim())
  }, [promoCode, onUpdatePromo])

  const handleApplyPromo = async () => {
    if (userProfile && userProfile.userId) {
      try {
        const response = await checkPromoCodeToken(
          userProfile.userId,
          promoCode.trim(),
          planDetails.tkPkgId,
        )
        if (response.data.success) {
          setFinalPrice(response.data.productFinalPrice)
          onUpdatePrice(response.data.productFinalPrice)
          setSuccessMsg(response.data.message)
        } else {
          setErrorMsg(response.data.message)
        }
      } catch (error: any) {
        if (error instanceof TypeError) {
          setErrorMsg(error.message)
        } else {
          setErrorMsg('Something wrong!')
        }
      } finally {
        setTimeout(() => {
          setErrorMsg('')
          setSuccessMsg('')
        }, 2000)
      }
    } else {
      // console.log(userProfile)
      setErrorMsg('Something wrong!')
      setTimeout(() => {
        setErrorMsg('')
      }, 2000)
    }
  }

  return (
    <>
      <div className="products__card w-full lg:min-h-[10.5rem] h-full flex items-center">
        <div className="product__card__info lg:w-[30.063rem] lg:h-[10.5rem] h-full w-full bg-[#FAFAFA] p-4 rounded-lg flex flex-col gap-2">
          <div className="product__card__info__title flex justify-between items-center lg:gap-0 gap-2">
            <p className="text-h4m">{planDetails.tkPkgName}</p>
            <p className="text-h4m text-info">
              <span className="text-h5m text-[#00000040] line-through mr-[0.4rem]">
                {planDetails.tkPkgPlatformPrice}
              </span>
              {`${finalPrice} AUD`}
            </p>
          </div>
          <div className="product__card__info__description">
            <p className="text-bodyr text-neutrals-2">
              {/* Type: {planDetails.product_type_name} */}
            </p>
            {/* <p className="text-bodyr text-neutrals-2">{planDetails.prodcredits_name}</p> */}
          </div>
          <div className="product__card__promo-code flex gap-4">
            <input
              type="text"
              placeholder="Promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className="lg:min-w-[22.25rem] w-3/4 h-[2.75rem] border border-1 border-[#0000000F] rounded-lg p-[7px] cart-promo-code"
            />
            <button
              onClick={handleApplyPromo}
              className="lg:min-w-[4.813rem] w-1/4 h-[2.75rem] rounded-lg text-white text-bodyr bg-primary-9 hover:bg-[#00354A] disabled:bg-[#D3D3D3] disabled:cursor-not-allowed"
              disabled={!promoCode.trim()}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      {successMsg !== '' && <SuccessMessageToast message={successMsg} />}
      {errorMsg !== '' && <ErrorMessageToast message={errorMsg} />}
    </>
  )
}

export default PlanTokenCard
