import ChangePasswordForm from '../../components/LandingPage/Auth/ChangePasswordForm'

const ChangePasswordPage: React.FC = () => {
  return (
    <div className="login-section h-full bg-[#F5F5F5] flex flex-col justify-center items-center lg:py-0 py-[2rem] lg:px-0 px-[1rem]">
      <p className="text-h1 font-landingLogo text-center mb-4">
        Change Password
      </p>

      <ChangePasswordForm />
    </div>
  )
}

export default ChangePasswordPage
