import PasswordForm from '../../components/LandingPage/Auth/PasswordForm'

const PasswordPage: React.FC = () => {
  return (
    <div className="forgot_password-section h-[70vh] bg-[#F5F5F5] flex flex-col justify-center items-center">
      <p className="lg:text-h1 text-h2 font-landingLogo text-center mb-4">
        Forgot Password
      </p>
      <PasswordForm />
    </div>
  )
}

export default PasswordPage
