import { Link } from 'react-router-dom'
import PracticePerformanceSection from '../components/PracticePerformancePage/PracticePerformanceSection'

const PracticePerformancePage = () => {
  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Practice Performance
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] min-h-[30rem] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <PracticePerformanceSection />
      </div>
    </>
  )
}

export default PracticePerformancePage
