import axiosInstance from '../configs/axiosConfig'

export function getShopData(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/shop/shop-data`, data)
}

export function getTokenPacksMock(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/shop/token-packs-mock`, data)
}

export function getShopDeals(userId: number) {
  const data = {
    user_id: userId,
  }

  return axiosInstance.post(`/shop/shop-deals`, data)
}

export function checkoutStripe(userId: number, paymentMethodId: string) {
  const data = {
    userId: userId,
    paymentMethodId: paymentMethodId,
  }

  return axiosInstance.post(`/shop/checkout-stripe`, data)
}

export function getTokenPackage() {
  return axiosInstance.get(`/shop/token-package`)
}

export function getPlanDetails(planId: number) {
  return axiosInstance.get(`/shop/plan-details/${planId}`)
}

export function checkPromoCodeToken(
  userId: number,
  promoCode: string,
  test_id: number,
) {
  const data = {
    user_id: userId,
    promo: promoCode,
    test_id: test_id,
  }

  return axiosInstance.post(`/shop/check-promoCode-token`, data)
}

export function buySingleTokenStripe(
  tokenId: number,
  userId: number,
  promoCode: string,
  token: string,
) {
  const data = {
    token_id: Number(tokenId),
    user_id: Number(userId),
    code: promoCode,
    stripe_token: token,
  }

  return axiosInstance.post(`/shop/token-stripe-buy-now`, data)
}

export function createPlanOrder(
  tokenId: number,
  userId: number,
  promoCode: string,
) {
  const data = {
    token_id: Number(tokenId),
    user_id: Number(userId),
    code: promoCode,
  }

  return axiosInstance.post(`/shop/token-paypal-buy-now`, data)
}

export function checkoutStripeDeal(
  user_id: number,
  dealid: number,
  stripe_token: string,
) {
  const data = {
    user_id: Number(user_id),
    dealid: Number(dealid),
    stripe_token: stripe_token,
  }

  return axiosInstance.post(`/shop/checkout-stripe-deal`, data)
}
