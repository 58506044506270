import { useEffect, useState } from 'react'
import MyUnlockedTestsSection from '../components/MyUnlockedTestsPage/MyUnlockedTestsSection'
import MockTestSection from '../components/PurchasePage/MockTestSection'
import {
  getMyUnlockedTest,
  getMockTestListToUnlock,
} from '../services/purchase.services'
import { useAuth } from '../providers/AuthProvider'
import Test from '../models/test.model'

const MyUnlockedTestsPage = () => {
  const [unlockedTests, setUnlockedTests] = useState<Test[]>([])
  const [mockCredits, setMockCredits] = useState<number>(0)
  const [mockTests, setMockTests] = useState<Test[]>([]) // State for mock tests
  const { userProfile } = useAuth()

  // Fetch unlocked tests
  const fetchUnlockedTests = () => {
    if (userProfile?.userId) {
      getMyUnlockedTest(userProfile.userId)
        .then((response) => {
          setUnlockedTests(response.data.test)
          setMockCredits(response.data.mockCredits)
        })
        .catch((error) => {
          console.error('Error fetching unlocked tests:', error)
        })
    }
  }

  // Fetch mock tests
  useEffect(() => {
    if (userProfile?.userId) {
      getMockTestListToUnlock(userProfile.userId, 0)
        .then((response) => {
          setMockTests(response.data.test) // Set mock tests
        })
        .catch((error) => {
          console.error('Error fetching mock tests:', error)
        })
    }
  }, [userProfile])

  // Fetch unlocked tests on page load
  useEffect(() => {
    fetchUnlockedTests()
  }, [userProfile])

  // Filter out unlocked tests
  const filteredMockTests = mockTests.filter(
    (mockTest) =>
      !unlockedTests.some((unlockedTest) => unlockedTest.id === mockTest.id),
  )

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          Mock Test / My Unlocked Tests
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <MyUnlockedTestsSection
          tests={unlockedTests}
          mockCredits={mockCredits}
        />

        <div className="mt-8">
          <MockTestSection
            mockTests={filteredMockTests}
            onTestUnlock={fetchUnlockedTests}
          />
        </div>
      </div>
    </>
  )
}

export default MyUnlockedTestsPage
