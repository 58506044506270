import React from 'react'
import TextInput from '../../components/FormElements/TextInput'
import Dropdown from '../../components/FormElements/Dropdown'
import RadioGroup from '../../components/FormElements/RadioGroup'
import { ReactComponent as EnvelopIcon } from '../../images/Landingpage/ContactUspage/envelop-contact-page.svg'
import { ReactComponent as PhoneIcon } from '../../images/Landingpage/ContactUspage/phone-contact-page.svg'
import { ReactComponent as LocationIcon } from '../../images/Landingpage/ContactUspage/location-contact-page.svg'

const ContactUsPage: React.FC = () => {
  return (
    <div className="my-[80px] flex justify-center items-center p-6">
      <div className="w-full lg:max-w-[70%] bg-white rounded-lg lg:p-8 flex flex-col lg:flex-row gap-8">
        {/* Contact Information Section */}
        <div className="w-full md:w-1/2">
          <h2 className="text-h1 mb-[32px]">Contact us</h2>
          <div className="space-y-6 lg:w-[55%] w-full">
            <div className="flex items-start">
              <div className="text-blue-500 mr-4">
                {/* Location Icon */}
                <LocationIcon className="h-6 w-6" />
              </div>
              <div>
                <p className="text-h5m mb-[8px]">Sydney Office</p>
                <p className="text-bodyr text-[#8C8C8C]">
                  Level 36 Gateway, 1 Macquarie Place Sydney NSW 2000
                </p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="text-blue-500 mr-4">
                {/* Phone Icon */}
                <PhoneIcon className="h-6 w-6" />
              </div>
              <div>
                <p className="text-h5m mb-[8px]">Contact number</p>
                <p className="text-bodyr text-[#8C8C8C]">0411 703 756</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="text-blue-500 mr-4">
                {/* Email Icon */}
                <EnvelopIcon className="h-6 w-6" />
              </div>
              <div>
                <p className="text-h5m mb-[8px]">Email Address</p>
                <p className="text-bodyr text-[#8C8C8C]">
                  admin@masterpte.com.au
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/2">
          <h2 className="text-h4m mb-[24px]">Basic information</h2>
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <TextInput placeholder="Full name" label="Full name" />
              <TextInput
                placeholder="Enter email address"
                type="email"
                label="Email"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <TextInput
                placeholder="Type password"
                type="password"
                label="Password"
              />
              <TextInput
                placeholder="Confirm Password"
                type="password"
                label="Confirm Password"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <TextInput
                placeholder="eg. 123456789"
                type="tel"
                label="Phone Number"
              />
              <Dropdown
                label="Select Course Type"
                defaultText="Choose appointment time"
                options={['Morning', 'Afternoon']}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Dropdown
                defaultText="Target points"
                label="Target Score"
                options={['50', '65', '79']}
              />
              <RadioGroup
                question="Have you ever taken the PTE before?"
                name="pte"
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
              />
            </div>
            <button
              type="submit"
              className="text-white !mt-[32px] px-6 py-[10px] rounded-[8px] w-full"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              Contact
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUsPage
