import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AvatarSvg from '../../images/Navbar/navbar-profile.svg'
import PointSvg from '../../images/Speaking/point.svg'
import ArrowDown from '../../images/Speaking/arrow-down.svg'
import MultipleMCQuestion from '../../components/ReadingCourse/MultipleMCQuestion'
import { useAuth } from '../../providers/AuthProvider'
import { getUserTokenDetails } from '../../services/user.services'
import Path from '../../routes/Path'
import { navigateUserToPlanPage } from '../../utils/utils'

interface Answer {
  name: string
  overall: number
  flunency: number
  pronunciation: number
  vocabulary: number
  grammar: number
  comment: string
}

const topAnswers: Answer[] = [
  {
    name: 'Bhavesh Panchani',
    overall: 90,
    flunency: 85,
    pronunciation: 85,
    vocabulary: 85,
    grammar: 85,
    comment:
      'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu',
  },
  {
    name: 'Bhavesh Panchani',
    overall: 80,
    flunency: 65,
    pronunciation: 65,
    vocabulary: 65,
    grammar: 65,
    comment:
      'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu',
  },
  {
    name: 'Bhavesh Panchani',
    overall: 70,
    flunency: 75,
    pronunciation: 75,
    vocabulary: 75,
    grammar: 75,
    comment:
      'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quNeque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed qu',
  },
]

const MultipleAnswerMC = () => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()
  }, [userProfile, navigate])

  const [activeCategory, setActiveCategory] = useState('Top Answers')
  const [showScoreBoard, setShowScoreBoard] = useState(false)
  const [detailsVisible, setDetailsVisible] = useState(
    Array(topAnswers.length).fill(false),
  )

  const toggleDetails = (index: number) => {
    const updatedVisibility = [...detailsVisible]
    updatedVisibility[index] = !updatedVisibility[index]
    setDetailsVisible(updatedVisibility)
  }

  return (
    <div>
      <MultipleMCQuestion
        setShowScoreBoard={setShowScoreBoard}
        showScoreBoard={showScoreBoard}
      />
      {showScoreBoard && (
        <div className="">
          <div className="flex my-4 rounded-xl bg-gray-100 p-1 mx-auto w-[90%] xl:w-[60%]">
            <button
              className={`flex-1 p-2 text-center rounded-xl transition-colors duration-300 cursor-pointer ${
                activeCategory === 'Top Answers'
                  ? 'bg-primary-6 text-primary-1'
                  : 'bg-transparent text-neutrals-1'
              }`}
              onClick={() => setActiveCategory('Top Answers')}
            >
              Top Answers
            </button>
            <button
              className={`flex-1 p-2 text-center rounded-xl transition-colors duration-300 cursor-pointer ${
                activeCategory === 'Best Verified Answer'
                  ? 'bg-primary-6 text-primary-1'
                  : 'bg-transparent text-neutrals-1'
              }`}
              onClick={() => setActiveCategory('Best Verified Answer')}
            >
              Best Verified Answer{' '}
            </button>
          </div>
          {topAnswers.map((answer, index) => (
            <div
              key={index}
              className={`p-8 shadow my-8 border border-[1px]-[#DFE3E6] rounded-lg
            ${
              index % 3 === 0
                ? 'shadow-[6px_6px_0_#FFBA68]'
                : index % 3 === 1
                  ? 'shadow-[6px_6px_0_#14AE5C]'
                  : 'shadow-[6px_6px_0_#F42424]'
            }
            `}
            >
              <div className="flex justify-between">
                <div className="flex items-center">
                  <img src={AvatarSvg} alt="avatar" className="mr-2" />

                  <div>
                    <p className="text-h5m">{answer.name}</p>
                    <p
                      className="flex text-bodyr text-neutrals-2 cursor-pointer"
                      onClick={() => toggleDetails(index)}
                    >
                      <img src={ArrowDown} alt="arrow-down" className="mr-2" />
                      Show Details
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <img src={PointSvg} alt="point" className="h-full mr-2" />
                  <div>
                    <p className="text-footnote">Points</p>
                    <p className="text-h2">{answer.overall}</p>
                  </div>
                </div>
              </div>
              <audio
                controls
                src="https://ptefilesbucket-stg.s3.amazonaws.com/test_question/0/6442820258e0a.wav"
                className="w-full mt-4"
              />

              {detailsVisible[index] && (
                <>
                  <div className="w-full grid grid-cols-4 gap-2 items-center my-4">
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#FF7A45] drop-shadow-lg">
                        {answer.flunency}/90
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">Fluency</p>
                    </div>
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#597EF7] drop-shadow-lg">
                        {answer.pronunciation}/90
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">
                        Pronunciation
                      </p>
                    </div>
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#722ED1] drop-shadow-lg">
                        {answer.vocabulary}/90
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">
                        Vocabulary
                      </p>
                    </div>
                    <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                      <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#EB2F96] drop-shadow-lg">
                        {answer.grammar}/90
                      </div>
                      <p className="text-bodyr text-neutrals-1 mt-2">Grammar</p>
                    </div>
                  </div>

                  <p className="text-bodyr text-neutrals-1">{answer.comment}</p>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MultipleAnswerMC
