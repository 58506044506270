import React, { useState } from 'react'
import { ReactComponent as DurationIcon } from '../../images/Purchase/purchase-duration-icon.svg'
import { ReactComponent as QuestionIcon } from '../../images/Purchase/purchase-question-icon.svg'
import PurchaseBanner from '../../images/Purchase/purchase-banner.png'
import ErrorMessage from '../Items/ErrorMessage'
import PracticeToken from '../../models/practiceToken.model'
import { activeToken } from '../../services/purchase.services'
import { toastError, toastSuccess } from '../../utils/utils'

interface PracticeTokenSectionProps {
  practiceTokens: PracticeToken[]
}

const PracticeTokenSection: React.FC<PracticeTokenSectionProps> = ({
  practiceTokens,
}) => {
  const [disabledTokens, setDisabledTokens] = useState<number[]>([]) // Track disabled token IDs

  const filteredItems: PracticeToken[] = practiceTokens.filter(
    (item) => item.activestatus === 0,
  )

  const displayDuration = (pack: PracticeToken): string | undefined => {
    if (pack.tokentype === 0) {
      return pack.tokenTypeDuration
    } else if (pack.tokentype === 1) {
      if (pack.tokenTypeDurationHours <= 24) {
        return `${pack.tokenTypeDurationHours} Hour(s)`
      } else if (
        pack.tokenTypeDurationByDay < 30 &&
        pack.tokenTypeDurationHours > 24
      ) {
        return `${pack.tokenTypeDurationByDay} Day(s)`
      } else if (pack.tokenTypeDurationByDay >= 30) {
        return `${pack.tokenTypeDurationByMonth} Month(s)`
      }
    }
    return undefined
  }

  const handleActiveToken = async (
    userId: number,
    time: number,
    id: number,
  ) => {
    try {
      setDisabledTokens((prev) => [...prev, id]) // Disable button immediately
      const response = await activeToken(userId, time, id)
      if (response.data.success) {
        toastSuccess(response.data.message)
        // Refresh after 3 seconds
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        toastError(response.data.message)
        setDisabledTokens((prev) => prev.filter((tokenId) => tokenId !== id)) // Re-enable button on failure
      }
    } catch (error) {
      console.error(error)
      toastError('Something went wrong!')
      setDisabledTokens((prev) => prev.filter((tokenId) => tokenId !== id)) // Re-enable button on error
    }
  }

  return (
    <div className="purchase-section__practice-token font-landingPrimary w-full flex flex-wrap gap-8">
      {filteredItems.length === 0 ? (
        <ErrorMessage message="You have no items to show." />
      ) : (
        filteredItems.map((pack: PracticeToken) => (
          <div
            key={pack.id}
            className="purchase-section__practice-token__item lg:w-[18.75rem] w-full lg:min-h-[25.125rem] h-full rounded-lg shadow-purchase-card"
          >
            <div className="practice-token__item__image w-full h-[14.375rem] bg-[#D9D9D9] rounded-t-lg relative">
              <img
                src={PurchaseBanner}
                alt="Purchase banner"
                className="object-fill w-full h-full rounded-t-lg"
              />
              <div className="practice-token__item__sale-off w-[5.25rem] h-[1.25rem] rounded-[6.25rem] bg-[#A0D911] absolute top-4 right-4">
                <p className="text-footnote text-center">50% Sale Off</p>
              </div>
            </div>
            <div className="practice-token__item__title mt-[1rem] mb-[0.6rem] px-2">
              <p className="text-h4m">{pack.tokenpackname}</p>
            </div>
            <div className="practice-token__item__info flex flex-col gap-2">
              <div className="practice-token__item__info__duration flex gap-2 px-2 items-center">
                <DurationIcon />
                <p className="text-bodyr text-neutrals-2">
                  {displayDuration(pack)}
                </p>
              </div>
              <div className="practice-token__item__info__duration flex gap-2 px-2 items-center">
                <QuestionIcon />
                <p className="text-bodyr text-neutrals-2">
                  {pack.tokenTypeName}
                </p>
              </div>
            </div>
            {pack.tokentype === 1 && (
              <div className="practice-token__item__activate w-full px-2 my-[1rem]">
                <button
                  onClick={() =>
                    handleActiveToken(
                      pack.userid,
                      pack.timecount / 3600,
                      pack.id,
                    )
                  }
                  disabled={disabledTokens.includes(pack.id)} // Disable the button if it is in the disabledTokens array
                  className={`w-full h-[2.5rem] ${
                    disabledTokens.includes(pack.id)
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-info hover:bg-[#1890FF]'
                  } text-bodyr text-white rounded-lg flex items-center justify-center`}
                >
                  {disabledTokens.includes(pack.id)
                    ? 'Activated'
                    : 'Click to activate'}
                </button>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  )
}

export default PracticeTokenSection
