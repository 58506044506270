import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from './NavBar'
import SideBar from './SideBar'
import Footer from './Footer'

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation()
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)

  const noSidebarRoutes = [
    '/weekly-prediction/speaking',
    '/weekly-prediction/reading',
    '/weekly-prediction/writing',
    '/weekly-prediction/listening',
    '/sectional-test/speaking',
    '/sectional-test/reading',
    '/sectional-test/writing',
    '/sectional-test/listening',
    '/mock-test/speaking',
    '/mock-test/reading',
    '/mock-test/writing',
    '/mock-test/listening',
    '/practice/speaking/',
    '/practice/writing/',
    '/practice/reading/',
    '/practice/listening/',
  ]

  const showSidebar = !noSidebarRoutes.some((route) =>
    location.pathname.startsWith(route),
  )

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  return (
    <div className="flex flex-col ">
      <Navbar />
      <div className="flex">
        {/* Sidebar Section */}
        {showSidebar && (
          <div
            className={`shrink-0 hidden lg:block bg-white transition-all duration-300 ease-in-out ${
              isSidebarCollapsed ? 'w-20' : 'w-64'
            }`}
          >
            <SideBar
              isCollapsed={isSidebarCollapsed}
              toggleSidebar={toggleSidebar}
            />
          </div>
        )}

        {/* Main Content Section */}

        <div className="max-w-full flex-1 transition-all duration-300 ease-in-out bg-[#FAFAFA]">
          <main
            className={`${showSidebar ? '' : 'm-2 md:m-8 p-4 md:px-[15%] md:py-[1%] rounded-lg bg-white content-center'}`}
          >
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Layout
