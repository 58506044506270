import { useState, useEffect, useRef } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  getAllMockQuestions,
} from '../../services/practice.services'
import {
  handleExceptionError,
  navigateUserToPlanPage,
  toastError,
} from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import Option from '../../models/option.model'
import MockQuestion from '../../models/mockQuestion.model'
import ReadingQuestionStates from './ReadingQuestionStates'
import Path from '../../routes/Path'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getUserTokenDetails } from '../../services/user.services'
import { QuestionModelId } from '../../models/QuestionModels'

const FillInTheBlankQuesiton = () => {
  const [questionId, setQuestionId] = useState<string | null>(
    localStorage.getItem('questionIdPTE'),
  )

  const navigate = useNavigate()
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [originalDescription, setOriginalDescription] = useState('')
  const [options, setOptions] = useState<Option[]>([])
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: number]: string
  }>({})
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const { userProfile } = useAuth()
  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)

  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()

    const fetchData = async () => {
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.R_ReadingAndWritingFillInTheBlanks
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setOptions(response.data.option)
              setOriginalDescription(mockQuestions[0].description || '')

              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }

    fetchData()
  }, [userProfile, questionId, navigate])

  // Helper function to filter options by sequence
  const getOptionsBySequence = (sequence: number) => {
    return options
      .filter((opt) => opt.sequence === sequence)
      .map((opt) => (
        <option key={opt.id} value={opt.options}>
          {opt.options}
        </option>
      ))
  }

  // Handle change in selected option
  const handleSelectChange = (sequence: number, value: string) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [sequence]: value, // Update the selected answer for the given sequence
    }))
  }

  // Helper function to replace placeholders with <select> elements
  const generateFillInTheBlanks = (text: string): JSX.Element[] => {
    const parts = text.split(/\{(\d+)\}/g)

    return parts.map((part, index) => {
      if (!isNaN(Number(part))) {
        const sequenceNumber = Number(part)
        // If part is a number, render a <select> element
        return (
          <select
            key={index}
            value={selectedAnswers[sequenceNumber] || ''}
            onChange={(e) => handleSelectChange(sequenceNumber, e.target.value)}
            style={{
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '5px',
              margin: '0 5px',
              color: '#004cd9',
              fontWeight: 'bold',
            }}
            disabled={isSubmitted}
          >
            <option value="">-- Select --</option>
            {getOptionsBySequence(sequenceNumber)}
          </select>
        )
      }
      // Otherwise, return the text part as is
      return <span key={index}>{part}</span>
    })
  }

  // Function to check if all options are filled
  const areAllOptionsFilled = () => {
    const placeholderMatches =
      question && question.description
        ? question.description.match(/\{(\d+)\}/g)
        : []

    // Count of placeholders
    const placeholderCount = placeholderMatches ? placeholderMatches.length : 0

    // Compare the number of selected answers with total placeholders
    return (
      Object.keys(selectedAnswers).length === placeholderCount &&
      Object.values(selectedAnswers).every((value) => value !== '')
    )
  }

  const displayOriginalAnswer = (): string => {
    const originalAnswers = options
      .filter((item) => item.correct === 1) // Filter correct answers
      .map((item) => item.options) // Get the options of correct answers

    return originalAnswers.reduce((updatedText, originalAnswer, index) => {
      // Construct the placeholder for each answer (e.g., {1}, {2})
      const placeholder = `{${index + 1}}`

      // Replace the placeholder with the styled answer
      return updatedText.replace(
        placeholder,
        `<span class="font-bold text-green-600 bg-green-200">${originalAnswer.trim()}</span>`,
      )
    }, originalDescription)
  }

  const displayUserAnswer = (): string => {
    const originalAnswers = options
      .filter((item) => item.correct === 1) // Filter correct answers
      .map((item) => item.options)

    const userAnswers = Object.values(selectedAnswers)

    return userAnswers.reduce((updatedText, answer, index) => {
      const placeholder = `{${index + 1}}`
      let conditionDisplayRender = ''
      if (originalAnswers[index].trim() === answer.trim()) {
        conditionDisplayRender = `<span class="font-bold text-green-600 bg-green-200">${answer.trim()}</span> `
      } else {
        conditionDisplayRender = `<span class="font-bold text-red-600 bg-red-200">${answer.trim()}</span> `
      }

      return updatedText.replace(placeholder, conditionDisplayRender)
    }, originalDescription)
  }

  const resetState = () => {
    setIsSubmitted(false)
    setSelectedAnswers({})

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    setQuestionId(selectedQuestionId)
    resetState()
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      setQuestionId(String(previousQuestionId))
      resetState()
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      setQuestionId(String(nextQuestionId))
      resetState()
    }
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [isSubmitted])

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="px-6 py-10" ref={questionSectionRef}>
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link
              to={
                testType === 'practice'
                  ? Path.practice.path
                  : Path.weeklyPrediction.path
              }
            >
              Practice
            </Link>{' '}
            /{' '}
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              Reading Section
            </Link>{' '}
            /{' '}
            <span className="text-neutrals-1">
              Reading And Writing Fill In The Blanks
            </span>
          </p>
          <div className="w-fit">
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
                <ReturnArrowSvg fill="white" className="mr-2" />
                <span className="text-bodyr text-white">Return</span>
              </button>
            </Link>
          </div>
          <p className="text-bodym text-danger mt-2 md:mt-8">
            *This question carries marks for Reading only (~25%)
          </p>

          <div className="p-8 rounded-xl shadow mt-8">
            <p className="text-h4m text-neutrals-1">{question?.short_title}</p>
            <div className="text-h5r text-neutrals-1 pt-4 leading-10">
              {question && question.description
                ? generateFillInTheBlanks(question.description)
                : ''}
            </div>

            <div className="w-[90%] lg:w-[60%] pt-4 mx-auto">
              <div className="flex place-content-center	mt-8">
                <button
                  className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 ${!areAllOptionsFilled() || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                  onClick={() => setIsSubmitted(true)}
                  disabled={!areAllOptionsFilled() || isSubmitted}
                >
                  <img
                    src={WeeklyPredictionSubmitIcon}
                    alt="submit"
                    className="mr-2"
                  />
                  Submit
                </button>
              </div>
            </div>

            <ReadingQuestionStates question={question} />
          </div>

          <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              disabled={isFirstQuestion()}
              onClick={displayPreviousQuestion}
            >
              <PreviousArrowSvg
                fill={isFirstQuestion() ? 'gray' : 'white'}
                className="md:mr-2"
              />
              <p className="hidden md:block text-bodyr">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">
                  Try Again
                </p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              onClick={displayNextQuestion}
              disabled={isLastQuestion()}
            >
              <p className="hidden md:block text-bodyr">Next</p>
              <NextArrowSvg
                fill={isLastQuestion() ? 'gray' : 'white'}
                className="md:ml-2"
              />
            </button>
          </div>

          {isSubmitted && (
            <div
              ref={answerSectionRef}
              className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4"
            >
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Original Answer</p>
                <div className="mt-[1rem]">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: displayOriginalAnswer(),
                    }}
                  />
                </div>
              </div>
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Your Answer</p>
                <div className="mt-[1rem]">
                  <span
                    dangerouslySetInnerHTML={{ __html: displayUserAnswer() }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default FillInTheBlankQuesiton
