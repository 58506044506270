import React from 'react'
import OfferIcon1 from '../../images/Landingpage/WhyUs/offer1.png'
import OfferIcon2 from '../../images/Landingpage/WhyUs/offer2.png'
import OfferIcon3 from '../../images/Landingpage/WhyUs/offer3.png'
import OfferIcon4 from '../../images/Landingpage/WhyUs/offer4.png'
import OfferIcon5 from '../../images/Landingpage/WhyUs/offer5.png'
import WhyIcon1 from '../../images/Landingpage/WhyUs/why1.png'
import WhyIcon2 from '../../images/Landingpage/WhyUs/why2.png'
import WhyIcon3 from '../../images/Landingpage/WhyUs/why3.png'
import WhyIcon4 from '../../images/Landingpage/WhyUs/why4.png'
import AboutImagesSlide1 from '../../images/Landingpage/about-image-slide-1.png'
import WhyUsBanner from '../../images/Landingpage/WhyUs/why_us_banner.png'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'

const WhyUsPage: React.FC = () => {
  const reasonList = [
    {
      icon: WhyIcon1,
      title: 'Computer Based English Language Test',
      description:
        'PTE is a computer based english language proficiency test to assess their communication for Reading, writing, reading and listening done in a single sitting of 3 hours.',
    },
    {
      icon: WhyIcon2,
      title: 'Unbiased Scoring',
      description:
        "PTE is exam is a computer based person's English language test and assessed using automated scoring technology and it ensures impartial and accurate scoring.Test takers are provided with a pearson's score from 10-90 overall for all pte speaking , reading , writing & listening skills based on the Global Scale of English and have freedom to send your scores to as many organizations as you like, without an additional fee.",
    },
    {
      icon: WhyIcon3,
      title: 'Flexible Testing & Fast Results',
      description:
        'PTE Academic offers flexibility to schedule tests upto to 24 hours in advance and also candidates can give pte test from anywhere any time or at any date. PTE academics deliver fast & accurate results within five business days or within just 48 hours of taking the pte test.The pearson test of english is conducted over 360 days of the year in about 250 test centers across the globe.',
    },
    {
      icon: WhyIcon4,
      title: 'Acceptance For Study & Migration Applications',
      description:
        'PTE Academic Is trusted & accepted for academic study program applications by thousands of universities, colleges and governments around the world. It is also approved for all Australian and New Zealand student visa and migration applications.',
    },
  ]
  const offerList = [
    {
      icon: OfferIcon1,
      title: 'Real PTE practice software with authentic AI scoring mechanism',
    },
    {
      icon: OfferIcon2,
      title: 'Weekly prediction based on real exam questions',
    },
    {
      icon: OfferIcon3,
      title: 'New question banks updated daily',
    },
    {
      icon: OfferIcon4,
      title: 'Online chat with other classmates',
    },
    {
      icon: OfferIcon5,
      title:
        'Free Ebook full of tips, tricks and latest strategies to boost your score',
    },
  ]
  return (
    <div>
      <div className="bg-[#0C3CC6] py-12">
        <div className="mx-auto lg:w-[80%] lg:px-0 px-8 flex flex-col lg:flex-row gap-12">
          {/* Left Section */}
          <div className="lg:w-[50%] w-full text-white">
            <p className="text-h2 pb-4">AI Powered PTE Practice Software</p>
            <p className="text-bodyr">
              We offer a unique online PTE academic preparation software to all
              students who are willing to study hard and achieve a high score to
              fulfil their dream of studying and working abroad. The platform is
              designed to mimic the same real exam environment, powered by
              powerful AI that can deliver frictionless scoring without delay in
              all 4 modules of PTE Academic which include Speaking, Writing,
              Reading and Listening.
            </p>
            <p className="p-4 bg-[#325DDA] rounded-md mt-8">
              "Discover Internationally Recognized & Innovative Degree Programs
              with PTE Academic to expand your career horizon."
            </p>
          </div>

          {/* Right Section */}
          <div className="lg:w-[50%] w-full">
            <img
              src={WhyUsBanner}
              alt="Why Us Banner"
              className="justify-self-center"
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#FAFAFA] py-12">
        <div className="mx-auto lg:w-[80%] lg:px-0 px-8 flex flex-col lg:flex-row gap-12">
          {/* Left Section */}
          <div className="lg:w-[50%] w-full">
            <h1 className="text-h1 lg:w-[70%] w-full ">
              Why choose PTE Master for your online PTE academic preparation ?
            </h1>
          </div>

          {/* Right Section */}
          <div className="lg:w-[50%] w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="bg-[#F5F5F5] rounded-[8px] items-center p-4">
                <p className="text-h4m">Authentic Marking Algorithms</p>
                <p className="text-bodyr text-[#595959] mt-2">
                  PTE Master's software is designed and developed using the true
                  algorithms as you would find in the real PTE academic exam.
                  This allows students to explore and freely leverage the system
                  to maximize their score in a non-pressure environment. All the
                  tricks and templates taught in our portal will work 100% in
                  the real exam.
                </p>
              </div>
              <div className=" bg-[#F5F5F5] rounded-[8px] items-center p-4">
                <p className="text-h4m">Real Mathematic Calculations</p>
                <p className="text-bodyr text-[#595959] mt-2">
                  We have algorithms developed to perform tasks based on real
                  calculations per PTE Academic standards. Our scoring system is
                  effective and accurate. The PTE practice platform simulates a
                  real environment for focused test preparation. Each practice
                  and section test is analyzed quickly, providing results to
                  identify improvement areas and detailed feedback on mistakes.
                </p>
              </div>
              <div className=" bg-[#F5F5F5] rounded-[8px] items-center p-4">
                <p className="text-h4m">Real Mathematic Calculations</p>
                <p className="text-bodyr text-[#595959] mt-2">
                  We have algorithms developed to perform tasks based on real
                  calculations per PTE Academic standards. Our scoring system is
                  effective and accurate. The PTE practice platform simulates a
                  real environment for focused test preparation. Each practice
                  and section test is analyzed quickly, providing results to
                  identify improvement areas and detailed feedback on mistakes.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-[80%] lg:px-0 px-8 !bg-white place-self-center flex flex-col-reverse lg:flex-row mt-[60px]">
          <div className="lg:w-2/3 lg:pr-12 grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
            {reasonList.map((reason, index) => (
              <div
                key={index}
                className="p-4  text-white rounded-lg shadow-md items-start gap-4"
                style={{
                  background:
                    'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
                }}
              >
                <img src={reason.icon} alt="Why Icon" className="w-8 h-8" />
                <div>
                  <h3 className="text-h4m my-2">{reason.title}</h3>
                  <p className="text-bodyr">{reason.description}</p>
                </div>
              </div>
            ))}
            {/* <div
              className="p-4  text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={MonitorIcon} alt="Monitor Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">
                  Computer Based English Language Test
                </h3>
                <p className="text-bodyr">
                  PTE is a computer based english language proficiency test to
                  assess their communication for Reading, writing, reading and
                  listening done in a single sitting of 3 hours.
                </p>
              </div>
            </div>
            <div
              className="p-4  text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={CPUIcon} alt="Score Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">Unbiased Scoring</h3>
                <p className="text-bodyr">
                  PTE is exam is a computer based person's English language test
                  and assessed using automated scoring technology and it ensures
                  impartial and accurate scoring.Test takers are provided with a
                  pearson's score from 10-90 overall for all pte speaking ,
                  reading , writing & listening skills based on the Global Scale
                  of English and have freedom to send your scores to as many
                  organizations as you like, without an additional fee.
                </p>
              </div>
            </div>

            <div
              className="p-4 text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={UserIcon} alt="User Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">
                  Flexible Testing & Fast Results
                </h3>
                <p className="text-bodyr">
                  PTE Academic offers flexibility to schedule tests upto to 24
                  hours in advance and also candidates can give pte test from
                  anywhere any time or at any date. PTE academics deliver fast &
                  accurate results within five business days or within just 48
                  hours of taking the pte test.The pearson test of english is
                  conducted over 360 days of the year in about 250 test centers
                  across the globe.
                </p>
              </div>
            </div>
            <div
              className="p-4  text-white rounded-lg shadow-md items-start gap-4"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              <img src={CPUIcon} alt="CPU Icon" className="w-8 h-8" />
              <div>
                <h3 className="text-h4 font-bold mb-2">
                  Acceptance For Study & Migration Applications
                </h3>
                <p className="text-bodyr">
                  PTE Academic Is trusted & accepted for academic study program
                  applications by thousands of universities, colleges and
                  governments around the world. It is also approved for all
                  Australian and New Zealand student visa and migration
                  applications.
                </p>
              </div>
            </div> */}
          </div>

          <div className="w-full flex flex-col md:flex-row lg:flex-col mb-6 lg:mb-0 lg:w-1/3 lg:pl-12 lg:border-l-[2px] border-gray-300 lg:justify-normal md:justify-around">
            <p className="text-h2">Why Choose The PTE Academic Exam ?</p>
          </div>
        </div>

        <div className="lg:w-[80%] lg:px-0 px-8 !bg-white grid grid-cols-1 lg:grid-cols-2 place-self-center mt-[60px]">
          <div className="lg:w-[80%]">
            <p className="text-h1">
              Fast Track Your PTE Preparation With Our Online Training Software
            </p>
            <p className="text-bodyr text-[#595959] py-4">
              You could shave off months and years of preparation if you learn
              PTE the correct ways. At PTE Master, we have helped over 10000+
              students from all over the world achieve their desire score in
              Speaking, Writing, Reading and Listening.
            </p>
            <p className="text-h4m">We offer :</p>
            {offerList.map((offer, index) => (
              <div key={index} className="flex items-start gap-4 lg:py-4 py-2">
                <img src={offer.icon} alt="Offer Icon" />
                <p className="text-bodyr text-[#595959]">{offer.title}</p>
              </div>
            ))}
          </div>
          <div className="lg:justify-items-end justify-self-center">
            <img src={AboutImagesSlide1} alt="About Images Slide 1" />
          </div>
        </div>

        {/* Call-to-Action Section */}
        <SkillCTASection />
      </div>
    </div>
  )
}

export default WhyUsPage
