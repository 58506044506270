import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as MicrophoneIcon } from '../../../images/SystemRequirement/sr-microphone-icon.svg'
import { ReactComponent as RightArrowIcon } from '../../../images/SystemRequirement/sr-right-arrow-icon.svg'
import {
  navigateUserToPlanPage,
  remvoveTestInformation,
} from '../../../utils/utils'
import {
  startTestNow2,
  doTest2,
} from '../../../services/sectionalTest.services'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import LoadingMessage from '../../../components/Items/LoadingMessage'
import { useAuth } from '../../../providers/AuthProvider'
import {
  handleExceptionError,
  navigateToSectionalTestSpeaking,
} from '../../../utils/utils'
import { getUserTokenDetails } from '../../../services/user.services'
import Path from '../../../routes/Path'

interface RecordingState {
  mediaRecorder: MediaRecorder | null
  audioBlob: Blob | null
}

const SRSectionalSpeakingPage = () => {
  const navigate = useNavigate()

  const [timer, setTimer] = useState<number>(5)
  const [isRecording, setIsRecording] = useState<boolean>(false)
  const [recordingState, setRecordingState] = useState<RecordingState>({
    mediaRecorder: null,
    audioBlob: null,
  })
  const [buyId, setBuyId] = useState<number>(0)
  const [testId, setTestId] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const { userProfile } = useAuth()

  useEffect(() => {
    //Remove the previous test at localStorage first
    remvoveTestInformation(2)

    const fetchStartTestNow5 = async () => {
      if (userProfile && userProfile.userId) {
        try {
          const response = await startTestNow2(userProfile.userId)
          if (response.data.success) {
            localStorage.setItem('buyIdSPTESectional', response.data.buyid)
            localStorage.setItem('testIdSPTESectional', response.data.testid)
            setBuyId(response.data.buyid)
            setTestId(response.data.testid)
          } else {
            setErrorMsg(response.data.message)
          }
        } catch (error) {
          if (error instanceof TypeError) {
            setErrorMsg(error.message)
          } else {
            setErrorMsg('Something went wrong!')
          }
        } finally {
          setIsLoading(false)
        }
      } else {
        console.error('User not found!')
      }
    }

    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }

    checkUserPlan()
    fetchStartTestNow5()
  }, [userProfile, navigate])

  const startRecording = async () => {
    setIsRecording(true)

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const recorder = new MediaRecorder(stream)
      const chunks: BlobPart[] = []

      recorder.ondataavailable = (event) => {
        chunks.push(event.data)
      }

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' })
        setRecordingState({ mediaRecorder: recorder, audioBlob: blob })
      }

      recorder.start()

      let remainingTime = 6
      const interval = setInterval(() => {
        remainingTime--
        setTimer(remainingTime)
        if (remainingTime === 0) {
          clearInterval(interval)
          setIsRecording(false)
          recorder.stop()
        }
      }, 1000)
    } catch (error) {
      console.error('Error accessing microphone:', error)
      setIsRecording(false)
    }
  }

  const handleContinue = async () => {
    if (userProfile && userProfile.userId) {
      try {
        const response = await doTest2(buyId, testId, userProfile.userId)
        if (response.data.success) {
          localStorage.setItem(
            'resultSectionalSPTE',
            JSON.stringify(response.data.result),
          )
          navigateToSectionalTestSpeaking(navigate, response.data.category_id)
        } else {
          console.error(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
      }
    } else {
      console.error('User not found!')
    }
  }

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Speaking Sectional Test / System
          Requirement
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        {isLoading ? (
          <LoadingMessage message="Generating questions... Please wait..." />
        ) : errorMsg ? (
          <ErrorMessage message={errorMsg} />
        ) : (
          <div className="sr-sectional-speaking-section font-landingPrimary">
            <p className="sr-sectional-speaking-section__title text-h1 text-info">
              Speaking Sectional Test
            </p>
            <p className="sr-sectional-speaking-section__description text-bodyr text-neutrals-2 lg:w-[36.188rem] w-full mt-[1rem]">
              Each time you create a sectional test, the system will randomly
              pick the questions for you. Every new test will come with
              different set of questions to ensure complete randomness when it
              comes to practicing. This single sectional test will only examine
              your speaking skill, if you wish to have all of your 4 skills
              evaluated at one time, please purchase a full mock test.
            </p>
            <p className="sr-sectional-speaking-section__instruction text-bodyr mt-[1rem]">
              To start, click the <span className="font-bold">"Record"</span>{' '}
              button below to test your microphone, then click{' '}
              <span className="font-bold">"Continue”</span> to sectional test
            </p>
            <div className="sr-sectional-speaking-section__micro-check text-bodyr lg:w-[37.188rem] w-full my-[1rem]">
              <p className="text-h5m text-neutrals-2 mb-[1rem]">
                MICROPHONE CHECK
              </p>
              <p>
                This is an opportunity to check if your microphone is working
                properly.
              </p>
              <ol className="list-decimal text-bodyr pl-[1rem] lg:w-[36.188rem]">
                <li className="mb-[0.6rem]">
                  Make sure your headset is on and the microphone is in the
                  downward position near your mouth.
                </li>
                <li className="mb-[0.6rem]">
                  Click on the <span className="font-bold">“Record”</span>{' '}
                  button and say{' '}
                  <span className="font-bold">
                    “Testing, testing, one, two, three”
                  </span>{' '}
                  into the microphone.
                </li>
                <li className="mb-[0.6rem]">
                  After you have spoken, click on the{' '}
                  <span className="font-bold">“Stop”</span> button. Your
                  recording is now complete.
                </li>
                <li className="mb-[0.6rem]">
                  Now click on the <span className="font-bold">“Playback”</span>{' '}
                  button. You should clearly hear yourself speaking.
                </li>
                <li className="mb-[0.6rem]">
                  If you can not hear your voice clearly, please raise your
                  hand.
                </li>
              </ol>
              <p className="text-bodyr text-neutrals-2 mt-[1rem]">
                During the test, you will not have{' '}
                <span className="font-bold">“Record”</span>,{' '}
                <span className="font-bold">“Playback”</span> and{' '}
                <span className="font-bold">“Stop”</span> buttons. The voice
                recording will start automatically.
              </p>
            </div>
            <div className="sr-sectional-speaking-section__audio-record mt-[1rem]">
              {!isRecording && !recordingState.audioBlob && (
                <button
                  onClick={startRecording}
                  className="lg:w-[8.625rem] w-full h-[2.5rem] text-h5r text-white bg-info hover:bg-[#1890FF] flex justify-center items-center gap-2 rounded-lg"
                >
                  <MicrophoneIcon />
                  Record
                </button>
              )}
              {!isRecording ? (
                recordingState.audioBlob && (
                  <div>
                    <audio
                      controls
                      className="lg:w-[30rem] w-full"
                      src={URL.createObjectURL(recordingState.audioBlob)}
                    />
                    <button
                      className="mt-[1rem] lg:w-[8.625rem] w-full h-[2.5rem] bg-info hover:bg-[#1890FF] text-h5r text-white flex justify-center items-center gap-2 rounded-lg"
                      onClick={handleContinue}
                    >
                      <RightArrowIcon />
                      Continue
                    </button>
                  </div>
                )
              ) : (
                <div className="w-[17rem] h-[2.875rem] bg-[#F1EFF2] text-bodyr text-neutrals-2 flex justify-center items-center rounded-2xl">
                  <p>00:0{timer} seconds</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SRSectionalSpeakingPage
