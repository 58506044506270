import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import PlanDetailSection from '../components/ShopPage/PlanDetailSection'
import { getPlanDetails } from '../services/shop.services'
import TokenPackage from '../models/tokenPakage.model'
import LoadingMessage from '../components/Items/LoadingMessage'

const PlanDetailPage: React.FC = () => {
  const [planData, setPlanData] = useState<TokenPackage | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const { planId } = useParams()
  // Fetch cart data with error handling
  const fetchPlanData = useCallback(async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await getPlanDetails(Number(planId))
      setPlanData(response.data.packages)
    } catch (error) {
      console.error('Error fetching plan data:', error)
      setError('Failed to load plan data.')
    } finally {
      setLoading(false)
    }
  }, [planId])

  // Fetch cart data on component mount
  useEffect(() => {
    fetchPlanData()
  }, [fetchPlanData])

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / <Link to="/plan">Plan</Link> / Plan
          Detail
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:px-[4rem] lg:py-[2rem] p-[1rem] bg-white rounded-l-lg">
        {loading && <LoadingMessage message="Loading plan information..." />}
        {error && <p className="text-red-500">{error}</p>}
        {planData && !loading && !error && (
          <PlanDetailSection planData={planData} />
        )}
      </div>
    </>
  )
}

export default PlanDetailPage
