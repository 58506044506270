import React from 'react'
import AboutImagesSlide1 from '../../images/Landingpage/about-image-slide-1.png'
import SelfStudyBanner from '../../images/Landingpage/SelfStudy/self_study_banner.png'
import ProvideIcon1 from '../../images/Landingpage/SelfStudy/provide1.png'
import ProvideIcon2 from '../../images/Landingpage/SelfStudy/provide2.png'
import ProvideIcon3 from '../../images/Landingpage/SelfStudy/provide3.png'
import ProvideIcon4 from '../../images/Landingpage/SelfStudy/provide4.png'
import SelfStudyIcon1 from '../../images/Landingpage/SelfStudy/selfstudy1.png'
import SelfStudyIcon2 from '../../images/Landingpage/SelfStudy/selfstudy2.png'
import SelfStudyIcon3 from '../../images/Landingpage/SelfStudy/selfstudy3.png'
import SelfStudyIcon4 from '../../images/Landingpage/SelfStudy/selfstudy4.png'
import SelfStudyIcon5 from '../../images/Landingpage/SelfStudy/selfstudy5.png'
import SelfStudyIcon6 from '../../images/Landingpage/SelfStudy/selfstudy6.png'
import SelfStudyIcon7 from '../../images/Landingpage/SelfStudy/selfstudy7.png'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'
import { Link } from 'react-router-dom'
import Path from '../../routes/Path'

const SelfStudyPage: React.FC = () => {
  const provideList = [
    {
      icon: ProvideIcon1,
      title: 'Free PTE E-Books',
      description: `This PTE eBook is the result of years of experiments and
                    observations by Ady & it is updated with last minute tips or
                    tricks, approach & strategies to boost score in PTE speaking,
                    reading, writing and listening. You can read it now for
                    completely free.`,
    },
    {
      icon: ProvideIcon2,
      title: 'PTE Video Toolkit',
      description: `PTE Video Toolkit consists of Short and Precise Video
                    tutorials that help to crack the PTE exam with the right
                    techniques, tricks and templates and save time, money with
                    highly accurate results.`,
    },
    {
      icon: ProvideIcon3,
      title: 'Practice Test Evaluation',
      description: `PTE-A Exam plans with distinguished features to kick start
                    your preparation with PTE Mock Tests or PTE practice
                    test based on the PTE Scoring Guide.`,
    },
    {
      icon: ProvideIcon4,
      title: 'Test Analytics & Results',
      description: `PTE score report is based on AI and will be automatically
                    generated after each exam attempt which clearly displays
                    communicative, enabling as well as the overall score.
                    <br/>
                    <span> > Highlights your error in each PTE academic section</span>
                    <br/>
                    <span> > Complete feedback on each task type. </span>
                     `,
    },
  ]

  const tutorialList = [
    {
      icon: SelfStudyIcon1,
      title: 'Flexible Timing',
    },
    {
      icon: SelfStudyIcon2,
      title: 'Fully Timed Mock Tests',
    },
    {
      icon: SelfStudyIcon3,
      title: 'Real PTE Exam Questions',
    },
    {
      icon: SelfStudyIcon4,
      title: 'Model answers',
    },
    {
      icon: SelfStudyIcon5,
      title: 'Time-based Analytics',
    },
    {
      icon: SelfStudyIcon6,
      title: 'Question-based Analytics',
    },
    {
      icon: SelfStudyIcon7,
      title: 'Evaluation by A.I. Software',
    },
  ]
  return (
    <div>
      <div className="bg-[#0C3CC6] py-12">
        <div className="mx-auto lg:w-[80%] lg:px-0 px-8 flex flex-col lg:flex-row gap-12">
          {/* Left Section */}
          <div className="lg:w-[50%] w-full text-white content-center">
            <p className="text-h2 pb-4">PTE Self Study</p>
            <p className="text-bodyr">
              PTE Self-study platform is built by a team of experienced PTE
              trainers includes four modules speaking , writing , reading &
              listening in
              <Link
                to={Path.landing.automateScoring.path}
                className="underline hover:bg-[#325DDA] px-1"
              >
                PTE Academic Mock test
              </Link>
              & practice test to assist PTE aspirants in preparing for real
              tests to achieve your desired score.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-8">
              <p className="p-4 bg-[#325DDA] rounded-md">
                50+ Scored PTE Mock Tests & Practice Test
              </p>
              <p className="p-4 bg-[#325DDA] rounded-md">
                Real Time Test Environment
              </p>
              <p className="p-4 bg-[#325DDA] rounded-md">
                AI scoring algorithm based on Real PTE Scoring Guide
              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className="lg:w-[50%] w-full">
            <img
              src={SelfStudyBanner}
              alt="Self Study Banner"
              className="justify-self-center"
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#FAFAFA] py-12">
        <div className="mx-auto lg:w-[80%] lg:px-0 px-8 flex flex-col lg:flex-row gap-12">
          {/* Left Section */}
          <div className="lg:w-[50%] w-full">
            <h1 className="text-h1 lg:w-[70%] w-full ">What can we provide?</h1>
          </div>

          {/* Right Section */}
          <div className="lg:w-[50%] w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {provideList.map((item, index) => (
                <div
                  key={index}
                  className="bg-[#F5F5F5] rounded-[8px] items-center p-4"
                >
                  <img src={item.icon} alt={item.title} className="mb-4" />
                  <p className="text-h4m">{item.title}</p>
                  <div
                    className="text-bodyr text-[#595959] mt-2"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="lg:w-[80%] lg:px-0 px-8 !bg-white grid grid-cols-1 lg:grid-cols-2 place-self-center mt-[60px]">
          <div className="justify-items-center	">
            <img src={AboutImagesSlide1} alt="About Images Slide 1" />
          </div>
          <div className="xl:pl-[20%] lg:pl-8 lg:pt-0 pt-8">
            <p className="text-h1">
              Start using AI PTE Self Study Tutorial for exam preparation
            </p>
            <p className="text-bodyr text-[#595959] py-4">
              Our AI PTE Self Study Tutorial will help you with PTE Academic
              exam preparation. With the help of our online PTE practice
              tutorial, a student can take advantage of practicing 50 + mock
              tests from anywhere, anytime in a real-time test environment with
              real scorecards and expert evaluation.
            </p>
            {tutorialList.map((tutorial, index) => (
              <div key={index} className="flex items-start gap-4 py-2">
                <img src={tutorial.icon} alt="Offer Icon" />
                <p className="text-bodyr text-[#595959]">{tutorial.title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mx-auto lg:w-[80%] lg:px-0 px-8 flex flex-col lg:flex-row gap-12 mt-[60px]">
          {/* Left Section */}
          <div className="lg:w-[50%] w-full">
            <h1 className="text-h1 xl:w-[50%] lg:w-[70%] w-full">
              Ways to prepare and crack PTE by AI PTE Self-Study Tutorial
            </h1>
            <p className="text-bodyr text-[#595959] xl:w-[50%] lg:w-[70%] w-full mt-4">
              After enrolling for PTE Exam, we are always worried about fees,
              time of classes & from where we start PTE exam preparation.
            </p>
          </div>

          {/* Right Section */}
          <div className="lg:w-[50%] w-full">
            <div className="flex flex-col gap-4">
              <p className="text-bodyr text-[#595959] p-2 bg-[#F5F5F5]">
                Although there are many PTE study materials available in the
                market for PTE academic exam preparation like books, pdfs,
                videos and other study material. But it is not enough for a
                successful PTE journey.
              </p>
              <p className="text-bodyr text-[#595959] p-2 bg-[#F5F5F5]">
                Students have to choose the authentic source which provides the
                real PTE exam environment & proper guidance to achieve a good
                score in PTE academics.
              </p>
              <p className="text-bodyr text-[#595959] p-2 bg-[#F5F5F5]">
                At PTE Master while keeping the needs of students in mind, We
                Developed structured online PTE academic practice mock test
                platform includes four modules speaking , writing, listening
                & reading which is designed by deep insights from PTE and
                language experts to familiarize you with the actual PTE-A Test.
              </p>
              <p className="text-bodyr text-[#595959] p-2 bg-[#F5F5F5]">
                With the help of our online PTE practice tutorials, a student
                can practice PTE academic mock test at their convenient time and
                get instant results with expert evaluation. Further, it also
                gives an indication about caliber of all the English language
                skills & identify the areas where students need to improve,
                before taking the actual PTE Academic Exam.
              </p>
              <p className="text-bodyr text-[#595959] p-2 bg-[#F5F5F5]">
                This allows them to learn time management and gain advantage of
                model answers & explanations for the same Bringing in a modern,
                student-friendly alternative for better scores in exams, it is
                through the use of latest technology like AI that you can easily
                master your skills & help you in staying ahead of competition.
              </p>
            </div>

            <p className="text-bodyr text-[#595959] mt-8">
              Some positive aspects of AI PTE practice Mock Test Tutorial
            </p>
            <div className="grid sm:grid-cols-4 lg:grid-cols-2 xl:flex gap-4 text-bodym text-[#000000D9] mt-4">
              <p className="p-2 rounded-lg bg-[#F0F0F0]">
                Communicative skill scores
              </p>
              <p className="p-2 rounded-lg bg-[#F0F0F0]">
                Enabling skill scores{' '}
              </p>
              <p className="p-2 rounded-lg bg-[#F0F0F0]">
                Voice Analysis Mode{' '}
              </p>
              <p className="p-2 rounded-lg bg-[#F0F0F0]">Time Mode </p>
            </div>
          </div>
        </div>

        {/* Call-to-Action Section */}
        <SkillCTASection />
      </div>
    </div>
  )
}

export default SelfStudyPage
