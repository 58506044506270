import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Path from '../../../routes/Path'
import { toastError, toastSuccess } from '../../../utils/utils'
import { sendPasswordReset } from '../../../configs/firebase'

const PasswordForm: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      emailOrUsername: '',
    },
  })

  const onSubmit = async (data: { emailOrUsername: string }) => {
    try {
      setIsDisabled(true)
      // Send password reset email
      await sendPasswordReset(data.emailOrUsername)
      toastSuccess('Password reset instructions sent!')
    } catch (error: any) {
      setIsDisabled(false)
      toastError(error.message || 'Failed to send password reset instructions.')
    }
  }

  return (
    <div className="register-form lg:w-[31.5rem] md:w-2/3 sm:w-3/4 w-11/12 h-fit bg-neutrals-4 rounded-xl lg:p-8 p-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label htmlFor="emailOrUsername" className="block mb-1 text-h5r">
            Email
          </label>
          <input
            type="email"
            id="emailOrUsername"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Type your email here"
            {...register('emailOrUsername', {
              required: 'Please enter your email!',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Please enter a valid email address.',
              },
            })}
          />
          {errors.emailOrUsername && (
            <div className="text-red-500 mb-4">
              {errors.emailOrUsername.message}
            </div>
          )}
        </div>

        <button
          type="submit"
          className={`w-full rounded-md p-3.5 text-white ${isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-info hover:bg-[#1890FF]'}`}
          disabled={isDisabled}
        >
          Reset Password
        </button>
        <div className="text-center font-landingPrimary my-2">
          Not a Member?{' '}
          <Link
            to={Path.landing.register.path}
            className="center text-bodyr font-landingPrimary text-info hover:text-info/80"
          >
            Register Now
          </Link>
        </div>
      </form>
    </div>
  )
}

export default PasswordForm
