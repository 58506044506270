import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ShopSection from '../components/ShopPage/ShopSection'
import {
  getShopData,
  getTokenPacksMock,
  getShopDeals,
} from '../services/shop.services'
import { useAuth } from '../providers/AuthProvider'
import ShopData from '../models/shopData.model'
import TokenPacksMock from '../models/tokenPacksMock.model'

const ShopPage = () => {
  const { userProfile } = useAuth()
  const location = useLocation()
  const [shopData, setShopData] = useState<ShopData | null>(null)
  const [tokenPacksMock, setTokenPacksMock] = useState<TokenPacksMock | null>(
    null,
  )
  const [shopDeals, setShopDeals] = useState(null)

  // State to manage the selected tab
  const [initialTab, setInitialTab] = useState('Item1')

  // Listen to query parameter changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tab = queryParams.get('tab') || 'Item1'
    console.log('Tab', location.search)
    setInitialTab(tab)
  }, [location.search]) // Only listen for location.search changes

  useEffect(() => {
    const userId = userProfile?.userId

    if (userId) {
      getShopData(userId)
        .then((response) => {
          if (response.data.success) {
            setShopData(response.data)
          } else {
            console.error(response.data.message)
          }
        })
        .catch((error) => {
          console.error('Error fetching shop data:', error)
        })

      getTokenPacksMock(userId)
        .then((response) => {
          if (response.data.success) {
            setTokenPacksMock(response.data)
          } else {
            console.error(response.data.message)
          }
        })
        .catch((error) => {
          console.error('Error fetching token packs mock:', error)
        })

      getShopDeals(userId)
        .then((response) => {
          if (response.data.success) {
            setShopDeals(response.data)
          } else {
            console.error(response.data.message)
          }
        })
        .catch((error) => {
          console.error('Error fetching shop deals:', error)
        })
    }
  }, [userProfile?.userId])

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          Mock Test / Shop
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        {shopData && tokenPacksMock && shopDeals && (
          <ShopSection
            shopData={shopData}
            tokenPacksMock={tokenPacksMock}
            shopDeals={shopDeals}
            initialTab={initialTab} // Pass the updated initial tab
          />
        )}
      </div>
    </>
  )
}

export default ShopPage
