import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as FooterLocationIcon } from '../images/Landingpage/footer-location-icon.svg'
import { ReactComponent as FooterPhoneIcon } from '../images/Landingpage/footer-phone-icon.svg'
import PteMasterLogo2 from '../images/Landingpage/pte-master-logo-2.png'
import Path from '../routes/Path'

const Footer: React.FC = () => {
  const academicList = [
    {
      destination: Path.landing.academicSpeaking.path,
      title: 'PTE Academic Speaking Test',
    },
    {
      destination: Path.landing.academicReading.path,
      title: 'PTE Academic Reading Test',
    },
    {
      destination: Path.landing.academicWriting.path,
      title: 'PTE Academic Writing Test',
    },
    {
      destination: Path.landing.academicListening.path,
      title: 'PTE Academic Listening Test',
    },
  ]

  const menuList1 = [
    {
      title: 'Automated Scoring Tests',
      destination: Path.landing.automateScoring.path,
    },
    { title: 'How It Works', destination: Path.landing.howItWorks.path },
    { title: 'Why us', destination: Path.landing.whyUs.path },
    { title: 'Contact Us', destination: Path.landing.contactUs.path },
    { title: 'Support', destination: Path.landing.support.path },
  ]

  const menuList2 = [
    {
      title: 'AI PTE Mock Test',
      destination: Path.landing.aiPteMockTest.path,
    },
    {
      title: 'Online PTE Preparation Tool',
      destination: Path.landing.prepareTools.path,
    },
    { title: 'Blogs', destination: Path.landing.blog.main.path },
    { title: 'FAQs', destination: Path.landing.faqs.path },
    { title: 'Students Review', destination: Path.landing.studentReviews.path },
  ]

  return (
    <footer className="w-full bg-primary-6 xl:px-12 px-4 py-8 lg:flex justify-between items-start gap-8">
      {/* Logo and About Section */}
      <div className="lg:w-[30%] lg:mb-0 mb-4">
        <img src={PteMasterLogo2} alt="PTE Master Logo" className="mb-4" />
        <p className="w-[80%] text-bodyr text-primary-2 font-landingPrimary">
          PTE Master provides PTE training courses to all students who wish to
          achieve a high score. We started our journey in 2016 and have helped
          thousands of students to achieve their desired score in a short amount
          of time.
        </p>
      </div>

      {/* Links Section */}
      <div className="lg:w-[50%] flex xl:gap-12 sm:gap-4 gap-2 lg:justify-center justify-between">
        {/* Academic Tests Links */}
        <div className="">
          <p className="font-sans font-semibold text-base text-neutrals-4 mb-3">
            Practice materials{' '}
          </p>
          {academicList.map((academic, index) => (
            <p key={index} className="py-1 w-fit">
              <Link
                to={academic.destination}
                className="text-bodyr text-primary-2 font-landingPrimary"
              >
                {academic.title}
              </Link>
            </p>
          ))}
        </div>

        {/* Navigation Column 1 */}
        <div className="">
          <p className="font-sans font-semibold text-base text-neutrals-4 mb-3">
            Navigation
          </p>
          {menuList1.map((menu, index) => (
            <p key={index} className="py-1">
              <Link
                to={menu.destination}
                className="text-bodyr text-primary-2 font-landingPrimary"
              >
                {menu.title}
              </Link>
            </p>
          ))}
        </div>

        {/* Navigation Column 2 */}
        <div className="">
          <p className="font-sans font-semibold text-base text-neutrals-4 mb-3">
            Navigation
          </p>
          {menuList2.map((menu, index) => (
            <p key={index} className="py-1">
              <Link
                to={menu.destination}
                className="text-bodyr text-primary-2 font-landingPrimary"
              >
                {menu.title}
              </Link>
            </p>
          ))}
        </div>
      </div>

      {/* Contact Information Section */}
      <div className="lg:w-[20%] text-neutrals-4 lg:mt-0 mt-4">
        <p className="text-h4m font-sans mb-3">Get in touch</p>
        <div className="flex items-start gap-3 mb-4">
          <FooterLocationIcon fill="white" width={30} />
          <p className="w-[80%] text-bodyr text-neutrals-4 font-landingPrimary">
            Level 36 Gateway, 1 Macquarie Place Sydney NSW 2000 Sydney,
            Australia
          </p>
        </div>
        <div className="flex items-start gap-3">
          <FooterPhoneIcon fill="white" width={30} />
          <p className="text-bodyr text-neutrals-4 font-landingPrimary">
            Contact Number: 0411 703 756
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
