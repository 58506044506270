import { useState, useEffect } from 'react'
import AIPracticeSection from './AIPracticeSection'
import AIMockTestSection from './AIMockTestSection'
import PracticeMockTestBundleSection from './PracticeMockTestBundleSection'
import { ReactComponent as ShopDownArrowIcon } from '../../images/ShopPage/shop-down-arrow-icon.svg'
import TokenPacksMock from '../../models/tokenPacksMock.model'
import ShopData from '../../models/shopData.model'

interface ShopSectionProps {
  shopData: ShopData
  tokenPacksMock: TokenPacksMock
  shopDeals: any
  initialTab: string
}

const ShopSection: React.FC<ShopSectionProps> = ({
  shopData,
  tokenPacksMock,
  shopDeals,
  initialTab,
}) => {
  const [selectedItem, setSelectedItem] = useState(initialTab)
  const [isOpen, setIsOpen] = useState(false)

  // Update selectedItem when initialTab changes
  useEffect(() => {
    setSelectedItem(initialTab)
  }, [initialTab])

  const items = [
    { name: 'AI Practice', id: 'Item1' },
    { name: 'AI Mock Test', id: 'Item2' },
    { name: 'Practice + Mock Test Bundle', id: 'Item3' },
  ]

  const handleItemClick = (item: any) => {
    setSelectedItem(item.id)
    setIsOpen(false)
  }

  return (
    <div className="shop-section">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">Shop</p>
      <p className="text-bodyr text-neutrals-2 lg:w-[25.875rem] font-landingPrimary">
        Subscribe to our VIP package will grant unlimited scoring for practice
        and allow full access to all prediction files and templates
      </p>
      <div className="lg:hidden lg:mt-[3rem] mt-[2rem] md:w-full w-full relative">
        {/* Mobile dropdown */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full h-[2rem] py-[1.2rem] px-[1rem] bg-white border border-1 rounded-lg text-h5r font-landingPrimary flex justify-between items-center"
        >
          {items.find((item) => item.id === selectedItem)?.name ??
            'Select Item'}
          <ShopDownArrowIcon fill="#00000040" />
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white rounded-md shadow-lg">
            {items.map((item) => (
              <li
                key={item.id}
                onClick={() => handleItemClick(item)}
                className="cursor-pointer px-[12px] py-[5px] text-h5r font-landingPrimary hover:bg-info hover:text-white"
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <nav className="hidden lg:flex justify-between items-center bg-[#FAFAFA] mt-[3rem] lg:w-[29.75rem] w-full lg:h-[2.875rem] h-[4rem] lg:p-0 p-[0.4rem] rounded-lg text-center">
        {/* PC Navbar */}
        <ul className="flex space-x-4 text-h5r font-landingPrimary text-neutrals-2 w-full h-full">
          {items.map((item) => (
            <li
              key={item.id}
              onClick={() => handleItemClick(item)}
              className={`cursor-pointer flex items-center justify-center flex-grow rounded-lg ${
                selectedItem === item.id
                  ? 'text-h5r text-neutrals-4 bg-info'
                  : ''
              }`}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </nav>
      <div className="shop-section__list-items mt-[2rem]">
        {selectedItem === 'Item1' && <AIPracticeSection shopData={shopData} />}
        {selectedItem === 'Item2' && (
          <AIMockTestSection tokenPacksMock={tokenPacksMock} />
        )}
        {selectedItem === 'Item3' && (
          <PracticeMockTestBundleSection shopDeals={shopDeals} />
        )}
      </div>
    </div>
  )
}

export default ShopSection
