import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Path from '../../../routes/Path'

const ChangePasswordForm: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false)

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  })

  return (
    <div className="register-form lg:w-[31.5rem] md:w-2/3 lg:h-fit w-full h-full bg-neutrals-4 rounded-xl p-8">
      <form>
        <div className="mb-4">
          <label htmlFor="newPassword" className="block mb-1">
            New Password
          </label>
          <input
            type="password"
            id="newPassword"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Enter your new password"
            {...register('newPassword', {
              required: 'Please enter your new password!',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters long.',
              },
            })}
          />
          {errors.newPassword && (
            <div className="text-red-500 mb-4">
              {errors.newPassword.message}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-1">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            className="w-full border border-[#D9D9D9] rounded-md px-4 py-2"
            placeholder="Confirm your new password"
            {...register('confirmPassword', {
              required: 'Please confirm your new password!',
            })}
          />
          {errors.confirmPassword && (
            <div className="text-red-500 mb-4">
              {errors.confirmPassword.message}
            </div>
          )}
        </div>

        <button
          type="submit"
          className={`w-full rounded-md p-3.5 text-white ${isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-info hover:bg-[#1890FF]'}`}
          disabled={isDisabled}
        >
          Change Password
        </button>
        <div className="text-center font-landingPrimary my-2">
          Not a Member?{' '}
          <Link
            to={Path.landing.register.path}
            className="center text-bodyr font-landingPrimary text-info"
          >
            Register Now
          </Link>
        </div>
      </form>
    </div>
  )
}

export default ChangePasswordForm
