import { useState, useEffect, useCallback, useMemo } from 'react'
import { ReactComponent as DropDownArrowIcon } from '../../../images/LastMinuteStudy/lmt-dropdown-arrow-icon.svg'
import HistoryDetailsSpeakingMock from './HistoryDetailsMockSpeaking'
import HistoryDetailsWritingMock from './HistoryDetailsMockWriting'
import HistoryDetailsListeningMock from './HistoryDetailsMockListening'
import HistoryDetailsReadingMock from './HistoryDetailsMockReading'
import LoadingMessage from '../../Items/LoadingMessage'
import ErrorMessage from '../../Items/ErrorMessage'
import { UserTestResultHistory } from '../../../models/userTestResultHistory'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../../providers/AuthProvider'
import { historyDetailData } from '../../../services/testHistory.services'

const HistoryDetailsMockSection = () => {
  const { userProfile } = useAuth()
  let { buyId, testId } = useParams()
  const [selectedCategory, setSelectedCategory] = useState(1)
  const [testHistory, setTestHistory] = useState<UserTestResultHistory[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<null | string>(null)
  const [isOpen, setIsOpen] = useState(false)

  const categories = useMemo(
    () => [
      { name: 'Speaking', id: 1 },
      { name: 'Writing', id: 2 },
      { name: 'Reading', id: 3 },
      { name: 'Listening', id: 4 },
    ],
    [],
  )

  const fetchHistoryDetailData = useCallback(async () => {
    if (
      userProfile &&
      userProfile.userId &&
      !isNaN(Number(buyId)) &&
      !isNaN(Number(testId))
    ) {
      setIsLoading(true)
      setError(null)
      try {
        const response = await historyDetailData(
          userProfile.userId,
          Number(buyId),
          Number(testId),
          categories
            .find((cat) => cat.id === selectedCategory)
            ?.name.toLowerCase() || '',
          '',
        )

        if (response.data.success) {
          setTestHistory(response.data.resultData)
        } else {
          setError('Failed to get the history details.')
        }
      } catch (error) {
        console.error(error)
        setError('An error occurred while gettting history details.')
      } finally {
        setIsLoading(false)
      }
    } else {
      console.error(
        'User profile is missing or invalid or buyId or testId is invalid!',
      )
      setIsLoading(false)
      setError('Invalid information!')
    }
  }, [buyId, testId, userProfile, selectedCategory, categories])

  useEffect(() => {
    fetchHistoryDetailData()
  }, [fetchHistoryDetailData])

  // Function to handle item click
  const handleItemClick = (item: any) => {
    setSelectedCategory(item.id)
    setIsOpen(false) // Close dropdown on mobile when an item is selected
  }

  return (
    <div className="last-minute-study-section min-h-[80vh]">
      <p className="text-h1 font-landingPrimary mb-[0.4rem]">History Details</p>
      <hr className="lg:w-[28.188rem] w-full" />
      <div className="lg:hidden lg:mt-[2rem] mt-[1rem] md:w-full w-full relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full h-[2rem] py-[1.2rem] px-[1rem] bg-white border border-1 rounded-lg text-h5r font-landingPrimary flex justify-between items-center"
        >
          {categories.find((category) => category.id === selectedCategory)
            ?.name ?? 'Select Category'}
          <DropDownArrowIcon fill="#00000040" />
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white rounded-md shadow-lg">
            {categories.map((category) => (
              <li
                key={category.id}
                onClick={() => handleItemClick(category)}
                className="cursor-pointer px-[12px] py-[5px] text-h5r font-landingPrimary hover:bg-info hover:text-white"
              >
                {category.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <nav className="hidden lg:flex justify-between items-center bg-[#FAFAFA] mt-[1rem] lg:w-[28.188rem] w-full lg:h-[2.875rem] h-[4rem] lg:p-0 p-[0.4rem] rounded-lg text-center">
        <ul className="flex space-x-4 text-h5r font-landingPrimary text-neutrals-2 w-full h-full">
          {categories.map((category) => (
            <li
              key={category.id}
              onClick={() => handleItemClick(category)}
              className={`cursor-pointer flex items-center justify-center flex-grow rounded-lg ${selectedCategory === category.id ? 'text-h5r text-neutrals-4 bg-info' : ''}`}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </nav>
      {isLoading ? (
        <div className="mt-[2rem]">
          <LoadingMessage message="Loading history..." />
        </div>
      ) : error ? (
        <div className="mt-[2rem]">
          <ErrorMessage message={error} />
        </div>
      ) : (
        <>
          {selectedCategory === 1 && (
            <HistoryDetailsSpeakingMock testHistorySpeaking={testHistory} />
          )}
          {selectedCategory === 2 && (
            <HistoryDetailsWritingMock testHistoryWriting={testHistory} />
          )}
          {selectedCategory === 3 && (
            <HistoryDetailsReadingMock testHistoryReading={testHistory} />
          )}
          {selectedCategory === 4 && (
            <HistoryDetailsListeningMock testHistoryListening={testHistory} />
          )}
        </>
      )}
    </div>
  )
}

export default HistoryDetailsMockSection
