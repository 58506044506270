import { useState, useEffect, useRef } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import AudioListeningCourse from './AudioListeningCourse'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  getAllMockQuestions,
} from '../../services/practice.services'
import {
  handleExceptionError,
  navigateUserToPlanPage,
  toastError,
} from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import Option from '../../models/option.model'
import MockQuestion from '../../models/mockQuestion.model'
import ListeningQuestionStates from './ListeningQuestionStates'
import Path from '../../routes/Path'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getUserTokenDetails } from '../../services/user.services'
import { QuestionModelId } from '../../models/QuestionModels'

const FillInTheBlanksQuestion = () => {
  const navigate = useNavigate()
  const { userProfile } = useAuth()

  const [questionId, setQuestionId] = useState<string | null>(
    localStorage.getItem('questionIdPTE'),
  )
  const [answers, setAnswers] = useState<string[]>([])
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [originalDescription, setOriginalDescription] = useState('')
  const [originalOptions, setOriginalOptions] = useState<Option[]>([])
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)
  const audioRef = useRef<{ resetAudio: () => void }>(null)

  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()

    setIsSubmitted(false)
    setIsLoading(true)
    setTimeout(async () => {
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.L_FillInTheBlanks
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setOriginalDescription(mockQuestions[0].description || '')
              setOriginalOptions(response.data.option)
              const inputCount = countPlaceholders(
                mockQuestions[0].description
                  ? mockQuestions[0].description
                  : '',
              )
              setAnswers(Array(inputCount).fill(''))
              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [questionId, userProfile, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [questionId])

  // Function to calculate the number of input fields dynamically
  const countPlaceholders = (text: string) => {
    return (text.match(/\{\d+\}/g) || []).length
  }

  // Function to handle input changes
  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to format the paragraph with input fields
  const formattedParagraph = () => {
    // Only proceed if the question and its description are available
    if (!question || !question.description) {
      return null // Return null or a loading state
    }

    const parts = question.description.split(/(\{\d+\})/)
    return parts.map((part, index) => {
      const match = part.match(/\{(\d+)\}/)
      if (match && match.length >= 2) {
        const placeholderIndex = parseInt(match[1], 10) - 1 // Get the index from the placeholder (e.g., {1} -> index 0)
        return (
          <span key={index} className="inline-flex items-center mx-1">
            <span className="w-5 h-5 flex items-center justify-center rounded-full bg-info text-white text-xs">
              {placeholderIndex + 1}
            </span>
            <input
              type="text"
              maxLength={20}
              className="inline-block w-[8rem] h-[2rem] mx-1 p-1 rounded-lg border border-[#D9D9D9]"
              value={answers[placeholderIndex] || ''} // Bind the value to the corresponding state value
              onChange={(e) => handleInputChange(placeholderIndex, e)} // Update the state on input change
              disabled={isSubmitted}
            />
          </span>
        )
      }
      return <span key={index}>{part}</span>
    })
  }

  const resetState = () => {
    setIsSubmitted(false)
    setAnswers(Array(countPlaceholders(originalDescription)).fill(''))

    if (audioRef.current) {
      audioRef.current.resetAudio()
    }

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    setQuestionId(selectedQuestionId)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      setQuestionId(String(previousQuestionId))
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      setQuestionId(String(nextQuestionId))
    }
  }

  const allAnswersAreFilled = answers.every((answer) => answer.trim() !== '')

  const displayOriginalAnswer = (): string => {
    const originalAnswers = originalOptions.map((item) => item.options)

    return originalAnswers.reduce((updatedText, originalAnswer, index) => {
      // Construct the placeholder for each answer (e.g., {1}, {2})
      const placeholder = `{${index + 1}}`

      // Replace the placeholder with the styled answer
      return updatedText.replace(
        placeholder,
        `<span class="font-bold text-green-600 bg-green-200">${originalAnswer.trim().toLowerCase()}</span> `,
      )
    }, originalDescription)
  }

  const displayUserAnswer = (): string => {
    const originalAnswers = originalOptions.map((item) => item.options)

    return answers.reduce((updatedText, answer, index) => {
      const placeholder = `{${index + 1}}`
      let conditionDisplayRender = ''
      if (
        originalAnswers[index].trim().toLowerCase() ===
        answer.trim().toLowerCase()
      ) {
        conditionDisplayRender = `<span class="font-bold text-green-600 bg-green-200">${answer.trim().toLowerCase()}</span> `
      } else {
        conditionDisplayRender = `<span class="font-bold text-red-600 bg-red-200">${answer.trim().toLowerCase()}</span> `
      }

      return updatedText.replace(placeholder, conditionDisplayRender)
    }, originalDescription)
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [isSubmitted])

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="px-6 py-10">
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link
              to={
                testType === 'practice'
                  ? Path.practice.path
                  : Path.weeklyPrediction.path
              }
            >
              Practice
            </Link>{' '}
            /{' '}
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              Listening Section
            </Link>{' '}
            / <span className="text-neutrals-1">Fill In The Blanks</span>
          </p>
          <Link
            to={
              testType === 'practice'
                ? Path.practiceCourse.path
                : Path.weeklyPredictionCourse.path
            }
          >
            <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
              <ReturnArrowSvg fill="white" className="mr-2" />
              <span className="text-bodyr text-white">Return</span>
            </button>
          </Link>
          <p className="text-bodym text-danger mt-2">
            *This question carries marks for Listening (~8%) and Writing (~7%)
          </p>
          <div className="p-8 rounded-xl shadow mt-8" ref={questionSectionRef}>
            <p className="text-h4m text-neutrals-1 mb-[2rem]">
              {question?.short_title}
            </p>
            <AudioListeningCourse
              ref={audioRef}
              audioUrl={question && question ? question.audio : ''}
            />
            <div className="text-bodyr mb-4 leading-10">
              {formattedParagraph()}
            </div>
            <ListeningQuestionStates question={question} />
            <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
              <div className="flex place-content-center	mt-8">
                <button
                  className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 
                      ${!allAnswersAreFilled || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                  onClick={() => setIsSubmitted(true)}
                >
                  <img
                    src={WeeklyPredictionSubmitIcon}
                    alt="submit"
                    className="mr-2"
                  />
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              disabled={isFirstQuestion()}
              onClick={displayPreviousQuestion}
            >
              <PreviousArrowSvg
                fill={isFirstQuestion() ? 'gray' : 'white'}
                className="md:mr-2"
              />
              <p className="hidden md:block text-bodyr">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">
                  Try Again
                </p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              onClick={displayNextQuestion}
              disabled={isLastQuestion()}
            >
              <p className="hidden md:block text-bodyr">Next</p>
              <NextArrowSvg
                fill={isLastQuestion() ? 'gray' : 'white'}
                className="md:ml-2"
              />
            </button>
          </div>

          {isSubmitted && (
            <div
              ref={answerSectionRef}
              className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4"
            >
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Original Answer</p>
                <audio
                  controls
                  src={question?.audio}
                  preload="auto"
                  className="my-4 w-full"
                />
                <div className="mt-[1rem]">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: displayOriginalAnswer(),
                    }}
                  />
                </div>
              </div>
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Your Answer</p>
                <div className="mt-[1rem]">
                  <span
                    dangerouslySetInnerHTML={{ __html: displayUserAnswer() }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default FillInTheBlanksQuestion
