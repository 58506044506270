import React from 'react'
import Vector from '../../images/Landingpage/vector.png'
import TestimonialAvatar1 from '../../images/Landingpage/testimonial-1.png'
import TestimonialAvatar2 from '../../images/Landingpage/testimonial-2.png'
import TestimonialAvatar3 from '../../images/Landingpage/testimonial-3.png'
import TestimonialAvatar4 from '../../images/Landingpage/testimonial-4.png'
import TestimonialAvatar5 from '../../images/Landingpage/testimonial-5.png'
import TestimonialAvatar6 from '../../images/Landingpage/profile-no-image.svg'

const studentReviews = [
  {
    name: 'BIMBIM TRAN',
    message:
      'The whole education team did truly show that they cared about their students as well as identifying each individual’s weakness and strength. They also checked students performance through classes, online practices and mock tests together with giving genuine reviews. Got my desired results with exceed expectations after 2 training weeks under direction of both Huy Bui and Hieu Vu. I highly recommend PTEMaster to anyone who looks into taking PTE for whatever requirement that supports your future!',
    image: TestimonialAvatar6,
  },
  {
    name: 'BAO NHU',
    message:
      'I achieved my target score of 66+ on the first attempt with a short period of time of preparation. The platform is very helpful and the trainers are very supportive. Highly recommended for everyone.',
    image: TestimonialAvatar1,
  },
  {
    name: 'VIET NGUYEN',
    message:
      'Looking for the PTE 50 for my visa application but turned out 66 overall, out of expectation. Credit to both trainers on this platform, great trainers bring great results.',
    image: TestimonialAvatar2,
  },
  {
    name: 'NISHARA JOHNSON',
    message:
      'Great resource for anyone looking to score good results in PTE. Their immediate automatic feedback and suggestions boosted my confidence to take the test. I’m happy and satisfied with my result and will suggest PTE Master to my friends.',
    image: TestimonialAvatar3,
  },
  {
    name: 'YRAG CIVINOM OLNAF',
    message:
      'I highly recommend PTE Master. They have very simple and straightforward tips and ideas that would help you a lot with your target score. You just need to trust the process and use the practice tool they provide. Thank you so much PTE Master and Adi for the help.',
    image: TestimonialAvatar4,
  },
  {
    name: 'DELNA PULLUPARAMBIL',
    message:
      'Hi Guys I will highly recommend PTE Master as I got my score after learning their key strategies. I thank David for supporting me so much. It was such a blessing to have him as my tutor. Whenever I practiced and shared my work with David, he immediately gave feedback which was very helpful.',
    image: TestimonialAvatar5,
  },
]

const TestimonialsSection: React.FC = () => {
  return (
    <div className="testimonials-section w-full h-full bg-[#FFF7E6] lg:pb-16 py-8">
      <div className="testimonials-section__title w-full lg:px-[4rem] px-[2rem] py-8 flex flex-wrap justify-between items-center gap-y-4">
        <div className="testimonials-section__title__main font-landingLogo lg:w-[25.3rem]">
          <p className="text-base font-normal leading-[3.75rem]">
            Testimonials
          </p>
          <p className="text-h1 sm:text-[60px] font-bold leading-[3.75rem] text-[#FA8C16]">
            Listen to our customers
          </p>
        </div>
        <div className="testimonials-section__title__button">
          <a
            href="https://www.facebook.com/PTEMASTER/reviews?ref=page_internal"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bodyr font-landingPrimary text-white rounded-xl bg-[#FA8C16] px-6 py-4 flex items-center justify-center hover:scale-105"
          >
            <img src={Vector} alt="vector icon" className="mr-2" />
            See more reviews here
          </a>
        </div>
      </div>
      <div className="testimonials-section__grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-[2rem]">
        {studentReviews.map((review, index) => (
          <div
            key={index}
            className="h-fit bg-white p-6 rounded-xl shadow-lg flex flex-col items-start"
          >
            <img
              src={review.image}
              alt={`${review.name} Avatar`}
              className="w-16 h-16 rounded-full mb-4"
            />
            <h4 className="text-h5b text-[#1D4ED8] font-landingLogo mb-2">
              {review.name}
            </h4>
            <p className="text-bodyr text-neutrals-1 font-landingPrimary">
              {review.message}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TestimonialsSection
