import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PointSvg from '../../../images/Speaking/point.svg'
import ArrowDown from '../../../images/Speaking/arrow-down.svg'
import SummarizeSpokenTextQuestion from '../../../components/ListeningCourse/SummarizeSpokenTextQuestion'
import PracticeResult from '../../../models/practiceResult.model'
import { loadMoreSamples } from '../../../services/practice.services'
import {
  handleExceptionError,
  navigateUserToPlanPage,
} from '../../../utils/utils'
import ErrorMessage from '../../../components/Items/ErrorMessage'
import { useAuth } from '../../../providers/AuthProvider'
import { getUserTokenDetails } from '../../../services/user.services'
import Path from '../../../routes/Path'
import RealExamResultModal from '../RealExamResultModal'
import TestHistoryModal from '../TestHistoryModal'

const SummarizeSpokenText = () => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()

  const [activeCategory, setActiveCategory] = useState('Top Answers')
  const [showScoreBoard, setShowScoreBoard] = useState(false)
  const [topAnswers, setTopAnswers] = useState<PracticeResult[]>([])
  const [bestVerifiedAnswers, setBestVerifiedAnswers] = useState<
    PracticeResult[]
  >([])

  const selectedAnswers: PracticeResult[] =
    activeCategory === 'Top Answers' ? topAnswers : bestVerifiedAnswers

  const [detailsVisible, setDetailsVisible] = useState(
    Array(selectedAnswers.length).fill(false),
  )

  const [isModalImageOpen, setIsModalImageOpen] = useState(false)
  const [modalImage, setModalImage] = useState<string>('')
  const [isModalTestHistoryOpen, setIsModalTestHistoryOpen] = useState(false)
  const [selectedStudentId, setSelectedStudentId] = useState<number | null>(
    null,
  )

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()

    setTimeout(async () => {
      const questionId = localStorage.getItem('questionIdPTE')

      if (questionId && !isNaN(Number(questionId))) {
        try {
          const response = await loadMoreSamples(Number(questionId), 0)
          if (response.data.success) {
            setTopAnswers(response.data.bestRecordings)
            setBestVerifiedAnswers(response.data.bestRecordingsVerified)
          }
        } catch (error) {
          handleExceptionError(error)
        }
      } else {
        console.error('Missing information!')
      }
    }, 1000)
  }, [userProfile, navigate])

  const toggleDetails = (index: number) => {
    const updatedVisibility = [...detailsVisible]
    updatedVisibility[index] = !updatedVisibility[index]
    setDetailsVisible(updatedVisibility)
  }

  const handleRealExamResultClick = (image: string) => {
    setModalImage(image)
    setIsModalImageOpen(true)
  }

  const handleTestHistoryClick = (studentId: number) => {
    setSelectedStudentId(studentId)
    setIsModalTestHistoryOpen(true)
  }

  return (
    <div className="min-h-[80vh]">
      <SummarizeSpokenTextQuestion
        setShowScoreBoard={setShowScoreBoard}
        showScoreBoard={showScoreBoard}
      />
      {showScoreBoard && (
        <div className="mt-[3rem]">
          <div className="flex my-4 rounded-xl bg-gray-100 p-1 mx-auto w-[90%] xl:w-[60%]">
            <button
              className={`flex-1 p-2 text-center rounded-xl transition-colors duration-300 cursor-pointer ${
                activeCategory === 'Top Answers'
                  ? 'bg-primary-6 text-primary-1'
                  : 'bg-transparent text-neutrals-1'
              }`}
              onClick={() => setActiveCategory('Top Answers')}
            >
              Top Answers
            </button>
            <button
              className={`flex-1 p-2 text-center rounded-xl transition-colors duration-300 cursor-pointer ${
                activeCategory === 'Best Verified Answers'
                  ? 'bg-primary-6 text-primary-1'
                  : 'bg-transparent text-neutrals-1'
              }`}
              onClick={() => setActiveCategory('Best Verified Answers')}
            >
              Best Verified Answers{' '}
            </button>
          </div>
          {selectedAnswers.length === 0 ? (
            <ErrorMessage message="No data..." />
          ) : (
            selectedAnswers.map((answer, index) => (
              <div
                key={index}
                className={`p-8 shadow my-8 border border-[1px]-[#DFE3E6] rounded-lg
            ${
              index % 3 === 0
                ? 'shadow-[6px_6px_0_#FFBA68]'
                : index % 3 === 1
                  ? 'shadow-[6px_6px_0_#14AE5C]'
                  : 'shadow-[6px_6px_0_#F42424]'
            }
            `}
              >
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img
                      src={answer.userimage}
                      alt="avatar"
                      className="w-[40px] h-[40px] rounded-full mr-2"
                    />

                    <div>
                      <p className="text-h5m">{answer.username}</p>
                      <p
                        className="flex text-bodyr text-neutrals-2 cursor-pointer"
                        onClick={() => toggleDetails(index)}
                      >
                        <img
                          src={ArrowDown}
                          alt="arrow-down"
                          className="mr-2"
                        />
                        Show Details
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img src={PointSvg} alt="point" className="h-full mr-2" />
                    <div>
                      <p className="text-footnote">Points</p>
                      <p className="text-h2">
                        {Math.round(Number(answer.prac_result_marks))}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-[1rem]"
                  dangerouslySetInnerHTML={{ __html: answer.prac_transcript }}
                ></div>

                {detailsVisible[index] && (
                  <>
                    {activeCategory !== 'Top Answers' && (
                      <div className="my-4 flex flex-row-reverse gap-2">
                        {answer.show_on_dashbord === 1 && (
                          <button
                            className="py-1 px-4 rounded border border-1 border-[#00000040] text-bodyr bg-white hover:bg-[#E6E6E6]"
                            onClick={() =>
                              handleTestHistoryClick(answer.prac_result_user_id)
                            }
                          >
                            Mock Tests Analysis
                          </button>
                        )}
                        {answer.admin_upload !== '' && (
                          <button
                            className="py-1 px-4 rounded bg-[#2C62FF] hover:bg-[#1248e6] text-bodyr text-white"
                            onClick={() =>
                              handleRealExamResultClick(answer.admin_upload)
                            }
                          >
                            Real Exam Result
                          </button>
                        )}
                      </div>
                    )}
                    <div className="w-full grid grid-cols-4 gap-4 items-center mt-[4rem]">
                      <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                        <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#FF7A45] drop-shadow-lg">
                          {answer.prac_grammar}/2
                        </div>
                        <p className="text-bodyr text-neutrals-1 mt-2">
                          Grammar
                        </p>
                      </div>
                      <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                        <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#597EF7] drop-shadow-lg">
                          {answer.prac_content}/2
                        </div>
                        <p className="text-bodyr text-neutrals-1 mt-2">
                          Content
                        </p>
                      </div>
                      <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                        <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#722ED1] drop-shadow-lg">
                          {answer.prac_form}/2
                        </div>
                        <p className="text-bodyr text-neutrals-1 mt-2">Form</p>
                      </div>
                      <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                        <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#EB2F96] drop-shadow-lg">
                          {answer.prac_vocab}/2
                        </div>
                        <p className="text-bodyr text-neutrals-1 mt-2">
                          Vocalbulary
                        </p>
                      </div>
                      <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                        <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#FF7A45] drop-shadow-lg">
                          {answer.prac_spelling}/2
                        </div>
                        <p className="text-bodyr text-neutrals-1 mt-2">
                          Spelling
                        </p>
                      </div>
                      <div className="col-span-2 md:col-span-1 flex flex-col items-center mx-2">
                        <div className="text-white text-h5b w-[80px] h-[80px] content-center text-center px-2 py-4 rounded-full bg-[#FF7A45] drop-shadow-lg">
                          {Math.round(Number(answer.prac_result_marks))}/90
                        </div>
                        <p className="text-bodyr text-neutrals-1 mt-2">
                          Final score
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))
          )}
        </div>
      )}
      {isModalImageOpen && modalImage !== '' && (
        <RealExamResultModal
          isOpen={isModalImageOpen}
          onClose={() => setIsModalImageOpen(false)}
          image={modalImage}
        />
      )}
      {isModalTestHistoryOpen && selectedStudentId && (
        <TestHistoryModal
          studentId={selectedStudentId}
          onClose={() => setIsModalTestHistoryOpen(false)}
        />
      )}
    </div>
  )
}

export default SummarizeSpokenText
