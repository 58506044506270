import React, { useState } from 'react'
import { ReactComponent as ShopTickIcon } from '../../images/ShopPage/shop-tick-icon.svg'
import { ReactComponent as ShopErrorIcon } from '../../images/ShopPage/shop-error-icon.svg'
import { ReactComponent as ShopArrowRightIcon } from '../../images/ShopPage/shop-right-arrow-icon.svg'
import { ReactComponent as ShopCartIcon } from '../../images/ShopPage/shop-cart-icon.svg'
import ShopData from '../../models/shopData.model'
import { addToCart } from '../../services/cart.services'
import { useAuth } from '../../providers/AuthProvider'
import { toastError, toastSuccess } from '../../utils/utils'
import { useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'
interface AIPracticeSectionProps {
  shopData: ShopData
}

const AIPracticeSection: React.FC<AIPracticeSectionProps> = ({ shopData }) => {
  const { userProfile } = useAuth()
  const [selectedTrialPack, setSelectedTrialPack] = useState<number>(
    shopData.trialTokenPack[0]?.tkPkgId,
  )
  const [selectedVipPack, setSelectedVipPack] = useState<number>(
    shopData.vipTokenPack[0]?.tkPkgId,
  )
  const trialPack = shopData.trialTokenPack.find(
    (pack) => pack.tkPkgId === selectedTrialPack,
  )
  const vipPack = shopData.vipTokenPack.find(
    (pack) => pack.tkPkgId === selectedVipPack,
  )
  const navigate = useNavigate()

  const handleAddToCart = async (packId: number, packType: number) => {
    if (userProfile && userProfile.userId) {
      try {
        const response = await addToCart(
          userProfile.userId,
          packId,
          packType + 2,
        )
        if (response.data.success) {
          toastSuccess(response.data.message)
        } else {
          toastError(response.data.message)
        }
      } catch (error: any) {
        if (error instanceof TypeError) {
          toastError(error.message)
        } else {
          toastError('Something wrong!')
        }
      }
    } else {
      toastError('Something wrong!')
      console.log(userProfile)
    }
  }

  const handleBuyNow = async (packId: number, packType: number) => {
    if (userProfile && userProfile.userId) {
      try {
        const response = await addToCart(
          userProfile.userId,
          packId,
          packType + 2,
        )
        if (response.data.success) {
          toastSuccess(response.data.message)
          navigate(Path.cart.path)
        } else {
          toastError(response.data.message)
        }
      } catch (error: any) {
        if (error instanceof TypeError) {
          toastError(error.message)
        } else {
          toastError('Something wrong!')
        }
      }
    } else {
      toastError('Something wrong!')
      console.log(userProfile)
    }
  }

  return (
    <div className="ai-practice-section lg:w-1/2 md:w-1/2 w-full lg:h-[33.375rem] h-full flex flex-wrap gap-y-[2rem] lg:flex-col lg:gap-y-0 lg:gap-x-[1.6rem]">
      <div className="ai-practice-section__trial-token lg:w-[20.375rem] w-full border-2 border-[#0000000F] rounded-3xl p-4">
        <div className="trial-token__title flex justify-between items-center">
          <p className="text-h3r font-landingPrimary">Trial Token</p>
          <p className="text-bodyr font-landingPrimary text-white px-[7px] rounded-[7px] bg-primary-5 relative bottom-[6px]">
            Basic
          </p>
        </div>
        <div className="trial-token__price mt-[1rem]">
          <p className="text-h1 font-landingPrimary text-info flex items-center gap-x-4">
            AUD{' '}
            {trialPack?.tkPkgDiscount &&
              (
                (Number(trialPack?.tkPkgPrice) *
                  (100 - trialPack?.tkPkgDiscount)) /
                100
              ).toFixed(2)}
            <span className="text-xs text-neutrals-3 line-through font-medium font-landingPrimary mt-[0.4rem]">
              AUD {Number(trialPack?.tkPkgPrice).toFixed(2)}
            </span>
          </p>
        </div>
        <div className="trial-token__feature-options w-full mt-[1rem] relative">
          <select
            className="font-landingPrimary border-2 border-[#D9D9D9] rounded-md w-full h-[2.625rem] px-[12px] appearance-none select-none"
            value={selectedTrialPack}
            onChange={(e) => setSelectedTrialPack(Number(e.target.value))}
          >
            {shopData.trialTokenPack.map((pack) => (
              <option
                key={pack.tkPkgId}
                value={pack.tkPkgId}
                className="text-bodyr"
              >
                {pack.tkPkgName}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg
              className="h-4 w-4 text-[#00000040] fill-current"
              viewBox="0 0 20 20"
            >
              <path d="M10 12.586l-6.293-6.293a1 1 0 011.414-1.414L10 10.586l5.293-5.293a1 1 0 111.414 1.414L10 12.586z" />
            </svg>
          </div>
        </div>
        <div className="trial-token__feature-details font-landingPrimary my-[1rem]">
          <p className="text-bodym">Features</p>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="black" />
            <p className="text-bodyr">
              {trialPack?.tkPkgCredits} times{' '}
              <span className="text-bodym">Scoring Requests</span>
            </p>
          </div>
          {/* Add other feature details here, based on trialPack data */}
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopErrorIcon fill="#00000073" />
            <p className="text-bodyr text-neutrals-2 line-through">
              Download 90/90 templates
            </p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="black" />
            <p className="text-bodyr">Up to 5 answers per question</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopErrorIcon fill="#00000073" />
            <p className="text-bodyr text-neutrals-2 line-through">
              Reveal Keyword
            </p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopErrorIcon fill="#00000073" />
            <p className="text-bodyr text-neutrals-2 line-through">
              Sectional Test
            </p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopErrorIcon fill="#00000073" />
            <p className="text-bodyr text-neutrals-2 line-through">200 days</p>
          </div>
        </div>
        <hr className="my-[0.4rem] border border-1 border-[#0000000F]" />
        <div className="trial-token__purchase w-full mt-[1rem] flex flex-col gap-y-2">
          <button
            onClick={() =>
              trialPack &&
              handleBuyNow(trialPack?.tkPkgId, trialPack?.tkPkgType)
            }
            className="w-full px-[15px] py-[6.4px] border border-[#000000D9] hover:border-transparent hover:bg-info text-h5r hover:text-white rounded-lg flex justify-center items-center gap-x-4"
          >
            <ShopArrowRightIcon className="fill-current transition-colors duration-200 " />
            Buy Now
          </button>
          <button
            className="w-full px-[15px] py-[6.4px] border border-transparent hover:border-[#F0F7FF] hover:bg-[#F0F7FF] text-h5r rounded-lg flex justify-center items-center gap-x-4"
            onClick={() =>
              trialPack &&
              handleAddToCart(trialPack?.tkPkgId, trialPack?.tkPkgType)
            }
          >
            <ShopCartIcon fill="#000000D9" />
            Add To Cart
          </button>
        </div>
      </div>
      <div className="ai-practice-section__vip-token bg-primary-6 lg:w-[20.375rem] w-full border-2 border-[#0000000F] rounded-3xl p-4">
        <div className="trial-token__title flex justify-between items-center">
          <p className="text-h3r text-white font-landingPrimary">VIP Token</p>
          <p className="text-bodyr font-landingPrimary px-[7px] rounded-[7px] bg-secondary-6 relative bottom-[6px]">
            Popular
          </p>
        </div>
        <div className="trial-token__price mt-[1rem]">
          <p className="text-h1 font-landingPrimary text-white flex items-center gap-x-4">
            AUD{' '}
            {vipPack?.tkPkgDiscount &&
              (
                (Number(vipPack?.tkPkgPrice) * (100 - vipPack?.tkPkgDiscount)) /
                100
              ).toFixed(2)}
            <span className="text-xs text-[#AAAAAA] line-through font-medium font-landingPrimary mt-[0.4rem]">
              AUD {Number(vipPack?.tkPkgPrice).toFixed(2)}
            </span>
          </p>
        </div>
        <div className="trial-token__feature-options w-full mt-[1rem] relative">
          <select
            className="text-bodyr font-landingPrimary border-2 border-transparent rounded-md w-full h-[2.625rem] px-[12px] appearance-none select-none"
            value={selectedVipPack}
            onChange={(e) => setSelectedVipPack(Number(e.target.value))}
          >
            {shopData.vipTokenPack.map((pack) => (
              <option
                key={pack.tkPkgId}
                value={pack.tkPkgId}
                className="text-bodyr"
              >
                {pack.tkPkgName}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg
              className="h-4 w-4 text-[#00000040] fill-current"
              viewBox="0 0 20 20"
            >
              <path d="M10 12.586l-6.293-6.293a1 1 0 011.414-1.414L10 10.586l5.293-5.293a1 1 0 111.414 1.414L10 12.586z" />
            </svg>
          </div>
        </div>
        <div className="trial-token__feature-details text-white font-landingPrimary my-[1rem]">
          <p className="text-bodym">Features</p>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">
              {vipPack?.tkPkgCredits && vipPack?.tkPkgCredits > 0
                ? `${vipPack.tkPkgCredits} times`
                : 'Unlimited'}{' '}
              <span className="text-bodym">Scoring Requests</span>
            </p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">Download 90/90 templates</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">Up to 5 answers per question</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">Reveal Keyword</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">Sectional Test</p>
          </div>
          <div className="flex items-center gap-x-2 w-full my-[0.4rem]">
            <ShopTickIcon fill="white" />
            <p className="text-bodyr">200 days</p>
          </div>
        </div>
        <hr className="my-[0.4rem] border border-1 border-[#FFFFFF4D]" />
        <div className="trial-token__purchase w-full mt-[1rem] flex flex-col gap-y-2">
          <button
            onClick={() =>
              vipPack && handleBuyNow(vipPack?.tkPkgId, vipPack?.tkPkgType)
            }
            className="w-full px-[15px] py-[6.4px] border border-info hover:border-transparent bg-info hover:bg-[#1890FF] text-white text-h5r rounded-lg flex justify-center items-center gap-x-4"
          >
            <ShopArrowRightIcon fill="white" />
            Buy Now
          </button>
          <button
            className="w-full px-[15px] py-[6.4px] text-white text-h5r hover:bg-[#002C5C] border border-white rounded-lg flex justify-center items-center gap-x-4"
            onClick={() =>
              vipPack && handleAddToCart(vipPack?.tkPkgId, vipPack?.tkPkgType)
            }
          >
            <ShopCartIcon fill="white" />
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  )
}

export default AIPracticeSection
