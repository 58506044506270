import React, { useState, useEffect, useRef } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  getAllMockQuestions,
} from '../../services/practice.services'
import {
  handleExceptionError,
  navigateUserToPlanPage,
  toastError,
} from '../../utils/utils'
import ArrayUtils from '../../utils/ArrayUtils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import Option from '../../models/option.model'
import MockQuestion from '../../models/mockQuestion.model'
import ReadingQuestionStates from './ReadingQuestionStates'
import Path from '../../routes/Path'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getUserTokenDetails } from '../../services/user.services'
import { QuestionModelId } from '../../models/QuestionModels'

interface NewArrayOptions {
  type: number
  data: string
  answer: string
}

const ReadingFillInTheBlanksQuestion = () => {
  const navigate = useNavigate()

  const [questionId, setQuestionId] = useState<string | null>(
    localStorage.getItem('questionIdPTE'),
  )
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [originalDescription, setOriginalDescription] = useState('')
  const [newArrayOptions, setNewArrayOptions] = useState<NewArrayOptions[]>([])
  const [originalOptions, setOriginalOptions] = useState<Option[]>([]) //This one is the original options get from data
  const [options, setOptions] = useState<Option[]>([]) //This one will be changed if the drop or remove function is triggered
  const [totalOptionsInParagraph, setTotalOptionsInParagraph] = useState(0)
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const { userProfile } = useAuth()
  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)

  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()

    const fetchData = async () => {
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.R_ReadingFillInTheBlanks
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setOriginalDescription(mockQuestions[0].description || '')
              setNewArrayOptions(
                response.data.descriptionArray.map((part: NewArrayOptions) => ({
                  ...part,
                  answer: '', // Reset the answer to be empty
                })),
              )
              setOriginalOptions(response.data.option)
              setOptions(ArrayUtils.shuffleArray(response.data.option))
              const totalOptionsInQuestion = () => {
                return response.data.descriptionArray.filter(
                  (part: NewArrayOptions) => part.type === 1,
                ).length
              }
              setTotalOptionsInParagraph(totalOptionsInQuestion)

              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }

    fetchData()
  }, [questionId, userProfile, navigate])

  const dragItem = useRef<{ id: string; source: 'items' | 'newArray' } | null>(
    null,
  )

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    id: string,
    source: 'items' | 'newArray',
  ) => {
    dragItem.current = { id, source }
    e.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetIndex: number,
  ) => {
    e.preventDefault()
    if (!dragItem.current) return

    const { id, source } = dragItem.current
    const droppedItem = options.find((option) => String(option.id) === id)

    if (newArrayOptions[targetIndex].answer) {
      return
    }

    if (source === 'items' && newArrayOptions[targetIndex].type === 1) {
      const existingAnswer = newArrayOptions[targetIndex].answer
      let updatedOptions = [...options]

      if (existingAnswer) {
        const originalOption = options.find(
          (option) => option.options === existingAnswer,
        )
        if (originalOption) {
          updatedOptions = [...updatedOptions, originalOption]
        }
      }

      const updatedArray = newArrayOptions.map((part, index) => {
        if (index === targetIndex) {
          return { ...part, answer: droppedItem ? droppedItem.options : '' }
        }
        return part
      })

      updatedOptions = updatedOptions.filter(
        (option) => String(option.id) !== id,
      )
      setNewArrayOptions(updatedArray)
      setOptions(updatedOptions)
    }
  }

  const removeOption = (index: number) => {
    // Get the option to be removed from newArrayOptions
    const removedOptionText = newArrayOptions[index].answer

    // Only proceed if there is a valid option to remove
    if (removedOptionText) {
      // Clear the selected answer from newArrayOptions at the given index
      const updatedArrayOptions = newArrayOptions.map((part, i) => {
        if (i === index) {
          return { ...part, answer: '' } // Clear the answer for this slot
        }
        return part
      })

      // Find the original option in the options list (based on the answer text)
      const removedOption = options.find(
        (option) => option.options === removedOptionText,
      )

      // If the removed option is not already in options, find it in the question's option list and restore it
      if (!removedOption) {
        // Find the original option in the list of options related to the question
        const originalOption = originalOptions.find(
          (option) => option.options === removedOptionText,
        )

        if (originalOption) {
          // Restore the original option back to the available options
          setOptions((prevOptions) => [...prevOptions, originalOption])
        }
      }

      // Update the state for newArrayOptions
      setNewArrayOptions(updatedArrayOptions)
    }
  }

  // Function to count filled options
  const countFilledOptions = () => {
    return newArrayOptions.filter((part) => part.answer !== '').length
  }

  const getAllAnswers = () => {
    return newArrayOptions
      .filter((part) => part.answer !== '') // Only include parts with answers
      .map((part) => ({
        data: part.data, // Return the `data` field
        answer: part.answer, // Optionally, include the answer if you need it
      }))
  }

  const displayOriginalAnswer = () => {
    const originalOptionList = originalOptions.map(
      (originalOption) => originalOption.options,
    )

    return originalOptionList.reduce((updatedText, originalOption, index) => {
      // Construct the placeholder for each answer (e.g., $$@@^^!!1$$@@^^!!) $$@@^^!!1$$@@^^!!
      const placeholder = `$$@@^^!!${index + 1}$$@@^^!!`

      return updatedText.replace(
        placeholder,
        `<span class="font-bold text-green-600 bg-green-200">${originalOption.trim()}</span> `,
      )
    }, originalDescription)
  }

  const replacePlaceholdersForAnswer = (
    originalDescription: string,
    answers: string[],
  ): string => {
    return answers.reduce((updatedText, answer, index) => {
      // Construct the placeholder for each answer (e.g., $$@@^^!!1$$@@^^!!) $$@@^^!!1$$@@^^!!
      const placeholder = `$$@@^^!!${index + 1}$$@@^^!!`
      let conditionDisplayRender = ''
      if (originalOptions[index].options === answer) {
        conditionDisplayRender = `<span class="font-bold text-green-600 bg-green-200">${answer.trim()}</span> `
      } else {
        conditionDisplayRender = `<span class="font-bold text-red-600 bg-red-200">${answer.trim()}</span> `
      }

      // Replace each placeholder with the corresponding answer from answers array
      return updatedText.replace(placeholder, conditionDisplayRender)
    }, originalDescription)
  }

  const displayUserAnswer = (): string => {
    const allAnswers = getAllAnswers()
    const answersWithCorrectValues = allAnswers.map((answer) => {
      // Find the original option that matches the user's answer
      const matchingOption = originalOptions.find(
        (option) => option.options.trim() === answer.answer.trim(),
      )

      return {
        data: answer.data,
        userAnswer: answer.answer,
        correctValue: matchingOption ? matchingOption.correct : 0,
      }
    })

    const userAnswerArray = answersWithCorrectValues.map(
      (answer) => answer.userAnswer,
    )
    const userAnswerStr = replacePlaceholdersForAnswer(
      originalDescription,
      userAnswerArray,
    )

    return userAnswerStr
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [isSubmitted])

  const filledOptionsCount = countFilledOptions()
  const isSaveDisabled = filledOptionsCount < totalOptionsInParagraph

  const resetState = () => {
    setIsSubmitted(false)
    setNewArrayOptions(newArrayOptions.map((part) => ({ ...part, answer: '' })))
    setOptions(ArrayUtils.shuffleArray(originalOptions))

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    setQuestionId(selectedQuestionId)
    resetState()
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      setQuestionId(String(previousQuestionId))
      resetState()
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      setQuestionId(String(nextQuestionId))
      resetState()
    }
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="px-6 py-10" ref={questionSectionRef}>
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link
              to={
                testType === 'practice'
                  ? Path.practice.path
                  : Path.weeklyPrediction.path
              }
            >
              Practice
            </Link>{' '}
            /{' '}
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              Reading Section
            </Link>{' '}
            /{' '}
            <span className="text-neutrals-1">Reading Fill In The Blanks</span>
          </p>
          <div className="w-fit">
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
                <ReturnArrowSvg fill="white" className="mr-2" />
                <span className="text-bodyr text-white">Return</span>
              </button>
            </Link>
          </div>
          <p className="text-bodym text-danger mt-2 md:mt-8">
            *This question carries marks for Reading only (~25%)
          </p>

          <div className="p-8 rounded-xl shadow mt-8">
            <p className="text-h4m text-neutrals-1">{question?.short_title}</p>
            <div className="my-4 leading-10">
              <div>
                {newArrayOptions.map((part, index) =>
                  part.type === 1 ? (
                    <div
                      key={index}
                      onDrop={(e) => handleDrop(e, index)}
                      onDragOver={handleDragOver}
                      className="min-w-[100px] min-h-[45px] text-center content-center mx-2 mt-1 border border-neutrals-2 rounded-lg inline-block bg-white px-2"
                    >
                      {part.answer ? (
                        <div className="flex justify-between items-center">
                          <span className="text-bodyr">{part.answer}</span>
                          <button
                            onClick={() => removeOption(index)}
                            className={`ml-2 ${isSubmitted ? 'text-gray-500' : 'text-red-500 hover:text-red-700'}`}
                            disabled={isSubmitted}
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        <div className="flex justify-between items-center mt-3">
                          <span className="text-placeholder"></span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <span key={index} className="text-bodyr">
                      {part.data}
                    </span>
                  ),
                )}
              </div>
            </div>
            <div className="flex flex-wrap gap-4 text-blue-500 border-[1px] lg:p-8 p-4 rounded-md">
              {options.map((option) => (
                <div
                  key={option.id}
                  draggable
                  onDragStart={(e) =>
                    handleDragStart(e, String(option.id), 'items')
                  }
                  className={`text-bodyr bg-[#F5F5F5] border border-[1px]-[#0000000F] text-neutrals-1 hover:text-white hover:bg-info px-6 py-2 rounded-md cursor-pointer`}
                >
                  {option.options}
                </div>
              ))}
            </div>
            <div className="flex place-content-center mt-8">
              <button
                className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 ${isSubmitted || isSaveDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                onClick={() => setIsSubmitted(true)}
                disabled={isSubmitted || isSaveDisabled}
              >
                <img
                  src={WeeklyPredictionSubmitIcon}
                  alt="submit"
                  className="mr-2"
                />
                Submit
              </button>
            </div>

            <ReadingQuestionStates question={question} />
          </div>
          <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              disabled={isFirstQuestion()}
              onClick={displayPreviousQuestion}
            >
              <PreviousArrowSvg
                fill={isFirstQuestion() ? 'gray' : 'white'}
                className="md:mr-2"
              />
              <p className="hidden md:block text-bodyr">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">
                  Try Again
                </p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              onClick={displayNextQuestion}
              disabled={isLastQuestion()}
            >
              <p className="hidden md:block text-bodyr">Next</p>
              <NextArrowSvg
                fill={isLastQuestion() ? 'gray' : 'white'}
                className="md:ml-2"
              />
            </button>
          </div>

          {isSubmitted && (
            <div
              ref={answerSectionRef}
              className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4"
            >
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Original Answer</p>
                <div className="mt-[1rem]">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: displayOriginalAnswer(),
                    }}
                  />
                </div>
              </div>
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Your Answer</p>
                <div className="mt-[1rem]">
                  <span
                    dangerouslySetInnerHTML={{ __html: displayUserAnswer() }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ReadingFillInTheBlanksQuestion
