import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithPopup,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  try {
    const result = await signInWithPopup(auth, provider)
    // This gives you a Google Access Token. You can use it to access Google APIs.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential?.accessToken;
    // console.log(token);
    // The signed-in user info.
    const user = result.user
    return user
  } catch (error) {
    console.error('Error signing in with Google', error)
    throw error
  }
}

export const sendPasswordReset = async (email: string) => {
  const actionCodeSettings = {
    url: 'http://localhost:3000/password',
    handleCodeInApp: true, // Ensure the link opens in your app
  }

  try {
    await sendPasswordResetEmail(auth, email, actionCodeSettings)
    console.log(`Password reset email sent to ${email}`)
    return `Password reset email sent to ${email}`
  } catch (error) {
    console.error('Error sending password reset email', error)
    throw error
  }
}
