import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import TextArea from '../../components/FormElements/TextArea'
import TextInput from '../../components/FormElements/TextInput'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'
import StudentReviewBanner from '../../images/Landingpage/student_review_banner.png'
import TestimonialAvatar1 from '../../images/Landingpage/testimonial-1.png'
import TestimonialAvatar2 from '../../images/Landingpage/testimonial-2.png'
import TestimonialAvatar3 from '../../images/Landingpage/testimonial-3.png'
import TestimonialAvatar4 from '../../images/Landingpage/testimonial-4.png'
import TestimonialAvatar5 from '../../images/Landingpage/testimonial-5.png'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

// Dummy Data for Reviews
const studentReviews = [
  {
    name: 'BIMBIM TRAN',
    message:
      'The whole education team did truly show that they cared about their students as well as identifying each individual’s weakness and strength. They also checked students performance through classes, online practices and mock tests together with giving genuine reviews.Got my desired results with exceed expectations after 2 training weeks under direction of both Huy Bui and Hieu Vu.I highly recommend PTEMaster to anyone who looks into taking PTE for whatever requirement that supports your future!',
    image: TestimonialAvatar1,
  },
  {
    name: 'BAO NHU',
    message:
      'I achieved my target score of 66+ on the first attempt with a short period of time of preparation. The platform is very helpful and the trainers are very supportive. Highly recommended for everyone.',
    image: TestimonialAvatar1,
  },
  {
    name: 'VIET NGUYEN',
    message:
      '"Looking for the pte 50 for my visa application but turned out 66 overall, out of expectation. Credit to both trainers on this platform, great trainers bring great results."',
    image: TestimonialAvatar2,
  },
  {
    name: 'NISHARA JOHNSON',
    message: `"Great resource for anyone looking to score good results in PTE. Their immediate automatic feedback and suggestions boosted my confidence to take the test. I'm happy and satisfied with my result and will suggest PTE Master to my friends"`,
    image: TestimonialAvatar3,
  },
  {
    name: 'YRAG CIVINOM OLNAF',
    message:
      '"I highly recommend pte master. They have a very simple and straight forward tips and ideas that would help you a lot with your target score. You just need to trust the process and use the practice tool that they have provided. You can always message adi and he will reply to you as soon as possible. The practice tool is just the best, plus they have the new option for the practice questions which is the weekly prediction which is very essential if you will take your exam days or weeks before. Thank you so much PTE master and Adi for the help."',
    image: TestimonialAvatar4,
  },
  {
    name: 'DELNA PULLUPARAMBIL',
    message:
      '"Hi Guys I will highly recommend PTE Master as I got my score after learning their key strategies. I thank David for supporting me Soo much. It was such a blessing to have him as my tutor. More than a tutor he was like a friend to me and motivated me a lot. As I am from Brisbane and I got the online sessions which was very flexible. The very good thing is that whenever I practice and share my work to David he would immediately give me feedbacks which was very helpful. Therefore PTE Master is really good for everyone who would like to get their desired scores."',
    image: TestimonialAvatar5,
  },
]

const StudentReviewPage = () => {
  return (
    <div className="bg-[#FAFAFA] pb-[80px]">
      <div className="w-full">
        <img
          src={StudentReviewBanner}
          className="w-full"
          alt="student review"
        />
      </div>

      <div className="lg:w-[80%] w-full lg:px-0 px-8 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 my-[80px]">
        <div className="lg:w-[80%] w-full">
          <p className="text-h1">
            What Our Students Have to Say about PTE Training
          </p>
          <p className="text-bodyr mt-6 text-[#595959]">
            PTE Master is the leading PTE center in Australia. Our Founder &
            Owner Ady Bui, has crafted a method to help international students
            in Australia pass the PTE Exam with less efforts and preparation
            time. Started since 2016, PTE Master has helped over 3000 students
            succeed in their PTE exam using cutting-edge technology in PTE AI.
            Mr. Ady has built one of the best PTE tools that host all quality
            PTE materials, tutorials that are all free, real, and authentic. And
            it does not stop at that. AI technology has allowed PTE Master to
            develop a sophisticated scoring mechanism that aligns with the
            standards of Pearson Test Of English, allowing students to practice
            intensively and achieve their PTE target in a very short amount of
            time.
          </p>
          <p className="text-h4m text-[#595959]">
            Will your story start here with PTE Master?
          </p>
        </div>

        {/* Swiper Section */}
        <div className="lg:mt-0 mt-8">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            loop={true}
            speed={800}
            pagination={{
              enabled: true,
              clickable: true,
              el: '.custom-pagination',
            }}
            modules={[Autoplay, Pagination]}
          >
            {studentReviews.map((review, index) => (
              <SwiperSlide key={index}>
                <div className="flex p-6 bg-white rounded-lg shadow-md">
                  <img
                    src={review.image}
                    alt={review.name}
                    className="w-16 h-16 rounded-full mr-4"
                  />
                  <div>
                    <p className="text-h4 font-bold text-primary-5">
                      {review.name}
                    </p>
                    <p className="text-bodyr text-[#595959] mt-4">
                      {review.message}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Pagination Dots */}
          <div className="custom-pagination mt-4 flex justify-center gap-x-2"></div>
        </div>
      </div>

      <SkillCTASection />

      <div className="mt-[60px] flex justify-center items-center px-8">
        <div className="w-full lg:max-w-[70%] rounded-lg lg:p-8 flex flex-col lg:flex-row gap-8">
          {/* Contact Information Section */}
          <div className="w-full md:w-1/2">
            <h2 className="text-h1 mb-[32px]">Book a demo class</h2>
          </div>

          <div className="w-full lg:w-1/2">
            <form className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <TextInput placeholder="Full name" label="Full name" />
                <TextInput
                  placeholder="Enter email address"
                  type="email"
                  label="Email"
                />
              </div>
              <TextArea label="Message" placeholder="Type here..." />

              <button
                type="submit"
                className="text-white !mt-[32px] px-6 py-[10px] rounded-[8px] w-full"
                style={{
                  background:
                    'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
                }}
              >
                Submit Form
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentReviewPage
