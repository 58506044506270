import React, { useState } from 'react'
import { ReactComponent as TrashBinIcon } from '../../images/Cart/trash-bin-icon.svg'
import { checkPromo } from '../../services/cart.services'
import { toastError, toastSuccess } from '../../utils/utils'
import { CartBundle } from '../../models/cartBundle.model'

interface Props {
  bundle: CartBundle
  onRemoveItem: (id: number) => void
  onUpdatePrice: (id: number, newPrice: string) => void // New prop to update price
}

const CartBundleCard: React.FC<Props> = ({
  bundle,
  onRemoveItem,
  onUpdatePrice,
}) => {
  const [finalPrice, setFinalPrice] = useState(bundle.product_final_price)
  const [promoCode, setPromoCode] = useState<string>('')

  const handleApplyPromo = async () => {
    try {
      const response = await checkPromo(
        bundle.user_id,
        bundle.cart_id,
        bundle.product_id,
        4,
        promoCode.trim(),
      )
      if (response.data.success) {
        setFinalPrice(response.data.price)
        onUpdatePrice(bundle.cart_id, response.data.price)
        toastSuccess(response.data.message)
      } else {
        toastError(response.data.message)
      }
    } catch (error: any) {
      if (error instanceof TypeError) {
        toastError(error.message)
      } else {
        toastError('Something wrong!')
      }
    }
  }

  return (
    <>
      <div className="products__card w-full lg:min-h-[10.5rem] h-full flex items-center">
        <div className="product__card__info lg:w-[30.063rem] lg:h-[10.5rem] h-full w-full bg-[#FAFAFA] p-4 rounded-lg flex flex-col gap-2">
          <div className="product__card__info__title flex justify-between items-center lg:gap-0 gap-2">
            <p className="text-h4m">{bundle.bundleName}</p>
            <p className="text-h4m text-info">
              <span className="text-h5m text-[#00000040] line-through mr-[0.4rem]">
                {bundle.price}
              </span>
              {`${finalPrice}AUD`}
            </p>
          </div>
          <div className="product__card__info__description">
            <p className="text-bodyr text-neutrals-2">
              Type: {bundle.product_type_name}
            </p>
            <p className="text-bodyr text-neutrals-2">
              <div
                dangerouslySetInnerHTML={{ __html: bundle.bundleDescription }}
              />
            </p>
          </div>
          <div className="product__card__promo-code flex gap-4">
            <input
              type="text"
              placeholder="Promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className="lg:min-w-[22.25rem] w-3/4 h-[2.75rem] border border-1 border-[#0000000F] rounded-lg p-[7px] cart-promo-code"
            />
            <button
              onClick={handleApplyPromo}
              className="lg:min-w-[4.813rem] w-1/4 h-[2.75rem] rounded-lg text-white text-bodyr bg-primary-9 hover:bg-[#00354A] disabled:bg-[#D3D3D3] disabled:cursor-not-allowed"
              disabled={!promoCode.trim()}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="lg:block hidden product__card__remove lg:w-[1.6rem]">
          <button
            onClick={() => onRemoveItem(bundle.cart_id)}
            className="ml-[0.8rem]"
          >
            <TrashBinIcon />
          </button>
        </div>
      </div>
    </>
  )
}

export default CartBundleCard
