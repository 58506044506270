import { Link } from 'react-router-dom'
import Path from '../../../routes/Path'
import ReportHistorySpeakingSectionalSection from '../../../components/SectionalTest/ReportHistory/ReportHistorySpeakingSectionalSection'

const ReportHistorySpeakingSectionalPage = () => {
  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to={Path.home.path}>Home</Link> / Sectional Test /{' '}
          <Link to={Path.testHistorySectional.path}>
            Test History & Analysis
          </Link>{' '}
          / Report History Speaking
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <ReportHistorySpeakingSectionalSection />
      </div>
    </>
  )
}

export default ReportHistorySpeakingSectionalPage
