import React from 'react'

interface TextAreaProps {
  label?: string // Optional label
  placeholder: string
  rows?: number // Number of rows for the textarea
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  placeholder,
  rows = 6,
}) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label className="text-bodyr mb-[8px] text-neutrals-2">{label}</label>
      )}
      <textarea
        placeholder={placeholder}
        rows={rows}
        className="border border-gray-300 p-3 rounded-md w-full bg-[#F9F9F9] text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  )
}

export default TextArea