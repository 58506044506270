import { Link } from 'react-router-dom'
import UserProfileSection from '../components/UserProfilePage/UserProfileSection'

const UserProfilePage = () => {
  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / User Profile
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-1 rounded-l-lg">
        <UserProfileSection />
      </div>
    </>
  )
}

export default UserProfilePage
