import YoutubeVideo from '../../models/youtubeVideo.model'

const PTEVideoCard: React.FC<{ youtubeVideo: YoutubeVideo }> = ({
  youtubeVideo,
}) => {
  return (
    <div className="youtube-videos-section__list__item w-full max-w-[19.625rem] h-full rounded-lg mx-auto">
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          className="youtube-videos-section__list__video w-full h-full rounded-lg"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          loading="eager"
          src={youtubeVideo.url}
          title={youtubeVideo.title}
        ></iframe>
      </div>
      <div className="youtube-videos-section__list__title w-full flex gap-2 items-center mt-[0.625rem]">
        <p className="text-bodyr sm:text-h5m font-landingPrimary">
          {youtubeVideo.title}
        </p>
      </div>
    </div>
  )
}

export default PTEVideoCard
