import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRightIcon } from '../../images/Landingpage/arrow-right-icon.svg'
import Path from '../../routes/Path'

const CTASection: React.FC = () => {
  const pteFeatureLists = [
    'Full PTE Academic practice tests',
    'Model Answers',
    'Real PTE Exam based Sample questions',
    'PTE Ebook',
    'PTE Practice Sectional Tests',
    'PTE Video tutorials',
    'Developed by PTE certified trainers',
    'Scored AI PTE Mock Test',
    'PTE Academic Study Plan',
  ]

  return (
    <div className="cta-section sm:px-8 px-0 py-[2rem] lg:h-[43rem] h-full flex justify-between">
      <div className="cta-section__main w-full lg:h-[40rem] h-fit bg-[#E3EBFF] rounded-t-xl">
        <div
          className="cta-section__main__first lg:px-8 lg:pl-[105px] px-4 py-8 rounded-xl grid lg:grid-cols-2 grid-cols-1 gap-y-4"
          style={{
            background: 'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
          }}
        >
          <div className="w-full h-fit py-9 col-span-1 font-landingPrimary text-neutrals-4 mx-auto">
            <p className="text-h1">ONLINE PTE PRACTICE</p>
            <p className="text-bodyr w-2/3 my-4">
              One of the best PTE Academic Exam preparation tools to evaluate
              your performance
            </p>
            <Link
              to={Path.landing.register.path}
              className="px-4 py-2 block w-[8.438rem] text-bodyr text-info bg-neutrals-4 rounded-md hover:scale-105"
            >
              Get Started Now
            </Link>
            {/* <Link
              to="/"
              className="text-neutrals-4 block text-bodyr block mt-[1rem] w-fit border-b border-white flex gap-x-4 hover:scale-105"
            >
              See our features
              <ArrowRightIcon fill="white" />
            </Link> */}
          </div>
          <div className="col-span-1 mx-auto">
            <iframe
              title="PTE Master Video"
              src="https://drive.google.com/file/d/1HNvXSrwADod21Z19QBCIaLmoBBntBHBp/preview"
              className="w-[90vw] h-[20vh] sm:w-[75vw] sm:h-[30vh] md:w-[540px] md:h-[340px] lg:w-[440px] lg:h-[240px] rounded-lg"
              allow="autoplay"
            ></iframe>
          </div>
        </div>
        <div className="cta-section__main__second lg:h-[22.25rem] lg:px-8 lg:pl-[105px] px-4 py-8 flex flex-wrap justify-between">
          <div className="lg:w-1/2 w-full text-neutrals-1 font-bodyr font-landingPrimary flex flex-wrap gap-y-2">
            {pteFeatureLists.map((feature, index) => (
              <div key={index} className="w-1/2 flex gap-3">
                <ArrowRightIcon fill="black" className="w-fit" />
                <p className="w-4/5">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTASection
