import React from 'react'
import { ReactComponent as StarActive } from '../../images/Landingpage/Faqspage/star-active.svg'
import { ReactComponent as Start } from '../../images/Landingpage/Faqspage/star.svg'

type TestimonialCardProps = {
  avatar: string
  name: string
  rating: number // Rating out of 5
  content: string
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  avatar,
  name,
  rating,
  content,
}) => {
  // Helper to render stars
  const renderStars = (rating: number) => {
    const stars = []
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} className="inline-flex items-center">
          {i < rating ? (
            <StarActive className="w-5 h-5 text-yellow-500" />
          ) : (
            <Start className="w-5 h-5 text-gray-300" />
          )}
        </span>,
      )
    }
    return stars
  }

  return (
    <div className="flex flex-col rounded-lg mt-[40px] p-6 bg-white shadow-sm">
      <div className="flex">
        <img
          src={avatar}
          alt={`${name}'s avatar`}
          className="w-16 h-16 rounded-full mb-4 mr-[16px]"
        />
        <div className="flex flex-col items-start">
          <p className="text-lg font-semibold mb-[10px]">{name}</p>
          <div className="flex mb-4">{renderStars(rating)}</div>
        </div>
      </div>
      <p className="text-gray-600">{content}</p>
    </div>
  )
}

export default TestimonialCard
