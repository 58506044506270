import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PerformanceChartSection from './PerformanceChartSection'
import TestPerformanceSection from './TestPerformanceSection'
import ComparisonSection from './ComparisonSection'
import MyPerformance from '../../models/myPerformance.model'
import { useAuth } from '../../providers/AuthProvider'
import { getUserScoring } from '../../services/performance.services'
import { getUserDetails } from '../../services/user.services'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'

const MyPerformanceSection = () => {
  const [resultPerformance, setResultPerformance] = useState<MyPerformance[]>(
    [],
  )
  const [yourTargetScore, setYourTargetScore] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const { userProfile } = useAuth()

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      try {
        getUserScoring(userProfile.userId).then((response) => {
          if (response.data.success) {
            setResultPerformance(response.data.result)
          }
          setIsLoading(false) // Notice: If put in finally will affect UX
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      console.error(userProfile)
    }
  }, [userProfile])

  useEffect(() => {
    if (userProfile && userProfile.userId) {
      try {
        getUserDetails(userProfile.userId).then((response) => {
          if (response.data.success) {
            setYourTargetScore(response.data.userInfo.target_score)
          } else {
            console.error(response.data.message)
          }
        })
      } catch (error) {
        console.error(error)
      }
    } else {
      console.log(userProfile)
    }
  }, [userProfile])

  return (
    <div className="my-performance-section font-landingPrimary w-full">
      <div className="my-performance-section__title w-full flex flex-col justify-center items-center gap-4">
        <p className="text-h1">My Performance</p>
        <hr className="w-[17.688rem]" />
        <Link
          to="/practice-performance"
          className="w-[11.938rem] h-[1.75rem] text-footnote border-2 border-info rounded-[6.25rem] flex justify-center items-center hover:scale-105"
        >
          Check Out Practice Performance
        </Link>
      </div>
      {isLoading ? (
        <div className="mt-[2rem]">
          <LoadingMessage message="Loading Your Performance information ..." />
        </div>
      ) : resultPerformance.length === 0 ? (
        <div className="mt-[2rem]">
          <ErrorMessage message="You haven't attempted any mock test, please complete at least 1 test to unlock this feature!" />
        </div>
      ) : (
        <>
          <PerformanceChartSection data={resultPerformance} />
          <TestPerformanceSection data={resultPerformance} />
          <ComparisonSection
            data={resultPerformance}
            yourTargetScore={yourTargetScore}
          />
        </>
      )}
    </div>
  )
}

export default MyPerformanceSection
