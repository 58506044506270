import React from 'react'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'
import SkillHeader from '../../components/LandingPage/AcademicSkill/SkillHeader'
import SkillTestFormat from '../../components/LandingPage/AcademicSkill/SkillTestFormat'
import AcademicBanner from '../../images/Landingpage/academic-banner.png'

const AcademicListeningPage: React.FC = () => {
  // Dynamic content for SkillHeader
  const skillHeaderContent = {
    title: 'PTE Academic Listening Section',
    subtitle:
      'Evaluate your effectiveness in English language listening with PTE Academic Listening Test',
    description:
      'Do you want to certify your English language listening skills? The Pearson English Proficiency Listening Test is a comprehensive area of the exam. The listening section also helps improve your PTE score. Therefore, students need the assistance of reliable PTE test preparation course providers for qualifying all sections in Pearson Test of English.',
    bannerImage: AcademicBanner,
  }

  // Dynamic content for SkillTestFormat
  const skillTestTabs = [
    {
      id: 'test-format',
      label: 'Test Format',
      content: (
        <div>
          <p className="text-bodyr pb-4">
            The Listening section is the third part of the Pearson Test of
            English (PTE) Academic exam. Listening exams based on audio clips
            examine your ability to understand spoken English and communicate
            what you have heard, as well as process the information received.
          </p>
          <ul className="list-disc text-bodyr pl-4">
            <li className="flex items-start">
              <span className="text-green-600 font-bold mr-2">✔</span>
              <p>
                {' '}
                The primary task in the PTE Academic listening section involves
                listening to audio or video clips and answering the
                corresponding questions accordingly.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-green-600 font-bold mr-2">✔</span>
              <p>
                {' '}
                The total duration of time allotted for the listening section is
                around 45 to 47 minutes.
              </p>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 'skill-test',
      label: 'Skill Test',
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[
            {
              title: 'Summarize Spoken Text',
              description: [
                'Candidates will get 2-3 audio clips, they will have to hear those audio clips properly & then write a summary of 50 to 70 words.',
                'Candidates will get 60-90 seconds to record their responses.',
                'The scoring in this section is based on listening and writing skills.',
              ],
            },
            {
              title: 'Multiple-choice, multiple answers',
              description: [
                'Candidates will have to listen to a recording and then they have to give a relevant answer from multiple options. Also they can give more than one option to the same question.',
                'All students get 40 to 90 seconds to record each response.',
                'The scoring in this section is based on the listening ability of candidates for analysis, interpretation, and evaluation of a short audio or video clip regarding an academic subject.',
              ],
            },
            {
              title: 'Fill in the Blanks',
              description: [
                'Candidates will receive an audio recording on the screen.',
                'Candidates will then find a transcript of the recording with some words missing in between.',
                'Candidates have to fill in the blank spaces in the transcript based on what they hear in the recording.',
                'The scoring criteria for this section are solely the content i.e., whether the response includes all the missing words.',
                'Each correct response scores one point for the candidate.',
              ],
            },
            {
              title: 'Highlight Correct Summary',
              description: [
                'Candidates receive an audio clip in this section, and they can listen to the recording only once.',
                'This section would provide different summaries as options.',
                'Candidates have to choose the right summary for the recording and select it in their response.',
                'The scoring for this section depends on the ability of the candidate for comprehension, analysis, and a combination of information from a recording.',
              ],
            },
            {
              title: 'Multiple-choice, single answer',
              description: [
                'Candidates have to listen to an audio recording in this section of the listening part of the PTE test. Candidates will have to answer multiple-choice questions with a single response. The questions will be based on the content in the audio recording.',
                'The scoring depends only on whether the response is correct or not.',
              ],
            },
            {
              title: 'Select Missing Word',
              description: [
                'Candidates have to select a word from a list of multiple options to complete an audio recording in this section.',
                'The scoring for this section depends on the candidate’s ability to predict the speaker’s statements on the basis of contextual clues in the recording.',
              ],
            },
            {
              title: 'Highlight Incorrect Words',
              description: [
                'Candidates have to identify the wrong words in a transcript of an audio recording after hearing it.',
                'The score of a candidate in this section depends on their ability to listen and identify the differences between a recording and a transcript.',
              ],
            },
            {
              title: 'Dictation Writing',
              description: [
                'This is the final and most crucial aspect of PTE study in the listening section.',
                'Candidates will be providing audio which they have to listen carefully and write the content exactly what they listened.',
                'The scoring criteria for this section are only content. The score would be on the basis of your writing skills in accordance with the dictated content.',
              ],
            },
          ].map((item, index) => (
            <div key={index} className="bg-[#F5F5F5] p-4 rounded-lg">
              <p className="text-h4m mb-2">{item.title}</p>
              <ul className="list-none space-y-2 text-[#595959]">
                {item.description.map((point, i) => (
                  <li key={i} className="flex items-start">
                    <span className="text-green-600 font-bold mr-2">✔</span>
                    <p className="text-bodyr">{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ),
    },
  ]

  return (
    <div className="w-full bg-white">
      {/* Header Section */}
      <SkillHeader
        title={skillHeaderContent.title}
        subtitle={skillHeaderContent.subtitle}
        description={skillHeaderContent.description}
        bannerImage={skillHeaderContent.bannerImage}
      />

      {/* Test Format Section */}
      <SkillTestFormat tabs={skillTestTabs} />

      {/* Call-to-Action Section */}
      <div className="w-full py-8 bg-[#FAFAFA]">
        <SkillCTASection />
      </div>
    </div>
  )
}

export default AcademicListeningPage
