import { Link } from 'react-router-dom'
import TestHistoryMockSection from '../../components/MockTest/TestHistory/TestHistoryMockSection'

const TestHistoryMockPage = () => {
  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Mock Test / Test History & Analysis
        </p>
      </div>
      <div className="w-full min-h-[100vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <TestHistoryMockSection />
      </div>
    </>
  )
}

export default TestHistoryMockPage
