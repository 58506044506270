import { useState } from 'react'
import WeeklyPredictionBannerSvg from '../images/WeeklyPrediction/weekly-prediction-banner.svg'
import { Link, useLocation } from 'react-router-dom'
import { SkillName } from '../models/QuestionModels'
import CategoryPractice from '../models/categoryPractice.model'
import { Subcategory } from '../models/categoryPractice.model'

interface DesktopCourseListProps {
  catgories: CategoryPractice[]
  freeCourseStatusIdList: number[]
}

const parentCategories = [
  {
    id: 0,
    name: 'All',
  },
  {
    id: 1,
    name: SkillName.Speaking,
  },
  {
    id: 2,
    name: SkillName.Writing,
  },
  {
    id: 3,
    name: SkillName.Reading,
  },
  {
    id: 4,
    name: SkillName.Listening,
  },
]

const heading: string[] = ['Name Course', 'Zone', 'Amount', 'Status', 'Action']

const DesktopCourseList: React.FC<DesktopCourseListProps> = ({
  catgories,
  freeCourseStatusIdList,
}) => {
  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  const itemsPerPage = 5
  const [activeCategory, setActiveCategory] = useState('All')
  const [currentPage, setCurrentPage] = useState(1)

  const getSubCategoryItems = (categoryId?: number): Subcategory[] => {
    if (categoryId) {
      return catgories
        .filter((item) => item.id === categoryId) // Filter for "Speaking Section"
        .flatMap((item) => item.subCategoryItems)
    }
    return catgories.flatMap((category) => category.subCategoryItems) // Default: return all subcategories
  }

  let currentItems: Subcategory[] = []

  switch (activeCategory) {
    case SkillName.Speaking:
      currentItems = getSubCategoryItems(1)
      break
    case SkillName.Writing:
      currentItems = getSubCategoryItems(2)
      break
    case SkillName.Reading:
      currentItems = getSubCategoryItems(3)
      break
    case SkillName.Listening:
      currentItems = getSubCategoryItems(4)
      break
    default:
      currentItems = getSubCategoryItems()
      break
  }

  // Filter out hidden categories
  const hiddenCategoryIds = [12, 13, 18, 20, 21, 22]
  currentItems = currentItems
    .filter((item) => !hiddenCategoryIds.includes(item.id))
    .sort((a, b) => {
      if (a.parent === b.parent) {
        return a.id - b.id // If parents are the same, sort by id
      }
      return a.parent - b.parent // Otherwise, sort by parent
    })

  const totalPages = Math.ceil(currentItems.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  currentItems = currentItems.slice(startIndex, startIndex + itemsPerPage)

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleTabClick = (categoryName: string) => {
    setActiveCategory(categoryName)
    setCurrentPage(1)
  }

  const displayCourseZone = (parentCategoryId: number): string => {
    switch (parentCategoryId) {
      case 1:
        return 'speaking'
      case 2:
        return 'writing'
      case 3:
        return 'reading'
      case 4:
        return 'listening'
      default:
        return 'unknown'
    }
  }

  return (
    <div className="">
      <div className="relative bg-primary-9 min-h-[320px] md:min-h-[400px] text-center rounded-t-lg">
        <p className="text-h1 text-primary-1 pt-12">
          {testType === 'practice' ? 'Practice' : 'Weekly Prediction'}
        </p>
        <p className="text-bodyr text-primary-2 mx-4 lg:mx-[22%] pt-4">
          Welcome to the practice test section! Here, you'll find a variety of
          exercises designed to help you prepare effectively. Our goal is to
          provide you with the tools you need to succeed, ensuring that you feel
          confident and ready for the real test. Dive in and start practicing
          your skills today!
        </p>

        <div className="absolute inset-0 flex justify-center items-center top-[100%] mx-4 lg:m-0">
          <img src={WeeklyPredictionBannerSvg} alt="Speaking Banner" />
        </div>
      </div>

      <div className="flex my-4 rounded-xl bg-gray-100 p-1 mx-auto w-[90%] xl:w-[60%] mt-52">
        {parentCategories.map((parentCategory, index) => (
          <button
            key={index}
            className={`flex-1 p-2 text-bodyr text-neutrals-1 text-center rounded-xl transition-colors duration-300 cursor-pointer ${
              activeCategory === parentCategory.name
                ? 'bg-primary-6 text-primary-1'
                : 'bg-transparent text-neutrals-1'
            }`}
            onClick={() => handleTabClick(parentCategory.name)}
          >
            {parentCategory.name}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-5 mr-4 xl:mx-[10%] text-center mt-8">
        {heading.map((head, index) => (
          <div
            key={index}
            className="text-footnote text-neutrals-2 uppercase text-center py-2"
          >
            {head}
          </div>
        ))}
      </div>

      {currentItems.map((test, index) => (
        <div
          key={index}
          className={`border border-[1px]-[#DFE3E6] rounded-lg py-4 mr-4 xl:mx-[10%] text-center items-center grid grid-cols-5 my-8 ${
            index % 5 === 0
              ? 'shadow-[6px_6px_0_rgba(255,186,104,1)]'
              : index % 5 === 1
                ? 'shadow-[6px_6px_0_rgba(36,69,244,1)]'
                : index % 5 === 2
                  ? 'shadow-[6px_6px_0_rgba(52,244,36,1)]'
                  : index % 5 === 3
                    ? 'shadow-[6px_6px_0_rgba(244,36,36,1)]'
                    : index % 5 === 4
                      ? 'shadow-[6px_6px_0_rgba(178,36,244,1)]'
                      : ''
          }`}
        >
          <p className="col-span-1 text-h5b uppercase max-w-[80%] mx-auto">
            {test.category}
          </p>
          <p className="col-span-1 text-footnote text-[#858585] uppercase">
            {displayCourseZone(test.parent)}
          </p>
          <p className="col-span-1 text-footnote text-[#858585] uppercase">
            {test.totalQuestion} Quesions
          </p>
          <p
            className={`col-span-1 text-footnote px-2 py-1 w-[120px] rounded-full mx-auto
           ${
             freeCourseStatusIdList.includes(test.id)
               ? 'bg-[#52C41A] text-white'
               : 'bg-[#F5D90A] text-black'
           }
           `}
          >
            {freeCourseStatusIdList.includes(test.id)
              ? 'Free Course'
              : 'Token Required'}
          </p>
          <Link
            to={
              testType === 'practice'
                ? '/practice/course'
                : '/weekly-prediction/course'
            }
          >
            <button
              className="col-span-1 text-bodyr text-neutrals-1 uppercase border border-[1px]-[#D9D9D9] rounded-lg xl:px-6 p-2 w-fit mx-auto hover:scale-105"
              onClick={() => {
                localStorage.setItem('questionTypePTE', String(test.id))
              }}
            >
              Practice Now
            </button>
          </Link>
        </div>
      ))}
      <div className="flex w-[50%] md:w-[20%] m-auto justify-evenly items-center my-4">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="hover:text-info relative"
        >
          <span className="relative z-10">{'<'}</span>
          <span
            className="absolute -top-2 -left-4"
            aria-hidden="true"
            style={{
              display: 'block',
              content: '""',
              width: '40px', // Adjust clickable width
              height: '40px', // Adjust clickable height
            }}
          ></span>
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`${currentPage === index + 1 ? 'text-info' : 'text-gray-700'}`}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="hover:text-info relative"
        >
          <span className="relative z-10">{'>'}</span>
          <span
            className="absolute -top-2 -left-4"
            aria-hidden="true"
            style={{
              display: 'block',
              content: '""',
              width: '40px', // Adjust clickable width
              height: '40px', // Adjust clickable height
            }}
          ></span>
        </button>
      </div>
    </div>
  )
}

export default DesktopCourseList
