import React from 'react'
import { Link } from 'react-router-dom'

interface Blog {
  title: string
  slug: string // Add slug to link to blog posts
  image: string
  time: string
}

interface BlogListProps {
  tabs: { name: string; count: number }[]
  blogs: Blog[]
  activeTab: string
  setActiveTab: (tab: string) => void
}

const BlogList: React.FC<BlogListProps> = ({
  tabs,
  blogs,
  activeTab,
  setActiveTab,
}) => {
  const getBlogsByTab = (tab: string) => {
    switch (tab) {
      case 'PTE Coaching':
        return blogs
      case 'PTE Migration':
        return [...blogs].reverse() // Reversed order for this tab
      case 'PTE Test':
        return [...blogs].sort(() => Math.random() - 0.5) // Random order for this tab
      default:
        return blogs
    }
  }

  return (
    <div className="w-full">
      <div className="flex flex-col mb-8">
        <p className="text-h1 mb-8">Blogs</p>
        <div className="flex space-x-4 sm:flex-row flex-col">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={`px-4 py-2 text-h5m font-medium rounded-md transition ${
                activeTab === tab.name
                  ? 'text-white bg-gradient-to-t from-[#2054E9] to-[#2C62FF]'
                  : 'text-[#00000073] hover:bg-gray-100'
              }`}
            >
              {tab.name} ({tab.count})
            </button>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {getBlogsByTab(activeTab).map((blog, index) => (
          <Link to={`/blog/post/${blog.slug}`}>
            <div
              key={index}
              className="rounded-md shadow-md hover:shadow-xl transition-transform overflow-hidden"
            >
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full object-cover"
              />
              <div className="p-4">
                <button className="px-6 py-2 bg-yellow-500 text-white rounded-full text-footnote">
                  View Practice Demo
                </button>
                <p className="text-h4m text-[#141414] mt-4">{blog.title}</p>
                <p className="text-h5m text-[#BFBFBF] mt-2">{blog.time}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default BlogList
