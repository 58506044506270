import React, { useEffect, useState } from 'react'
import OverallScoreMock from './OverallScoreMock'
import BarChartScoreMock from './BarChartScoreMock'
import historyTestMenu from '../../../images/MockTest/history-test-menu.svg'
import ReportHistoryDetails from '../../../models/reportHistoryDetails.model'

interface AnalyticsModalProps {
  show: boolean
  test: ReportHistoryDetails
}

const AnalyticMockModal: React.FC<AnalyticsModalProps> = ({
  show,
  test,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(1)
  const [visible, setVisible] = useState(false)
  const [style, setStyle] = useState({ maxHeight: '0px', opacity: 0 })

  useEffect(() => {
    if (show) {
      setVisible(true)
      setTimeout(() => {
        setStyle({ maxHeight: '200vh', opacity: 1 })
      }, 10)
    } else {
      setStyle({ maxHeight: '0px', opacity: 0 })
      setTimeout(() => setVisible(false), 300)
    }
  }, [show])

  if (!visible) return null

  const categories = [
    { name: 'Communicative Skills', id: 1 },
    { name: 'Enabling Skills', id: 2 },
  ]

  const score = {
    average: Math.round(test.average),
    speaking: Math.round(test.speaking),
    listening: Math.round(test.listening),
    reading: Math.round(test.reading),
    writing: Math.round(test.writing),
  }

  const skill = {
    vocabulary: Math.round(test.vocabulary),
    spelling: Math.round(test.spelling),
    grammar: Math.round(test.grammar),
    pronunciation: Math.round(test.pronunciation),
    form: Math.round(test.form),
    fluency: Math.round(test.fluency),
    writtenDiscourse: Math.round(test.form),
  }

  // Function to handle item click
  const handleItemClick = (item: any) => {
    setSelectedCategory(item.id)
  }

  return (
    <div
      className="overflow-y-auto transition-all duration-300 ease-in-out"
      style={style}
    >
      <div className="bg-white mt-4 rounded-lg shadow-lg">
        <div className="flex justify-between items-center border-b border-gray-200 p-4">
          <div className="flex">
            <img
              src={historyTestMenu}
              alt="history-test-menu"
              className="mr-2"
            />
            <p className="text-xl align-center font-semibold">Report</p>
          </div>
        </div>
        <div className="block md:grid grid-cols-4 gap-4 py-4 p-4 md:p-0">
          <div className="col-span-1">
            <OverallScoreMock score={test.average} />
          </div>
          <div className="col-span-3">
            <BarChartScoreMock score={score} />
          </div>
        </div>
        <div className="border-t border-gray-200 p-4">
          <p className="text-xl font-semibold text-left">Analytics</p>
          <nav className="flex justify-between items-center bg-[#FAFAFA] my-[2rem] lg:w-[28.188rem] w-full lg:h-[2.875rem] h-[4rem] lg:p-0 p-[0.4rem] rounded-lg text-center">
            <ul className="flex space-x-4 text-h5r font-landingPrimary text-neutrals-2 w-full h-full">
              {categories.map((category) => (
                <li
                  key={category.id}
                  onClick={() => handleItemClick(category)}
                  className={`cursor-pointer flex items-center justify-center flex-grow rounded-lg ${selectedCategory === category.id ? 'text-bodyr text-neutrals-4 bg-info' : ''}`}
                >
                  {category.name}
                </li>
              ))}
            </ul>
          </nav>
          {selectedCategory === 1 && <BarChartScoreMock score={score} />}
          {selectedCategory === 2 && <BarChartScoreMock score={skill} />}
        </div>
      </div>
    </div>
  )
}

export default AnalyticMockModal
