import React from 'react'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'
import SkillHeader from '../../components/LandingPage/AcademicSkill/SkillHeader'
import SkillTestFormat from '../../components/LandingPage/AcademicSkill/SkillTestFormat'
import AcademicBanner from '../../images/Landingpage/academic-banner.png'

const AcademicReadingPage: React.FC = () => {
  // Dynamic content for SkillHeader
  const skillHeaderContent = {
    title: 'PTE Academic Reading Section',
    subtitle:
      'Prove your English language comprehension skills in the PTE Academic Reading Test',
    description:
      "Fluency in English reading is the most important ingredient to clear PTE academic reading section. The reading section is one of the significant aspects of the PTE exam. This section mainly emphasizes examining candidates' ability to read and understand authentic texts from various academic subjects.",
    bannerImage: AcademicBanner,
  }

  // Dynamic content for SkillTestFormat
  const skillTestTabs = [
    {
      id: 'test-format',
      label: 'Test Format',
      content: (
        <div>
          <p className="text-bodyr pb-4">
            The most important detail for candidates in the PTE reading exam is
            the format of the exam. The exam is a computer-based test for
            evaluating the English language proficiency of non-native English
            speakers. The total duration of the exam is 3 hours, and candidates
            could get their results quickly.
          </p>
          <ul className="list-none space-y-2">
            {[
              'The reading section contains passages and related questions for testing the reading skills of candidates.',
              'Every question is associated with certain instructions that every candidate should read carefully.',
              'The total duration allotted for the reading section is up to 35 minutes.',
              'The timer will be running on the computer screen during the test. Candidates should manage all the questions within the allotted time limit.',
            ].map((point, index) => (
              <li key={index} className="flex items-start">
                <span className="text-green-600 font-bold mr-2">✔</span>
                <p className="text-bodyr">{point}</p>
              </li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      id: 'skill-test',
      label: 'Skill Test',
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[
            {
              title: 'The multiple-choice question, single answer',
              description: [
                'Candidates would get a passage, and they have to read the text carefully.',
                'Based on the passage provided for reading, candidates could find 2 to 3 questions with multiple options.',
                'Candidates could select only one option as the correct answer in this section.',
                'Candidates can improve their PTE score by focusing on the passage precisely and spending not more than 2 minutes on each question.',
              ],
            },
            {
              title: 'Multiple-Choice, Multiple Answers',
              description: [
                'The next section in the Pearson Test of English Reading section also presents a passage, followed by 2 to 3 questions.',
                'The questions in this section also have multiple options.',
                'However, candidates have to select multiple options as answers to the questions.',
                'Candidates will get negative marking i.e., points for each correct response and deduction for an incorrect response.',
                'The passage would be around 300 words in this section.',
              ],
            },
            {
              title: 'Reorder Paragraphs',
              description: [
                'Candidates would find different paragraphs in random order on the left-hand space on their computer screen.',
                'Candidates have to arrange the paragraphs in the ordered manner on the right-hand side of their computer screens.',
                'The length of the text would be around 150 words.',
                'The test also involves partial credit for each correctly ordered paragraph.',
              ],
            },
            {
              title: 'Reading- Fill in the Blanks',
              description: [
                'Another important highlight in the course content of a Real PTE practice platform and the reading section of the PTE test is the “fill in the blanks” section.',
                'There will be some words below the passage, and candidates have to choose the relevant word for the proper blanks.',
                'The scoring for this section is also partial credit for each correct word.',
              ],
            },
          ].map((item, index) => (
            <div key={index} className="bg-[#F5F5F5] p-4 rounded-lg">
              <p className="text-h4m mb-2">{item.title}</p>
              <ul className="list-none space-y-2 text-[#595959]">
                {item.description.map((point, i) => (
                  <li key={i} className="flex items-start">
                    <span className="text-green-600 font-bold mr-2">✔</span>
                    <p className="text-bodyr">{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ),
    },
  ]

  return (
    <div className="w-full bg-white">
      {/* Header Section */}
      <SkillHeader
        title={skillHeaderContent.title}
        subtitle={skillHeaderContent.subtitle}
        description={skillHeaderContent.description}
        bannerImage={skillHeaderContent.bannerImage}
      />

      {/* Test Format Section */}
      <SkillTestFormat tabs={skillTestTabs} />

      {/* Call-to-Action Section */}
      <div className="w-full py-8 bg-[#FAFAFA]">
        <SkillCTASection />
      </div>
    </div>
  )
}

export default AcademicReadingPage
