import { useState, useEffect, useRef } from 'react'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import AudioListeningCourse from './AudioListeningCourse'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  getAllMockQuestions,
} from '../../services/practice.services'
import {
  handleExceptionError,
  navigateUserToPlanPage,
  toastError,
} from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import Option from '../../models/option.model'
import MockQuestion from '../../models/mockQuestion.model'
import ListeningQuestionStates from './ListeningQuestionStates'
import Path from '../../routes/Path'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getUserTokenDetails } from '../../services/user.services'
import { QuestionModelId } from '../../models/QuestionModels'

const HighlightIncorrectWordsQuestion = () => {
  const navigate = useNavigate()
  const { userProfile } = useAuth()

  const [questionId, setQuestionId] = useState<string | null>(
    localStorage.getItem('questionIdPTE'),
  )
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [options, setOptions] = useState<Option[]>([])
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const [highlightedWords, setHighlightedWords] = useState<number[]>([])
  const [showIncorrectWords, setInShowIncorrectWords] = useState(false)

  const audioRef = useRef<{ resetAudio: () => void }>(null)

  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()

    setHighlightedWords([])
    setInShowIncorrectWords(false)
    setIsLoading(true)
    setTimeout(async () => {
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.L_HighlightIncorrectWords
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setOptions(response.data.option)

              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }, 1000)
  }, [questionId, userProfile, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [questionId])

  const handleToggleVisibility = () => {
    setInShowIncorrectWords((prevState) => !prevState)
  }

  const answers = options.map((option) => option.options)

  const formattedOriginalParagraph = (originalString: string): string => {
    return originalString.replace(/<\/?span[^>]*>/g, '')
  }

  const handleWordClick = (index: number) => {
    setHighlightedWords((prevWords) =>
      prevWords.includes(index)
        ? prevWords.filter((w) => w !== index)
        : [...prevWords, index],
    )
  }

  const renderParagraph = (text: string) => {
    const stripPunctuation = (word: string) =>
      word
        .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
        .toLowerCase()
        .trim()

    const replacePlaceholders = (text: string): string =>
      text.replace(/\{(\d+)\}/g, (match, p1) => {
        const option = options.find((opt) => String(opt.correct) === p1) // Ensure `correct` is compared as a string
        return option ? option.options : match // Replace or leave placeholder as is
      })

    const processedText = replacePlaceholders(text)

    return processedText.split(' ').map((word, index) => {
      const cleanWord = stripPunctuation(word)
      const isHighlighted = highlightedWords.includes(index)
      const isAnswer = answers.map(stripPunctuation).includes(cleanWord)

      const originalPlaceholderMatch = text.match(/\{(\d+)\}/g)
      const isPlaceholderAnswer =
        originalPlaceholderMatch &&
        originalPlaceholderMatch.some((placeholder) => {
          const placeholderIndex = placeholder.replace(/\{|\}/g, '')
          return (
            options.find((opt) => opt.correct === Number(placeholderIndex))
              ?.options === cleanWord
          )
        })

      return (
        <>
          <span
            key={index}
            onClick={() => handleWordClick(index)}
            className={`
              cursor-pointer  ${
                isPlaceholderAnswer && showIncorrectWords
                  ? 'text-neutrals-1 bg-blue-400'
                  : isAnswer && showIncorrectWords
                    ? 'text-neutrals-1 bg-green-400'
                    : isHighlighted &&
                      'text-neutrals-1 bg-[#FFA39E] color-black'
              }
            `}
          >
            {word}
          </span>
          <span> </span>
        </>
      )
    })
  }

  const resetState = () => {
    if (audioRef.current) {
      audioRef.current.resetAudio()
    }
    setHighlightedWords([])
    setInShowIncorrectWords(false)
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    setQuestionId(selectedQuestionId)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      setQuestionId(String(previousQuestionId))
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      setQuestionId(String(nextQuestionId))
    }
  }

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="px-6 py-10">
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link
              to={
                testType === 'practice'
                  ? Path.practice.path
                  : Path.weeklyPrediction.path
              }
            >
              Practice
            </Link>{' '}
            /{' '}
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              Listening Section
            </Link>{' '}
            / <span className="text-neutrals-1">Highlight Incorrect Words</span>
          </p>
          <Link
            to={
              testType === 'practice'
                ? Path.practiceCourse.path
                : Path.weeklyPredictionCourse.path
            }
          >
            <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
              <ReturnArrowSvg fill="white" className="mr-2" />
              <span className="text-bodyr text-white">Return</span>
            </button>
          </Link>
          <p className="text-bodym text-danger mt-2">
            * This question carries marks for Listening (~8%)and Reading (~8%)
          </p>
          <div className="p-8 rounded-xl shadow mt-8">
            <p className="text-h4m text-neutrals-1 mb-[2rem]">
              {question?.short_title}
            </p>
            <AudioListeningCourse
              ref={audioRef}
              audioUrl={question && question ? question.audio : ''}
            />
            <div className="text-h5r text-neutrals-2 mb-4">
              {question && question.description
                ? renderParagraph(
                    formattedOriginalParagraph(question.description.trim()),
                  )
                : ''}
            </div>
            <ListeningQuestionStates question={question} />
            <div className="w-[90%] lg:w-[60%] mb-4 mx-auto">
              <div className="flex place-content-center	mt-8">
                <button
                  className="flex text-bodyr text-white items-center py-2 px-6 bg-success rounded-lg mr-2"
                  onClick={handleToggleVisibility}
                >
                  <img
                    src={WeeklyPredictionSubmitIcon}
                    alt="submit"
                    className="mr-2"
                  />
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              disabled={isFirstQuestion()}
              onClick={displayPreviousQuestion}
            >
              <PreviousArrowSvg
                fill={isFirstQuestion() ? 'gray' : 'white'}
                className="md:mr-2"
              />
              <p className="hidden md:block text-bodyr">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">
                  Try Again
                </p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              onClick={displayNextQuestion}
              disabled={isLastQuestion()}
            >
              <p className="hidden md:block text-bodyr">Next</p>
              <NextArrowSvg
                fill={isLastQuestion() ? 'gray' : 'white'}
                className="md:ml-2"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default HighlightIncorrectWordsQuestion
