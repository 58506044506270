import React from 'react'
import pteToolBanner from '../../images/Landingpage/PTE Tool/pte-tool-banner.png'
import pteLastMinuteStudy from '../../images/Landingpage/PTE Tool/pte-tool-last-minute-study.png'
import pteStudyZone from '../../images/Landingpage/PTE Tool/pte-tool-study-zone.png'
import pteStudy from '../../images/Landingpage/PTE Tool/pte-tool-study.png'
import SwitchableSection from '../../components/SwitchableSection'
import { ReactComponent as TickIcon } from '../../images/Landingpage/PTE Tool/tick.svg'
import { ReactComponent as ArrowRightIcon } from '../../images/Landingpage/PTE Tool/arrowRight.svg'
import SkillCTASection from '../../components/LandingPage/AcademicSkill/SkillCTASection'

const PreparationToolPage: React.FC = () => {
  const sectionsData = [
    {
      subtitle: 'Welcome to',
      title: 'PTE Master, A one-stop shop for PTE practice',
      body: (
        <div className="space-y-4">
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <p className="text-gray-600">
              Let me walk you through step by step how you can leverage our
              technology to bring you closer to your desired score!
            </p>
          </div>
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <p className="text-gray-600">
              Simply click the button below to start a walkthrough!
            </p>
          </div>
        </div>
      ),
      image: pteStudy,
      imageAlt: 'Welcome to PTE Master',
      buttonLabel: 'Show System',
    },
    {
      subtitle: 'Welcome to PTE',
      title: 'Self-study zone',
      body: (
        <div className="space-y-4">
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">Practice AI</p>
              <p className="text-gray-600">
                Section will allow you to practice with our AI-scored questions
                in all PTE academic Speaking, Listening, Reading, and Writing
                modules.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">Buy Token</p>
              <p className="text-gray-600">
                Area will allow you to purchase tokens to allow you to study in
                the PTE practice section.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">My Token</p>
              <p className="text-gray-600">
                Area will allow you to view all of your purchased tokens.
              </p>
            </div>
          </div>
        </div>
      ),
      image: pteStudyZone,
      imageAlt: 'Self-study zone',
      buttonLabel: 'View Practice Demo',
    },
    {
      subtitle: 'Prepare yourself for',
      title: 'PTE Academic Test',
      body: (
        <div className="space-y-4">
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">Buy PTE Mock Test AI</p>
              <p className="text-gray-600">
                Section will allow you to purchase your PTE mock test; all mock
                test will come with AI-scored technology, and you will receive
                your result instantly upon completion.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">My Tests</p>
              <p className="text-gray-600">
                Area will allow you to view all your purchased PTE mock tests.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">Test History & Analysis</p>
              <p className="text-gray-600">
                Area will allow you to view all of your completed PTE mock test
                results and weakness analysis.
              </p>
            </div>
          </div>
        </div>
      ),
      image: pteStudyZone,
      imageAlt: 'PTE Academic Test',
      buttonLabel: 'View PTE Mock Test Demo',
    },
    {
      subtitle: 'Last Minute Tips &',
      title: 'Tricks for the PTE Academic test of English',
      body: (
        <div className="space-y-4">
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">79, 65, and 50 bands templates</p>
              <p className="text-gray-600">
                Section will allow you to download all templates depending on
                your desired target. You will need to purchase VIP tokens from
                Buy Token to access this folder.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">Weekly Prediction</p>
              <p className="text-gray-600">
                Area will allow you to view & download weekly prediction PDFs
                along with other useful resources for your study. You will need
                to purchase.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <div>
              <p className="font-bold">Top Scorer Report</p>
              <p className="text-gray-600">
                Area will allow you to view some of our successful students’
                real scorecards along with their mock test results. This helps
                you understand how a certain student can pass with flying colors
                using the same techniques as you do.
              </p>
            </div>
          </div>
        </div>
      ),
      image: pteLastMinuteStudy,
      imageAlt: 'Last Minute Tips',
      buttonLabel: 'OK, Got It!',
    },
    {
      subtitle: '',
      title: 'Progress Tracker',
      body: (
        <div className="space-y-4">
          <div className="flex items-start">
            <TickIcon className="text-blue-500 mr-2 flex-shrink-0" />
            <p className="text-gray-600">
              This area will show you your level of consistency across all
              sections in PTE.
            </p>
          </div>
          <p className="text-gray-600">
            A <span className="font-bold">90/90</span> in 4 bands indicates that
            you have had adequate practice in all sections. This means you can
            start taking your first PTE Academic Mock Test to evaluate your
            performance.
          </p>
          <p className="text-gray-600">
            <span className="font-bold">Please note:</span> Having a 90/90 in
            this area does not mean you will get the same 90/90 in PTE Mock Test
            or Real Test as it takes skills and endurance to go through the
            whole 3-hour exam in 1 sitting.
          </p>
        </div>
      ),
      image: pteLastMinuteStudy,
      imageAlt: 'Progress Tracker',
      buttonLabel: 'View Demo',
    },
  ]

  return (
    <div className="w-full">
      {/* Banner Section */}
      <img
        src={pteToolBanner}
        alt="FAQs Banner"
        className="w-full h-auto object-cover"
      />

      <div className="w-full flex flex-col items-center bg-gray-50 lg:py-12 py-2">
        {sectionsData.map((section, index) => (
          <SwitchableSection
            key={index}
            subtitle={section.subtitle}
            title={section.title}
            image={section.image}
            imageAlt={section.imageAlt}
            maxWidth="80%"
            reverse={index % 2 !== 0}
          >
            {section.body}

            {/* Button aligned to the right */}
            <div className="flex justify-end mt-4 mb-[20px] lg:mb-0">
              <button
                className="text-bodyr text-white px-6 py-[10px] rounded-full flex items-center hover:scale-105"
                style={{
                  background:
                    'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
                }}
              >
                {section.buttonLabel}
                <ArrowRightIcon className="ml-2 flex-shrink-0" />
              </button>
            </div>
          </SwitchableSection>
        ))}
      </div>

      <SkillCTASection />
    </div>
  )
}

export default PreparationToolPage
