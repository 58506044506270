import { useState, useEffect } from 'react'
import { ReactComponent as WeeklyPredictionSaveAsSvg } from '../../images/WeeklyPrediction/weekly-prediction-save-as.svg'
import WeeklyPredictionEditSvg from '../../images/WeeklyPrediction/weekly-prediction-edit.svg'
import { ReactComponent as WeeklyPredictionMarkSvg } from '../../images/WeeklyPrediction/weekly-prediction-mark.svg'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import { updateQuestionState } from '../../services/practice.services'
import {
  toastError,
  toastSuccess,
  handleExceptionError,
} from '../../utils/utils'
import { labelOptions } from '../../constants'
import { useAuth } from '../../providers/AuthProvider'

interface ListeningQuestionStatesProps {
  question: PracticeMockQuestion | undefined
}

const ListeningQuestionStates: React.FC<ListeningQuestionStatesProps> = ({
  question,
}) => {
  const { userProfile } = useAuth()

  const [activePopup, setActivePopup] = useState(false)
  const [isEditingNote, setIsEditingNote] = useState(false)
  const [noteInput, setNoteInput] = useState<string>(
    question?.addons?.note || '',
  )
  const [isBookmark, setIsBookmark] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState<number | null>(null)

  useEffect(() => {
    if (question && question.addons) {
      setIsBookmark(question.addons.examCount === 1)
      setSelectedLabel(question.addons.label)
    }
  }, [question])

  const handleUpdateBookmark = async (
    practiceMockQuestion?: PracticeMockQuestion,
  ) => {
    if (practiceMockQuestion && userProfile && userProfile.userId) {
      try {
        const response = await updateQuestionState(
          userProfile.userId,
          practiceMockQuestion.id,
          2,
          practiceMockQuestion.category_id,
          '',
        )
        if (response.data.success) {
          toastSuccess('Update state successfully!')
          setIsBookmark(!isBookmark)
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
        toastError('Something went wrong!')
      }
    } else {
      console.error('Userprofile or question is missing!')
    }
  }

  const getSvgFillColorForLabel = () => {
    const matchedLabel = labelOptions.find(
      (label) => label.value === selectedLabel,
    )
    return matchedLabel ? matchedLabel.color : '#F0F0F0' // Use default color if not found
  }

  const handleLabelSelect = async (
    labelValue: number,
    practiceMockQuestion?: PracticeMockQuestion,
  ) => {
    if (practiceMockQuestion && userProfile && userProfile.userId) {
      try {
        const response = await updateQuestionState(
          userProfile.userId,
          practiceMockQuestion.id,
          labelValue,
          practiceMockQuestion.category_id,
          '',
        )
        if (response.data.success) {
          toastSuccess('Update state successfully!')
          const matchedLabel = labelOptions.find(
            (label) => label.value === labelValue,
          )
          setSelectedLabel(matchedLabel ? matchedLabel.type : null)
          setActivePopup(false)
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
        toastError('Something went wrong!')
      }
    } else {
      console.error('Userprofile or question is missing!')
    }
  }

  // Function to toggle edit note
  const toggleEditNote = (currentNote: string) => {
    setIsEditingNote(!isEditingNote)
    setNoteInput(currentNote || '') // Set the current note in the input
  }

  // Function to handle note input change
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoteInput(event.target.value)
  }

  const saveNote = async (practiceMockQuestion?: PracticeMockQuestion) => {
    if (practiceMockQuestion && userProfile && userProfile.userId) {
      try {
        const response = await updateQuestionState(
          userProfile.userId,
          practiceMockQuestion.id,
          1,
          practiceMockQuestion.category_id,
          noteInput,
        )
        if (response.data.success) {
          toastSuccess('Note updated successfully!')
          setIsEditingNote(false)
          setNoteInput(noteInput)

          // Update the question.addons.note with the new note value
          if (practiceMockQuestion.addons) {
            practiceMockQuestion.addons.note = noteInput
          }
        } else {
          toastError(response.data.message)
        }
      } catch (error) {
        handleExceptionError(error)
        toastError('Failed to update the note!')
      }
    } else {
      console.error('User profile or question is missing!')
    }
  }

  const cancelEditNote = () => {
    setIsEditingNote(false)
    setNoteInput('') // Reset the input field
  }

  return (
    <div className="flex flex-col pt-4">
      <div className="flex items-center">
        <WeeklyPredictionSaveAsSvg
          title="Bookmark"
          fill={isBookmark ? '#2C62FF' : 'none'}
          className="cursor-pointer hover:scale-105"
          onClick={() => handleUpdateBookmark(question)}
        />
        <img
          src={WeeklyPredictionEditSvg}
          alt="Add note"
          title="Add Note"
          className="ml-2 hover:scale-105 cursor-pointer"
          onClick={() => toggleEditNote(question?.addons?.note || '')}
        />
        <div className="relative">
          <WeeklyPredictionMarkSvg
            title="Label Type"
            fill={getSvgFillColorForLabel()}
            className="cursor-pointer ml-2 hover:scale-105"
            onClick={() => setActivePopup(!activePopup)}
          />
          {activePopup && (
            <div
              className="absolute bottom-full mt-2 right-0 bg-white shadow-lg rounded-lg p-4 z-50 w-48"
              style={{ minWidth: '12rem' }}
              onMouseEnter={() => setActivePopup(true)} // Keep the popup open when hovered
              onMouseLeave={() => setActivePopup(false)} // Close the popup when leaving
            >
              <p className="font-medium text-gray-800">Select Label:</p>
              <ul>
                {labelOptions.map((labelOption) => (
                  <li
                    key={labelOption.id}
                    style={{ color: labelOption.color }}
                    className="py-1 text-bodyr cursor-pointer hover:bg-gray-100"
                    onClick={() =>
                      handleLabelSelect(labelOption.value, question)
                    }
                  >
                    {labelOption.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <p className="text-bodym text-neutrals-1 mt-4">
        My note:{' '}
        {isEditingNote ? (
          <div className="flex items-center gap-2 mt-2">
            <input
              type="text"
              maxLength={30}
              value={noteInput}
              onChange={handleNoteChange}
              className="text-bodyr border p-1 rounded-md flex-grow"
              placeholder="Type your note here..."
            />
            <button
              className={`text-white px-2 py-1 rounded ${
                noteInput.trim()
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
              onClick={() => saveNote(question)}
            >
              ✓
            </button>
            <button
              className="text-white bg-red-600 px-2 py-1 rounded hover:bg-red-700"
              onClick={cancelEditNote}
            >
              ✖
            </button>
          </div>
        ) : (
          <span className="text-bodyr">{noteInput}</span>
        )}
      </p>
    </div>
  )
}

export default ListeningQuestionStates
