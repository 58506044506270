import React from 'react'
import MockQuestion from '../models/mockQuestion.model'
import { labelOptions } from '../constants'

interface SelectLabelPopupProps {
  index: number
  activePopup: number | null
  setActivePopup: (index: number | null) => void
  handleLabelSelect: (question: MockQuestion, type: number) => void
  question: MockQuestion
}

const SelectLabelPopup: React.FC<SelectLabelPopupProps> = ({
  index,
  activePopup,
  setActivePopup,
  handleLabelSelect,
  question,
}) => {
  // Return null explicitly when activePopup !== index
  if (activePopup !== index) {
    return null
  }

  return (
    <div
      className="absolute bottom-full mt-2 right-0 bg-white shadow-lg rounded-lg p-3 z-50 w-48"
      style={{ minWidth: '12rem' }}
      onMouseEnter={() => setActivePopup(index)} // Keep the popup open when hovered
      onMouseLeave={() => setActivePopup(null)} // Close the popup when leaving
    >
      <p className="font-medium text-gray-800 py-1 px-2">Select Label:</p>
      <ul className="list-none m-0 p-0">
        {labelOptions.map((labelOption) => (
          <li
            key={labelOption.id}
            style={{
              color: labelOption.color,
            }}
            className="py-1 px-2 text-bodyr cursor-pointer hover:bg-gray-100 hover:rounded-md hover:px-2 transition-all duration-200"
            onClick={(e) => {
              e.stopPropagation()
              handleLabelSelect(question, labelOption.value)
            }}
          >
            {labelOption.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SelectLabelPopup
