import { useState, useCallback } from 'react'
import MockQuestion from '../models/mockQuestion.model'
import { updateQuestionState } from '../services/practice.services'
import { handleExceptionError, toastError, toastSuccess } from '../utils/utils'
import { labelOptions } from '../constants'

const useQuestionState = (userProfile: any) => {
  const [mockQuestions, setMockQuestions] = useState<MockQuestion[]>([])
  const [editingNoteId, setEditingNoteId] = useState<number | null>(null) // Track editing question note
  const [notes, setNotes] = useState<{ [key: number]: string }>({}) // Track temporary notes

  // Handle label selection and update state
  const handleLabelSelect = useCallback(
    async (questionInfo: MockQuestion, labelType: number) => {
      if (userProfile && userProfile.userId) {
        try {
          const response = await updateQuestionState(
            userProfile.userId,
            questionInfo.id,
            labelType,
            questionInfo.category_id,
            '',
          )

          if (response.data.success) {
            toastSuccess('Label updated successfully!')
            setMockQuestions(
              (prevQuestions) =>
                prevQuestions.map((q) =>
                  q.id === questionInfo.id
                    ? { ...q, addons: { ...q.addons, label: labelType } }
                    : q,
                ) as MockQuestion[],
            )
          } else {
            toastError(response.data.message)
          }
        } catch (error) {
          handleExceptionError(error)
          toastError('Something went wrong!')
        }
      }
    },
    [userProfile],
  )

  // Handle bookmark toggle and update state
  const handleUpdateBookmark = useCallback(
    async (questionInfo: MockQuestion) => {
      if (userProfile && userProfile.userId) {
        try {
          const response = await updateQuestionState(
            userProfile.userId,
            questionInfo.id,
            2,
            questionInfo.category_id,
            '',
          )

          if (response.data.success) {
            toastSuccess('Bookmark updated successfully!')
            setMockQuestions(
              (prevQuestions) =>
                prevQuestions.map((q) =>
                  q.id === questionInfo.id
                    ? {
                        ...q,
                        addons: {
                          ...q.addons,
                          examCount: q.addons?.examCount === 1 ? 0 : 1,
                        },
                      }
                    : q,
                ) as MockQuestion[],
            )
          } else {
            toastError(response.data.message)
          }
        } catch (error) {
          handleExceptionError(error)
          toastError('Something went wrong!')
        }
      }
    },
    [userProfile],
  )

  // Get SVG fill color for label
  const getSvgFillColorForLabel = useCallback((mockQuestion: MockQuestion) => {
    if (mockQuestion.addons) {
      const matchedLabel = labelOptions.find(
        (label) => label.value === mockQuestion.addons?.label,
      )
      return matchedLabel ? matchedLabel.color : '#F0F0F0' // Default color if not found
    }
    return '#F0F0F0'
  }, [])

  // Get SVG fill color for bookmark
  const getSvgFillColorForBookmark = useCallback(
    (mockQuestion: MockQuestion) => {
      if (mockQuestion.addons && mockQuestion.addons.examCount) {
        return mockQuestion.addons.examCount === 1 ? '#2C62FF' : 'none'
      }
      return 'none'
    },
    [],
  )

  // Toggle note editing mode
  const toggleEditNote = useCallback((questionId: number) => {
    setEditingNoteId((prevId) => (prevId === questionId ? null : questionId))
  }, [])

  // Handle note input change
  const handleNoteChange = useCallback(
    (questionId: number, newNote: string) => {
      setNotes((prevNotes) => ({
        ...prevNotes,
        [questionId]: newNote,
      }))
    },
    [],
  )

  // Save note to the server
  const saveNote = useCallback(
    async (questionInfo: MockQuestion) => {
      if (userProfile && userProfile.userId) {
        try {
          const response = await updateQuestionState(
            userProfile.userId,
            questionInfo.id,
            1,
            questionInfo.category_id,
            notes[questionInfo.id]?.trim(),
          )

          if (response.data.success) {
            toastSuccess('Note updated successfully!')
            setEditingNoteId(null) // Close input
          } else {
            toastError(response.data.message)
          }
        } catch (error) {
          handleExceptionError(error)
          toastError('Failed to update the note!')
        }
      }
    },
    [userProfile, notes],
  )

  return {
    mockQuestions,
    setMockQuestions,
    notes,
    setNotes,
    editingNoteId,
    setEditingNoteId,
    handleLabelSelect,
    handleUpdateBookmark,
    getSvgFillColorForLabel,
    getSvgFillColorForBookmark,
    toggleEditNote,
    handleNoteChange,
    saveNote,
  }
}

export default useQuestionState
