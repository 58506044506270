import React, { useState, useEffect, useRef } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useLocation } from 'react-router-dom'
import WeeklyPredictionSubmitIcon from '../../images/WeeklyPrediction/weekly-prediction-submit.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  getAllMockQuestions,
  updateAttempt,
} from '../../services/practice.services'
import {
  handleExceptionError,
  navigateUserToPlanPage,
  toastError,
} from '../../utils/utils'
import ArrayUtils from '../../utils/ArrayUtils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import Option from '../../models/option.model'
import MockQuestion from '../../models/mockQuestion.model'
import ReadingQuestionStates from './ReadingQuestionStates'
import Path from '../../routes/Path'
import { Link, useNavigate } from 'react-router-dom'
import { getUserTokenDetails } from '../../services/user.services'
import { QuestionModelId } from '../../models/QuestionModels'

interface Column {
  name: string
  items: Option[]
}

interface Columns {
  [key: string]: Column
}

const initialColumns: Columns = {
  leftPanel: {
    name: 'Source Panel',
    items: [],
  },
  rightPanel: {
    name: 'Target Panel',
    items: [],
  },
}

const onDragEnd = (
  result: DropResult,
  columns: Columns,
  setColumns: React.Dispatch<React.SetStateAction<Columns>>,
) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
  }
}

const ReorderParagraphQuestion = () => {
  const navigate = useNavigate()

  const [questionId, setQuestionId] = useState<string | null>(
    localStorage.getItem('questionIdPTE'),
  )
  const [columns, setColumns] = useState(initialColumns)
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [options, setOptions] = useState<Option[]>([])
  const [errorMsg, setErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  const { userProfile } = useAuth()
  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)

  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()

    const fetchData = async () => {
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.R_ReorderParagraphs
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setOptions(response.data.option)

              // Map the options to the format expected by `columns.leftPanel.items`
              let leftPanelItems: Option[] = response.data.option.map(
                (option: Option) => ({
                  id: option.id,
                  content: option.options,
                  altOption: option.altOption,
                  correct: option.correct,
                  options: option.options,
                }),
              )

              leftPanelItems = ArrayUtils.shuffleArray(leftPanelItems)

              // Update columns with the new leftPanel items
              setColumns((prevColumns) => ({
                ...prevColumns,
                leftPanel: {
                  ...prevColumns.leftPanel,
                  items: leftPanelItems,
                },
              }))

              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }

              await updateAttempt(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }

    fetchData()
  }, [userProfile, questionId, navigate])

  const resetState = () => {
    // Shuffle the options and move them back to the left panel
    const shuffledItems = ArrayUtils.shuffleArray([
      ...columns.leftPanel.items,
      ...columns.rightPanel.items,
    ])

    setColumns({
      ...columns,
      leftPanel: {
        ...columns.leftPanel,
        items: shuffledItems,
      },
      rightPanel: {
        ...columns.rightPanel,
        items: [],
      },
    })

    setIsSubmitted(false)

    if (questionSectionRef.current) {
      questionSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    setQuestionId(selectedQuestionId)
    resetState()
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      setQuestionId(String(previousQuestionId))
      resetState()
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      setQuestionId(String(nextQuestionId))
      resetState()
    }
  }

  useEffect(() => {
    if (isSubmitted && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [isSubmitted])

  return (
    <div className="min-h-[80vh]">
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="px-6 py-10" ref={questionSectionRef}>
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link
              to={
                testType === 'practice'
                  ? Path.practice.path
                  : Path.weeklyPrediction.path
              }
            >
              Practice
            </Link>{' '}
            /{' '}
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              Reading Section
            </Link>{' '}
            / <span className="text-neutrals-1">Re-order Paragraph</span>
          </p>
          <div className="w-fit">
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
                <ReturnArrowSvg fill="white" className="mr-2" />
                <span className="text-bodyr text-white">Return</span>
              </button>
            </Link>
          </div>
          <p className="text-bodym text-danger mt-2 md:mt-8">
            *This question carries marks for Reading only (~12%)
          </p>

          <div className="p-8 rounded-xl shadow mt-8">
            <p className="text-h4m text-neutrals-1">{question?.short_title}</p>

            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              <div className="pt-8 mx-auto">
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(columns).map(([columnId, column], index) => (
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="col-span-1 bg-gray-200 p-4 rounded-lg"
                          style={{
                            height: '650px',
                            overflowY: 'auto',
                          }}
                        >
                          <h2 className="text-h5m">{column.name}</h2>
                          {column.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={String(item.id)}
                              index={index}
                              isDragDisabled={isSubmitted}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`p-4 my-2 bg-white rounded shadow ${
                                    snapshot.isDragging
                                      ? 'bg-blue-100'
                                      : 'bg-white'
                                  }`}
                                >
                                  {item.options}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              </div>

              <div className="flex place-content-center mt-8">
                <button
                  className={`flex text-bodyr text-white items-center py-2 px-6 rounded-lg mr-2 ${columns.rightPanel.items.length !== options.length || isSubmitted ? 'bg-gray-400 cursor-not-allowed' : 'bg-success'}`}
                  disabled={
                    columns.rightPanel.items.length !== options.length ||
                    isSubmitted
                  }
                  onClick={() => setIsSubmitted(true)}
                >
                  <img
                    src={WeeklyPredictionSubmitIcon}
                    alt="submit"
                    className="mr-2"
                  />
                  Submit
                </button>
              </div>

              <ReadingQuestionStates question={question} />
            </DragDropContext>
          </div>

          <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              disabled={isFirstQuestion()}
              onClick={displayPreviousQuestion}
            >
              <PreviousArrowSvg
                fill={isFirstQuestion() ? 'gray' : 'white'}
                className="md:mr-2"
              />
              <p className="hidden md:block text-bodyr">Previous</p>
            </button>
            <div className="flex text-white">
              <button
                className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                onClick={resetState}
              >
                <p className="hidden md:block text-bodyr text-white">
                  Try Again
                </p>
                <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
              </button>
            </div>

            <div>
              <select
                className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                defaultValue={question?.id}
                onChange={(e) => handleQuestionChange(e.target.value)}
              >
                {allMockQuestions.map((mockQuestion, index) => (
                  <option
                    key={mockQuestion.id}
                    value={mockQuestion.id}
                    className="text-bodyr"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
              onClick={displayNextQuestion}
              disabled={isLastQuestion()}
            >
              <p className="hidden md:block text-bodyr">Next</p>
              <NextArrowSvg
                fill={isLastQuestion() ? 'gray' : 'white'}
                className="md:ml-2"
              />
            </button>
          </div>
          {isSubmitted && (
            <div
              ref={answerSectionRef}
              className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4"
            >
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Original Answer</p>
                <div className="flex flex-col gap-4 mt-[1rem]">
                  {options.map((option: Option, index) => (
                    <div
                      key={index}
                      className="p-2 bg-white text-green-700 border-2 border-green-700 rounded-lg"
                    >
                      {option.options}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                <p className="text-h4m text-center">Your Answer</p>
                <div className="flex flex-col gap-4 mt-[1rem]">
                  {columns.rightPanel.items.map((option: Option, index) => (
                    <div
                      key={index}
                      className={`p-2 bg-white rounded-lg ${option.correct === index + 1 ? 'text-green-700 border-2 border-green-700' : 'text-red-600 border-2 border-red-600'}`}
                    >
                      {option.options}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ReorderParagraphQuestion
