import { Link, useNavigate } from 'react-router-dom'
import TestHistorySectionalSection from '../../components/SectionalTest/TestHistory/TestHistorySectionalSection'
import { useAuth } from '../../providers/AuthProvider'
import { useEffect } from 'react'
import { getUserTokenDetails } from '../../services/user.services'
import { navigateUserToPlanPage } from '../../utils/utils'
import Path from '../../routes/Path'

const TestHistorySectionalPage = () => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const checkUserPlan = async () => {
      if (userProfile) {
        const userInfo = await getUserTokenDetails(userProfile.userId)
        if (navigateUserToPlanPage(userInfo.data)) {
          navigate(Path.planPages.main.path)
        }
      }
    }
    checkUserPlan()
  }, [userProfile, navigate])

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Sectional Test / Test History &
          Analysis
        </p>
      </div>
      <div className="w-full min-h-[80vh] lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <TestHistorySectionalSection />
      </div>
    </>
  )
}

export default TestHistorySectionalPage
