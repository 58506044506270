import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import ReadAloudArrowSvg from '../../images/Speaking/read-aloud-arrow.svg'
import { ReactComponent as PreviousArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-previous-arrow.svg'
import { ReactComponent as NextArrowSvg } from '../../images/WeeklyPrediction/weekly-prediction-next-arrow.svg'
import TryAgainArrowSvg from '../../images/Speaking/try-again-arrow.svg'
import { ReactComponent as ReturnArrowSvg } from '../../images/WeeklyPrediction/weekly-preidction-return.svg'
import WeeklyPredictionKeywordIcon from '../../images/WeeklyPrediction/weekly-prediction-keyword.svg'
import ProgressBar from '../ProgressBar'
import LoadingMessage from '../Items/LoadingMessage'
import ErrorMessage from '../Items/ErrorMessage'
import { useAuth } from '../../providers/AuthProvider'
import {
  practiceNowWithFilter,
  updateAttempt,
  deductCreditUser,
  getAllMockQuestions,
  storePracticeData,
} from '../../services/practice.services'
import { handleExceptionError, toastError } from '../../utils/utils'
import PracticeMockQuestion from '../../models/practiceMockQuestion.model'
import SpeakingQuestionStates from './SpeakingQuestionStates'
import MockQuestion from '../../models/mockQuestion.model'
import { useNavigate } from 'react-router-dom'
import SpeakingFinalResult from './SpeakingFinalResult'
import Path from '../../routes/Path'
import { Link } from 'react-router-dom'
import { QuestionModelId } from '../../models/QuestionModels'

type RepeatSentencesQuestionProps = {
  setShowScoreBoard: (value: boolean) => void
  showScoreBoard: boolean
}

const RepeatSentenceQuestion: React.FC<RepeatSentencesQuestionProps> = ({
  setShowScoreBoard,
  showScoreBoard,
}) => {
  const { userProfile } = useAuth()
  const navigate = useNavigate()
  const maxRecordingTime = 10
  const recordingTimeout = 5
  const postAudioDelay = 3

  const [initialDelay, setInitialDelay] = useState<null | number>(
    recordingTimeout,
  )
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime)
  const [recordingProgress, setRecordingProgress] = useState(0)
  const audioRef = useRef<HTMLAudioElement>(null)
  const recorderRef = useRef<MediaRecorder | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState('')
  const [question, setQuestion] = useState<PracticeMockQuestion | undefined>(
    undefined,
  )
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [transcript, setTranscript] = useState<string>('')
  const [audioPlayError, setAudioPlayError] = useState(false)
  const [allMockQuestions, setAllMockQuestions] = useState<MockQuestion[]>([])
  //Final scores
  const [finalPronunicationScore, setFinalPronunicationScore] = useState(10)
  const [finalFluencyScore, setFinalFluencyScore] = useState(10)
  const [finalContentPercentage, setFinalContentPercentage] = useState(0)
  const [finalSpeakingScore, setFinalSpeakingScore] = useState(10)
  //Highligted transcripted audio
  const [highlightedPagagraph, setHighlightedPagagraph] = useState('')
  //Conditions for unlocking the final answers
  const [totalCreditTime, setTotalCreditTime] = useState<number>(0)
  const [totalCredits, setTotalCredits] = useState<number>(0)
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [isLoadingSpeakingResult, setIsLoadingSpeakingResult] = useState(true)
  const [postAudioCountdown, setPostAudioCountdown] = useState<null | number>(
    null,
  )
  const [showAnswerPopup, setShowAnswerPopup] = useState(false)

  const myFluency = useRef(5) // Initialize fluency, you can adjust it based on your needs
  const t0 = useRef(0)
  const t1 = useRef(0)
  const pauseThresold = useRef(0)

  const answerSectionRef = useRef<HTMLDivElement>(null)
  const questionSectionRef = useRef<HTMLDivElement>(null)
  const audioBlobRef = useRef<Blob | null>(null)
  const finalTranscriptTextRef = useRef('')

  const location = useLocation()
  let testType: string = ''
  if (location.pathname.includes('/practice')) {
    testType = 'practice'
  }

  useEffect(() => {
    const fetchData = async () => {
      const questionId = localStorage.getItem('questionIdPTE')
      const questionType = localStorage.getItem('questionTypePTE')
      const questionLabel = localStorage.getItem('ptePracticeQuestionLabel')

      if (
        userProfile &&
        userProfile.userId &&
        !isNaN(Number(questionId)) &&
        !isNaN(Number(questionType)) &&
        !isNaN(Number(questionLabel)) &&
        questionType === QuestionModelId.S_RepeatSentence
      ) {
        try {
          const response = await practiceNowWithFilter(
            userProfile.userId,
            Number(questionType),
            Number(questionId),
            0,
            0,
            0,
            Number(questionLabel),
            4,
          )
          if (response.data.success) {
            const mockQuestions = response.data.mockQuestion
            if (Array.isArray(mockQuestions) && mockQuestions.length > 0) {
              setQuestion(mockQuestions[0])
              setTotalCreditTime(response.data.totalCreditTime)
              setTotalCredits(response.data.totalCredits)
              const allMockQuestionsData = await getAllMockQuestions(
                userProfile.userId,
                Number(questionType),
                Number(questionId),
                0,
                0,
                0,
                Number(questionLabel),
                4,
              )
              if (allMockQuestionsData.data.success) {
                setAllMockQuestions(allMockQuestionsData.data.allMockQuestion)
              } else {
                console.error('Failed to fetch all mock questions.')
              }
            } else {
              console.error('No questions found in the response')
              setErrorMsg('No valid question data found in the response!')
            }

            await updateAttempt(
              userProfile.userId,
              Number(questionType),
              Number(questionId),
              0,
              0,
              0,
              Number(questionLabel),
              4,
            )
          }
        } catch (error) {
          handleExceptionError(error)
          setErrorMsg('Error while getting the question data!!!')
        }
      } else {
        setErrorMsg('Error while getting the question data!!!')
      }
      setIsLoading(false)
    }

    fetchData()
  }, [userProfile])

  //Implement speakable words
  const speakableClickHandler = useCallback((event: any) => {
    if (event.target.classList.contains('speakable')) {
      const word = event.target.getAttribute('data-word')
      const utterance = new SpeechSynthesisUtterance(word)
      speechSynthesis.speak(utterance)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', speakableClickHandler)

    return () => {
      document.removeEventListener('click', speakableClickHandler)
    }
  }, [speakableClickHandler])

  // Use useMemo to initialize SpeechRecognition
  const recognition = useMemo(() => {
    const rec = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)()
    rec.continuous = true // Keep recognition active
    rec.interimResults = true

    rec.onresult = (event: any) => {
      let interimTranscript = ''
      let finalTranscript = ''

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const result = event.results[i]
        if (result.isFinal) {
          finalTranscript += result[0].transcript // Final result
        } else {
          interimTranscript += result[0].transcript // Partial result
        }
      }

      // Combine final and interim transcripts
      const fullTranscript =
        finalTranscriptTextRef.current + finalTranscript + interimTranscript
      finalTranscriptTextRef.current += finalTranscript // Append only final results to the reference
      setTranscript(fullTranscript) // Update state with full transcript
      // console.log('Full Transcript:', fullTranscript) // Log full transcript
      // console.log('Interim Transcript:', interimTranscript) // Log partial result
      const now = performance.now()
      if (t0.current === 0) {
        // First recognized speech, set the initial timestamp
        t0.current = now
        t1.current = now
      } else {
        // Calculate the pause duration
        const pauseDuration = now - t1.current
        // Apply fluency calculation based on the pause duration
        if (pauseDuration >= 5000) {
          myFluency.current = 0 // Set fluency to 0 for long pauses
          pauseThresold.current = 1
        } else {
          const fluencyReduction =
            pauseDuration >= 750
              ? 1
              : pauseDuration >= 691
                ? 0.5
                : pauseDuration >= 651
                  ? 0.4
                  : pauseDuration >= 591
                    ? 0.3
                    : pauseDuration >= 551
                      ? 0.2
                      : pauseDuration >= 450
                        ? 0.1
                        : 0

          myFluency.current = Math.max(0, myFluency.current - fluencyReduction)
        }

        // Update the timestamp for the next pause
        t1.current = now
      }
    }

    rec.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error)
    }
    return rec
  }, [])

  const startRecording = useCallback(() => {
    if (!isRecording && !audioBlob) {
      setIsRecording(true)
      setRecordingTime(10)
      setRecordingProgress(0)
      setTranscript('')

      const beep = new Audio('/sound-beep.mp3')
      beep.play().catch((error) => {
        console.log('Beep sound play error:', error)
      })

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const newRecorder = new MediaRecorder(stream)
          recorderRef.current = newRecorder
          const chunks: BlobPart[] = []

          newRecorder.ondataavailable = (event) => chunks.push(event.data)
          newRecorder.onstop = () => {
            setAudioBlob(new Blob(chunks, { type: 'audio/wav' }))
            audioBlobRef.current = new Blob(chunks, { type: 'audio/wav' })
            setRecordingProgress(100)
          }
          newRecorder.start()

          if (recognition) {
            recognition.start()
          }
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error)
        })
    }
  }, [isRecording, audioBlob, recognition])

  // Start post-audio countdown
  useEffect(() => {
    if (!initialDelay && audioRef.current) {
      audioRef.current.addEventListener('ended', () => {
        setPostAudioCountdown(postAudioDelay)
      })
    }
  }, [initialDelay])

  // Handle post-audio countdown logic
  useEffect(() => {
    let countdownId: NodeJS.Timeout
    if (postAudioCountdown && postAudioCountdown > 0) {
      countdownId = setTimeout(
        () => setPostAudioCountdown(postAudioCountdown - 1),
        1000,
      )
    } else if (postAudioCountdown === 0) {
      setPostAudioCountdown(null)
      startRecording()
    }
    return () => clearTimeout(countdownId)
  }, [postAudioCountdown, startRecording])

  const calculateFinalAnswer = useCallback(async () => {
    if (!question || !userProfile) return

    let finalCount = 0
    let finalPronoun = 0
    let finalFluency = 0
    let finalAll = 0
    let text3Array: Array<string> = []
    const regex = /[^a-zA-Z0-9 ]/g

    let text1 = ''
    text1 = question?.description.replace('/[^a-zA-Z0-9_ -]/s', '') || ''
    text1 = text1.toLowerCase()
    text1 = text1.replace(regex, '')

    let text3 = ''
    text3 = question?.description.replace('/[^a-zA-Z0-9_ -]/s', '') || ''
    text3 = text3.toLowerCase()
    text3 = text3.replace(regex, '')

    let text2 = ''
    text2 = finalTranscriptTextRef.current.toLowerCase()
    text2 = text2.replace(regex, '')

    let count = 0
    let pronoun = 0

    const text1Array: Array<string> = text1.split(' ')
    const text2Array: Array<string> = text2.split(' ')
    text3Array = text3.split(' ')

    for (let i = 0; i < text1Array.length; i++) {
      for (let j = 0; j < text2Array.length; j++) {
        text3Array[i] =
          '<span class="text-red-600 bg-red-200 cursor-pointer speakable" data-word="' +
          text1Array[i] +
          '">' +
          text1Array[i] +
          '</span>'
        if (text1Array[i].trim() === text2Array[j].trim()) {
          count = count + 1
          text3Array[i] =
            '<span class="text-green-600 bg-green-200 cursor-pointer speakable" data-word="' +
            text1Array[i] +
            '">' +
            text1Array[i] +
            '</span>'
          break
        }
      }
    }

    for (let i = 0; i < text2Array.length; i++) {
      for (let j = 0; j < text1Array.length; j++) {
        if (text1Array[j].trim() === text2Array[i].trim()) {
          pronoun = pronoun + 1
          break
        }
      }
    }

    const finalCountPercentage = (count * 100) / text1Array.length

    if (finalCountPercentage >= 25 && finalCountPercentage <= 49) {
      finalCount = 25
    } else if (finalCountPercentage >= 50 && finalCountPercentage < 100) {
      finalCount = 60
    } else if (finalCountPercentage === 100) {
      finalCount = 90
    } else {
      finalCount = 0
    }

    finalCount = Math.max(finalCount, 10)
    finalPronoun = Math.max((pronoun * 90) / text2Array.length, 10)
    finalFluency = Math.max((myFluency.current * 90) / 5, 10)

    if (finalCount < 25) {
      finalCount = 10
      finalPronoun = 10
      finalFluency = 10
      finalAll = 10
    }

    const finalallv = finalFluency + finalPronoun + finalCount
    if (finalallv > 0) {
      finalAll = finalallv / 3
      if (finalAll <= 80) {
        finalAll = finalallv / 3 + 10
      } else {
        finalAll = finalallv / 3 + (90 - finalAll)
      }
    } else {
      finalAll = 10
    }

    if (finalCount < 25) {
      finalCount = 10
      finalPronoun = 10
      finalFluency = 10
      finalAll = 10
    }

    setHighlightedPagagraph(text3Array.join(' '))
    setFinalContentPercentage(finalCountPercentage)
    setFinalPronunicationScore(finalPronoun)
    setFinalFluencyScore(finalFluency)
    setFinalSpeakingScore(finalAll)

    if (finalAll > 79) {
      try {
        if (userProfile && question) {
          const filename =
            question.id +
            '_' +
            Math.round(finalAll) +
            '_' +
            Math.random().toString(36).substring(7) +
            '.wav'
          const audioFile = audioBlobRef.current
            ? new File([audioBlobRef.current], filename, { type: 'audio/wav' })
            : undefined
          const response = await storePracticeData(
            question.id,
            0,
            filename,
            finalFluency,
            finalPronoun,
            finalCount,
            0,
            0,
            0,
            0,
            finalAll,
            text3Array.join(' '),
            userProfile.userId,
            audioFile,
          )
          if (!response.data.success) {
            console.error('Failed to store the data!')
          }
        }
      } catch (error) {
        handleExceptionError(error)
      }
    }
  }, [question, userProfile])

  // Function to handle the submission of the answer
  const handleSubmitAnswer = useCallback(async () => {
    // Check if user has sufficient credits and is eligible
    if (totalCreditTime <= 0 && totalCredits <= 0) {
      toastError('Please purchase more tokens to enable automated scoring!!!')
      return
    }

    // Ensure userProfile and question are valid
    if (!userProfile?.userId || !question?.category_id) {
      console.error('User profile or question data is missing!')
      return
    }

    try {
      // Deduct credit from the user
      const response = await deductCreditUser(
        userProfile.userId,
        question.category_id,
      )
      if (!response.data.success) {
        console.error(`Credit deduction failed: ${response.data.message}`)
      }

      // Enable scoring and calculate the answer
      setIsEnabled(true)

      setTimeout(() => {
        setIsLoadingSpeakingResult(false)
        calculateFinalAnswer()
      }, 2000)
    } catch (error) {
      // Handle exception errors
      handleExceptionError(error)
    }
  }, [
    calculateFinalAnswer,
    question,
    totalCreditTime,
    totalCredits,
    userProfile,
  ])

  const stopRecording = useCallback(() => {
    if (!isRecording || !recorderRef.current) return // Ensure it's only stopped once

    recorderRef.current.stop() // Stop the audio recording
    recorderRef.current = null // Clear the recorder reference

    setIsRecording(false)
    setRecordingTime(10)
    setRecordingProgress(100)
    setIsSubmitted(true)

    // Stop speech recognition
    if (recognition) {
      recognition.stop()
    }

    handleSubmitAnswer()
  }, [isRecording, recognition, handleSubmitAnswer])

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isRecording) {
      intervalId = setInterval(() => {
        setRecordingTime((prevTimer) => {
          let newTime = prevTimer - 1
          setRecordingProgress(((10 - newTime) / 10) * 100)
          if (newTime > 0) {
            return newTime
          } else {
            clearInterval(intervalId)
            stopRecording()
            return 0
          }
        })
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, [isRecording, stopRecording])

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (question) {
      if (initialDelay && initialDelay > 0) {
        timerId = setTimeout(() => setInitialDelay(initialDelay - 1), 1000)
      } else if (initialDelay === 0) {
        audioRef.current?.play().catch(() => {
          setAudioPlayError(true) // Set error state if play fails
        })
        setInitialDelay(null)
      }
      return () => clearTimeout(timerId)
    }
  }, [initialDelay, question])

  const resetState = () => {
    setIsSubmitted(false)
    setIsLoadingSpeakingResult(true)
    setTranscript('')
    setInitialDelay(recordingTimeout)
    setIsRecording(false)
    setAudioBlob(null)
    setRecordingTime(maxRecordingTime)
    setRecordingProgress(0)
    setErrorMsg('')
    setFinalPronunicationScore(10)
    setFinalFluencyScore(10)
    setFinalContentPercentage(0)
    setFinalSpeakingScore(10)
    setHighlightedPagagraph('')
    setPostAudioCountdown(null)
    finalTranscriptTextRef.current = ''
    myFluency.current = 5
    t0.current = 0
    t1.current = 0
    pauseThresold.current = 0

    audioRef.current && (audioRef.current.src = question?.audio || '')
  }

  const handlePlayAudio = () => {
    audioRef.current
      ?.play()
      .then(() => {
        setAudioPlayError(false) // Reset error state if audio plays successfully
      })
      .catch(() => {
        setAudioPlayError(true) // Set error state if play fails
      })
  }

  const handleQuestionChange = (selectedQuestionId: string) => {
    localStorage.setItem('questionIdPTE', selectedQuestionId)
    navigate(0)
  }

  const isFirstQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (allMockQuestions.length === 0 || position === -1 || position !== 0) {
      return false
    } else {
      return true
    }
  }

  const isLastQuestion = (): boolean => {
    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )
    if (
      allMockQuestions.length === 0 ||
      position === -1 ||
      position + 1 !== allMockQuestions.length
    ) {
      return false
    } else {
      return true
    }
  }

  const displayPreviousQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position === 0) {
      toastError('This question is the first question!')
    } else {
      const previousQuestionId = allMockQuestions[position - 1].id
      localStorage.setItem('questionIdPTE', String(previousQuestionId))
      navigate(0)
    }
  }

  const displayNextQuestion = () => {
    if (allMockQuestions.length === 0) {
      toastError('No questions in the list!')
    }

    const position = allMockQuestions.findIndex(
      (mockQuestion) => mockQuestion.id === question?.id,
    )

    if (position === -1) {
      toastError('Can not find the current question in the list!')
    } else if (position + 1 === allMockQuestions.length) {
      toastError('This question is the last question!')
    } else {
      const nextQuestionId = allMockQuestions[position + 1].id
      localStorage.setItem('questionIdPTE', String(nextQuestionId))
      navigate(0)
    }
  }

  const createMarkup = (html: string) => {
    return { __html: html }
  }

  useEffect(() => {
    if (isSubmitted && isEnabled && answerSectionRef.current) {
      answerSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [isEnabled, isSubmitted])

  const speakingFinalMarks = [
    {
      name: 'Pronunciation',
      score: `${Math.round(finalPronunicationScore)}/90`,
    },
    {
      name: 'Fluency',
      score: `${Math.round(finalFluencyScore)}/90`,
    },
    {
      name: 'Content',
      score: `${Math.round(finalContentPercentage)}%`,
    },
    {
      name: 'Final',
      score: `${Math.round(finalSpeakingScore)}`,
    },
  ]

  return (
    <div>
      {isLoading ? (
        <LoadingMessage message="Loading question..." />
      ) : errorMsg ? (
        <ErrorMessage message={errorMsg} />
      ) : (
        <div className="px-6 py-10" >
          <p className="text-bodyr my-2 text-neutrals-2">
            <Link
              to={
                testType === 'practice'
                  ? Path.practice.path
                  : Path.weeklyPrediction.path
              }
            >
              Practice
            </Link>{' '}
            /{' '}
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              Speaking Section
            </Link>{' '}
            / <span className="text-neutrals-1">Repeat Sentence</span>
          </p>
          <div className="w-fit">
            <Link
              to={
                testType === 'practice'
                  ? Path.practiceCourse.path
                  : Path.weeklyPredictionCourse.path
              }
            >
              <button className="py-2 px-4 flex items-center gap-2 my-6 bg-info rounded-lg">
                <ReturnArrowSvg fill="white" className="mr-2" />
                <span className="text-bodyr text-white">Return</span>
              </button>
            </Link>
          </div>
          <div ref={questionSectionRef}>
            <p className="text-bodym text-danger mt-2 md:mt-8">
              *This question carries marks for Speaking (~35%) and Listening (
              ~20%)
            </p>

            <div className="p-8 rounded-xl shadow mt-8">
              <p className="text-h4m text-neutrals-1">
                {question?.short_title}
              </p>
              {!isRecording && !audioBlob && (
                <div className="bg-[#F1EFF2] text-center p-4 rounded-xl lg:w-[50%] w-full mx-auto mt-4">
                  {initialDelay ? (
                    <p className="text-h4m text-neutrals-1">
                      Audio will start in: {initialDelay} seconds
                    </p>
                  ) : postAudioCountdown ? (
                    <p className="text-h4m text-neutrals-1">
                      Recording will start in: {postAudioCountdown} seconds
                    </p>
                  ) : audioPlayError ? (
                    <p className="text-red-600 text-footnote w-full text-center">
                      Oops! It seems the audio didn't start automatically. No
                      worries—just click{' '}
                      <button
                        onClick={handlePlayAudio}
                        className="underline text-blue-600 bg-yellow-200 px-2 rounded-lg"
                      >
                        Here
                      </button>{' '}
                      to play it manually!
                    </p>
                  ) : (
                    <>
                      <p className="text-h4m text-neutrals-1">Original Audio</p>
                    </>
                  )}
                  {!postAudioCountdown && ( // Hide audio during post-audio countdown
                    <audio
                      ref={audioRef}
                      src={question && question.audio ? question.audio : ''}
                      preload="auto"
                      controls
                      className="my-4 w-full"
                    ></audio>
                  )}
                </div>
              )}
              {isRecording && (
                <div className="bg-[#F1EFF2] text-center p-4 rounded-xl w-[50%] mx-auto mt-4">
                  <p className="text-h5r mt-4">
                    Timer: {recordingTime} seconds
                  </p>
                  <ProgressBar progress={recordingProgress} />
                  <button
                    onClick={stopRecording}
                    className="text-bodyr text-neutrals-1 p-2 mt-2 rounded-lg border border-[1px]-[#D9D9D9] hover:scale-105"
                  >
                    Stop Recording
                  </button>
                </div>
              )}
              <SpeakingQuestionStates question={question} />
              <div className="flex items-center justify-center mt-4 gap-6 cursor-pointer">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setShowScoreBoard(!showScoreBoard)}
                >
                  <img
                    src={ReadAloudArrowSvg}
                    alt="arrow"
                    className={`transition-transform duration-300 ${showScoreBoard ? 'rotate-180' : 'rotate-0'}`}
                  />
                  <p className="text-bodyr text-info ml-2">
                    {!showScoreBoard ? 'Show' : 'Hide'} Score Board
                  </p>
                </div>
                <div className="relative">
                  <button
                    className="flex text-bodyr text-neutrals-2 items-center p-2 border border-1px-neutrals-2 rounded-lg"
                    onClick={() => setShowAnswerPopup(!showAnswerPopup)}
                  >
                    <img
                      src={WeeklyPredictionKeywordIcon}
                      alt="keyword"
                      className="mr-2"
                    />
                    Answer
                  </button>
                  {showAnswerPopup && (
                    <div
                      className="absolute bottom-12 mt-2 left-0 bg-white shadow-lg rounded-lg p-4 z-50"
                      style={{ minWidth: '18rem' }}
                      onMouseEnter={() => setShowAnswerPopup(true)} // Keep the popup open when hovered
                      onMouseLeave={() => setShowAnswerPopup(false)} // Close the popup when leaving
                    >
                      <p className="font-medium text-green-400">
                        {question?.description}
                      </p>
                    </div>
                  )}
                </div>
              </div>{' '}
            </div>

            <div className="flex w-full xl:w-[50%] justify-center gap-4 mx-auto mt-4">
              <button
                className={`flex items-center px-4 py-2 rounded-xl ${isFirstQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
                disabled={isFirstQuestion()}
                onClick={displayPreviousQuestion}
              >
                <PreviousArrowSvg
                  fill={isFirstQuestion() ? 'gray' : 'white'}
                  className="md:mr-2"
                />
                <p className="hidden md:block text-bodyr">Previous</p>
              </button>
              <div className="flex text-white">
                <button
                  className="flex items-center px-4 py-2 bg-info rounded-xl cursor-pointer"
                  onClick={resetState}
                >
                  <p className="hidden md:block text-bodyr text-white">
                    Try Again
                  </p>
                  <img src={TryAgainArrowSvg} alt="again" className="md:ml-2" />
                </button>
              </div>

              <div>
                <select
                  className="px-2 md:px-4 py-2 rounded-xl border border-[1px]-[#D9D9D9] overflow-y"
                  defaultValue={question?.id}
                  onChange={(e) => handleQuestionChange(e.target.value)}
                >
                  {allMockQuestions.map((mockQuestion, index) => (
                    <option
                      key={mockQuestion.id}
                      value={mockQuestion.id}
                      className="text-bodyr"
                    >
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>

              <button
                className={`flex items-center px-4 py-2 rounded-xl ${isLastQuestion() ? 'bg-[#F0F0F0] text-gray-400 cursor-not-allowed' : 'bg-info text-white cursor-pointer'}`}
                onClick={displayNextQuestion}
                disabled={isLastQuestion()}
              >
                <p className="hidden md:block text-bodyr">Next</p>
                <NextArrowSvg
                  fill={isLastQuestion() ? 'gray' : 'white'}
                  className="md:ml-2"
                />
              </button>
            </div>
          </div>
          {isSubmitted && isEnabled && (
            <div ref={answerSectionRef}>
              {isLoadingSpeakingResult ? (
                <div className="mt-4">
                  <LoadingMessage message="Calculating your score..." />
                </div>
              ) : (
                <>
                  <SpeakingFinalResult finalResult={speakingFinalMarks} />
                  <div
                    className="bg-gray-100 rounded-xl mt-4 p-4"
                    dangerouslySetInnerHTML={createMarkup(highlightedPagagraph)}
                  />
                  <div className="grid grid-row-2 lg:grid-cols-2 gap-4 mt-4">
                    <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                      <p className="text-h4m">Original Audio</p>
                      <audio
                        controls
                        src={question?.audio}
                        preload="auto"
                        className="my-4 w-full"
                      />
                      <p>
                        <span className="font-bold">Answer: </span>
                        <span>{question?.description}</span>
                      </p>
                    </div>
                    <div className="col-span-1 bg-[#F1EFF2] p-6 rounded-xl">
                      <p className="text-h4m">Your Audio</p>
                      {audioBlob && (
                        <>
                          <audio
                            controls
                            src={URL.createObjectURL(audioBlob)}
                            className="my-4 w-full"
                          />
                          <p>
                            <span className="font-bold">Your answer: </span>
                            <span>{transcript}</span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default RepeatSentenceQuestion
