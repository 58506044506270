import React from 'react'
import { labelOptions } from '../constants'
import PracticeMockQuestion from '../models/practiceMockQuestion.model'

interface SelectLabelPopupQuestionStateProps {
  activePopup: boolean // Boolean to control visibility
  setActivePopup: (value: boolean) => void // Function to toggle visibility
  handleLabelSelect: (value: number, question?: PracticeMockQuestion) => void // Function to handle selection
  question: PracticeMockQuestion | undefined
}

const SelectLabelPopupQuestionState: React.FC<
  SelectLabelPopupQuestionStateProps
> = ({ activePopup, setActivePopup, handleLabelSelect, question }) => {
  if (!activePopup) {
    // Return null when the popup should not be visible
    return null
  }

  return (
    <div
      className="absolute bottom-full mt-2 right-0 bg-white shadow-lg rounded-lg p-3 z-50 w-48"
      style={{ minWidth: '12rem' }}
      onMouseEnter={() => setActivePopup(true)} // Keep the popup open when hovered
      onMouseLeave={() => setActivePopup(false)} // Close the popup when leaving
    >
      <p className="font-medium text-gray-800 py-1 px-2">Select Label:</p>
      <ul className="list-none m-0 p-0">
        {labelOptions.map((labelOption) => (
          <li
            key={labelOption.id}
            style={{
              color: labelOption.color,
            }}
            className="py-1 px-2 text-bodyr cursor-pointer hover:bg-gray-100 hover:rounded-md hover:px-2 transition-all duration-200"
            onClick={(e) => {
              e.stopPropagation() // Prevent click event from propagating
              handleLabelSelect(labelOption.value, question) // Pass `value` and `question`
            }}
          >
            {labelOption.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SelectLabelPopupQuestionState
