import { useState, useEffect } from 'react'
import PTEVideosSection from '../components/PTEVideosPage/PTEVideosSection'
import {
  getYoutubeType,
  getYoutubeVideosByType,
} from '../services/media.services'
import YoutubeVideoType from '../models/youtubeVideoType.model'
import YoutubeVideo from '../models/youtubeVideo.model'

const PTEVideosPage = () => {
  const [youtubeVideoTypes, setYoutubeVideoTypes] = useState<
    YoutubeVideoType[]
  >([])
  const [youtubeVideos, setYoutubeVideos] = useState<YoutubeVideo[]>([])
  const [selectedYoutubeVideoType, setSelectedYoutubeVideoType] = useState<
    string | undefined
  >()

  useEffect(() => {
    const fetchYoutubeTypes = async () => {
      try {
        const response = await getYoutubeType()
        setYoutubeVideoTypes(response.data.youtubeInfo)
        if (response.data.youtubeInfo.length > 0) {
          setSelectedYoutubeVideoType(response.data.youtubeInfo[0].typeName)
        }
      } catch (error) {
        console.error('Error fetching YouTube types:', error)
      }
    }

    const fetchYoutubeVideos = async () => {
      try {
        const response = await getYoutubeVideosByType()
        setYoutubeVideos(response.data.resultInfo[0].videos)
      } catch (error) {
        console.error('Error fetching YouTube videos:', error)
      }
    }

    fetchYoutubeTypes()
    fetchYoutubeVideos()
  }, [])

  // const handleYoutubeVideoTypeChange = (youtubeVideoType: string) => {
  //   setSelectedYoutubeVideoType(youtubeVideoType)
  // }

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          Community / PTE Videos / {selectedYoutubeVideoType}
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        <PTEVideosSection
          youtubeVideoTypes={youtubeVideoTypes}
          youtubeVideos={youtubeVideos}
        />
      </div>
    </>
  )
}

export default PTEVideosPage
