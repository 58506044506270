import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'; // Import Swiper types

import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import '../../styles/Landingpage/custom-swiper-pagination.css'

import arrowRightIcon from '../../images/Homepage/arrow-right.svg'
import TestHistory from './TestHistory'
import VerifiedUsers from '../../models/verifiedUsers.model'
import { userAvatars } from '../../constants'
import { useNavigate } from 'react-router-dom'
import Path from '../../routes/Path'

interface HeroSectionProps {
  verifiedUsers: VerifiedUsers[]
}

const HeroSection: React.FC<HeroSectionProps> = ({ verifiedUsers }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [modalId, setModalId] = useState<number | null>(null)
  const navigate = useNavigate()

  const toggleModal = (id?: number) => {
    setShowModal(!showModal)
    setModalId(id ?? null)
  }

  return (
    <div className="w-full block lg:grid lg:grid-cols-5 justify-center">
      {/* Left Section */}
      <div className="col-span-2 p-8 lg:pr-4 justify-center content-center">
        <h1 className="text-h1 text-black mb-4">
          Our Verified Successful Students
        </h1>
        <p className="text-bodyr text-neutrals-2 mb-6">
          Welcome to our PTE test site in Australia, where you can discover the
          success stories of our verified students and see how our tailored
          programs have helped them excel in their exams.
        </p>

        {/* Student Swiper */}
        <div className="hidden lg:flex xl:flex-row flex-col xl:items-center gap-2 select-none">
          {/* Render avatars */}
          <div className="flex -space-x-2">
            {userAvatars.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`User ${index + 1}`}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 border-white"
              />
            ))}

            {/* Overlay circle for extra count */}
            <div className="w-10 h-10 bg-gray-800 text-white text-sm flex items-center justify-center rounded-full border-2 border-white">
              +100
            </div>
          </div>
          {/* Text */}
          <span className="text-neutrals-2 text-bodyr">
            students using PTEMaster.
          </span>
        </div>

        {/* Start Button */}
        <div className="flex justify-between items-center">
          <button className="flex items-center text-bodyr text-white bg-info h-12 px-6 rounded-xl hover:scale-105">
            <img src={arrowRightIcon} alt="Arrow Right" className="mr-2" />
            Let's get started
          </button>
          <div className="hidden lg:block">
            <svg
              width="114"
              height="126"
              viewBox="0 0 114 126"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M96.9183 80.9258C83.4468 90.7994 62.0981 99.1933 47.9514 94.9537C41.5333 93.0304 29.0387 81.0383 37.4171 71.2951C46.5972 60.62 54.0035 79.0014 44.0858 86.5269C35.3163 93.181 25.8497 89.8548 19.7217 83.837C8.89203 73.2014 5.18355 58.0948 8.05521 40.2774"
                stroke="#2E2E2E"
                strokeWidth="2"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="animated-path"
              />
              <path
                d="M110.803 80.2536C108.235 83.1224 105.565 85.8658 102.984 88.7168C102.453 89.3028 101.104 91.5316 100.535 91.6959C99.7374 91.9257 98.9594 89.5583 98.7636 89.0164C97.7606 86.2372 97.0187 83.3398 96.2672 80.422C96.1798 80.0831 95.459 78.1268 95.6659 77.84C96.4523 76.7498 98.5969 77.3784 99.4036 77.4346C102.925 77.6802 107.775 77.9032 110.988 79.1967"
                stroke="#2E2E2E"
                strokeWidth="2.93433"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="animated-triangle"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Score Report Swiper */}
      <div className="pl-8 pt-0 lg:!p-0 col-span-3 justify-center content-center">
        {verifiedUsers.length !== 0 ? (
          <Swiper
            modules={[Autoplay, Pagination]}
            spaceBetween={12}
            loop={false}
            pagination={{
              clickable: true,
            }}
            speed={1000}
            onSlideChange={(swiper: SwiperType) =>
              setActiveIndex(swiper.realIndex)
            }
            slidesPerView="auto"
            breakpoints={{
              0: {
                // Mobile
                slidesPerView: 'auto',
                spaceBetween: 16,
              },
              768: {
                // Tablet
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                // Desktop
                slidesPerView: 2,
                spaceBetween: 24,
              },
            }}
            className="!pb-10"
          >
            {verifiedUsers.map((student, index) => (
              <SwiperSlide
                key={student.userId}
                className="rounded-xl bg-white flex flex-col justify-between w-full sm:!w-[311px]"
              >
                {/* White Card (Score Report) */}
                <div className="bg-info py-5 px-8 sm:py-7 sm:px-12 rounded-t-lg w-full h-[520px] sm:h-80 flex items-center justify-center">
                  <img
                    src={student.adminUpload}
                    alt="Student Score Report"
                    className="rounded-md object-cover w-full h-full max-h-[520px] sm:max-h-80"
                  />
                </div>

                {/* Student Info Section */}
                <div className="flex gap-4 bg-white p-3 pb-6 rounded-b-lg text-center border-[1px] border-neutrals-5">
                  {/* Avatar and Name */}
                  <img
                    src={student.image}
                    alt={student.name}
                    className="w-10 h-10 sm:w-12 sm:h-12 rounded-full border-2 border-white object-cover"
                  />
                  <div className="flex flex-col items-start gap-2 w-full">
                    {/* Name and Target Score */}
                    <div>
                      <h5 className="text-bodyr sm:text-h5m !text-left">
                        {student.name}
                      </h5>
                      <p className="text-bodyr">Target score: {100}</p>
                    </div>
                    {/* Buttons */}
                    <div className="flex justify-between gap-2 mt-auto w-full">
                      <button
                        onClick={() => {
                          if (index === activeIndex) toggleModal(student.userId)
                        }}
                        className="flex-1 bg-info text-white text-bodyr rounded-lg px-2 py-1 sm:px-4 sm:py-1 hover:scale-105"
                      >
                        Test Results
                      </button>
                      <button
                        onClick={() => navigate(Path.shop.path)}
                        className="flex-1 bg-white border-2 border-neutrals-3 text-neutrals-1 text-bodyr rounded-lg px-2 py-1 sm:px-4 sm:py-1 hover:scale-105"
                      >
                        Mock Tests
                      </button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="h-40 flex justify-center items-center">
            <div
              className="p-4 text-sm text-red-800 rounded-lg bg-red-200 border border-red-600"
              role="alert"
            >
              <span className="font-medium">
                There aren't any verified students here!
              </span>
            </div>
          </div>
        )}
        {showModal && modalId && (
          <TestHistory id={modalId} onClose={toggleModal} />
        )}
      </div>
    </div>
  )
}

export default HeroSection
