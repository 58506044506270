import { Link } from 'react-router-dom'
import SupportSection from '../components/SupportPage/SupportSection'

const SupportPage = () => {
  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Support
        </p>
      </div>
      <div className="w-full lg:min-h-[56rem] h-fit lg:p-[4rem] p-[1rem] bg-[#F0F7FF] rounded-l-lg">
        <SupportSection />
      </div>
    </>
  )
}

export default SupportPage
