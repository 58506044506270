import { useState, useEffect } from 'react'
import { ReactComponent as DropDownArrowIcon } from '../../images/LastMinuteStudy/lmt-dropdown-arrow-icon.svg'
import PracticeTokenSection from './PracticeTokenSection'
import MockTestSection from './MockTestSection'
import YourPurchaseSection from './YourPurchaseSection'
import {
  getAccountDetailsForMyPurchase,
  getMockTestListToUnlock,
  getPurchaseBundle,
} from '../../services/purchase.services'
import { useAuth } from '../../providers/AuthProvider'
import PracticeToken from '../../models/practiceToken.model'
import PurchasedBundle from '../../models/purchasedBundle.model'
import Test from '../../models/test.model'
import UserTokenPack from '../../models/userTokenPack.model'
import UserTokenPackMock from '../../models/userTokenPackMock.model'
import LoadingMessage from '../Items/LoadingMessage'

type SelectCategoryFunction = (category: string) => void

const PurchaseSection: React.FC<{
  onSelectCategory: SelectCategoryFunction
}> = ({ onSelectCategory }) => {
  const { userProfile } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState(1)
  const [isOpen, setIsOpen] = useState(false)

  // Practice Token Section State
  const [timer, setTimer] = useState(0)
  const [dailyTokenCredit, setDailyTokenCredit] = useState(0)
  const [pramotionalTokenCredit, setPramotionalTokenCredit] = useState(0)
  const [totalToken, setTotalToken] = useState(0)
  const [packageInfo, setPackageInfo] = useState<PracticeToken[]>([])

  // Mock Test Section State
  const [mockTests, setMockTests] = useState<Test[]>([])
  const [mockCredits, setMockCredits] = useState(0)

  // Bundle State
  const [bundles, setBundles] = useState<PurchasedBundle[]>([])
  const [userTokenPacks, setUserTokenPacks] = useState<UserTokenPack[]>([])
  const [userTokenPackMocks, setUserTokenPackMocks] = useState<
    UserTokenPackMock[]
  >([])

  useEffect(() => {
    const fetchData = async () => {
      if (userProfile) {
        try {
          if (selectedCategory === 1) {
            const response = await getAccountDetailsForMyPurchase(
              userProfile.userId,
            )
            if (response.data.success) {
              setTimer(response.data.diff)
              setDailyTokenCredit(response.data.user.daily_token_credit)
              setPramotionalTokenCredit(
                response.data.user.pramotional_token_credit,
              )
              setTotalToken(
                response.data.totalCredits +
                  response.data.user.daily_token_credit +
                  response.data.user.pramotional_token_credit,
              )
              setPackageInfo(response.data.packageInfo)
            }
          } else if (selectedCategory === 2) {
            const response = await getMockTestListToUnlock(
              userProfile.userId,
              0,
            )
            if (response.data.success) {
              setMockTests(response.data.test)
              setMockCredits(response.data.mockCredits)
            }
          } else {
            const response = await getPurchaseBundle(userProfile.userId)
            if (response.data.success) {
              setBundles(response.data.data.bundle)
              setUserTokenPacks(response.data.data.userTokenPack)
              setUserTokenPackMocks(response.data.data.mockCredits)
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchData()
  }, [selectedCategory, userProfile])

  // Convert total seconds to days, hours, minutes, and remaining seconds
  const formatTimer = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 60 * 60))
    const hoursLeft = Math.floor(seconds - days * 86400)
    const hours = Math.floor(hoursLeft / 3600)
    const minutesLeft = Math.floor(hoursLeft - hours * 3600)
    const minutes = Math.floor(minutesLeft / 60)
    const remainingSeconds = seconds % 60
    return `${days} day${days > 1 ? 's' : ''} ${hours}:${minutes}:${remainingSeconds}`
  }

  useEffect(() => {
    const interval =
      timer > 0
        ? setInterval(() => setTimer((prev) => prev - 1), 1000)
        : undefined
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [timer])

  const categories = [
    { name: 'Practice Token', id: 1 },
    { name: 'Mock Test', id: 2 },
    { name: 'Your Purchase', id: 3 },
  ]

  const handleItemClick = (category: (typeof categories)[number]) => {
    if (category.id !== selectedCategory) {
      setSelectedCategory(category.id)
      setIsOpen(false)
      onSelectCategory(category.name)
      setIsLoading(true)
    }
  }

  const currentCategoryName =
    categories.find((category) => category.id === selectedCategory)?.name ||
    'Select Category'

  return (
    <div className="purchase-section font-landingPrimary">
      <p className="text-h1 mb-[0.4rem]">Purchase</p>
      {selectedCategory === 1 && (
        <div className="purchase-section__mock-test-info w-full lg:h-[1.875rem] h-full flex flex-wrap gap-3">
          <div className="purchase-section__practice-token-info__daily-credit text-bodyr text-secondary-11 bg-secondary-1 px-3 py-1 rounded-xl flex justify-center items-center">
            <p>Daily credit: {dailyTokenCredit}</p>
          </div>
          <div className="purchase-section__practice-token-info__promotional-credit text-bodyr text-secondary-11 bg-secondary-1 px-3 py-1 rounded-xl flex justify-center items-center">
            <p>Promotional credit: {pramotionalTokenCredit}</p>
          </div>
          <div className="purchase-section__practice-token-info__total-token text-bodyr text-secondary-11 bg-secondary-1 px-3 py-1 rounded-xl flex justify-center items-center">
            <p>Total token: {totalToken}</p>
          </div>
          <div className="purchase-section__practice-token-info__vip-token text-bodyr text-primary-6 bg-[#F0F7FF] px-3 py-1 rounded-xl flex justify-center items-center">
            <p>VIP Token: {formatTimer(timer)}</p>
          </div>
        </div>
      )}
      {selectedCategory === 2 && (
        <div className="purchase-section__mock-test-info w-full lg:h-[1.875rem] h-full flex flex-wrap gap-3">
          <div className="purchase-section__mock-test-info__credit text-bodyr text-secondary-11 bg-secondary-1 px-3 py-1 rounded-xl flex justify-center items-center">
            <p>Credit: {mockCredits}</p>
          </div>
        </div>
      )}
      <div className="lg:hidden lg:mt-[2rem] my-[2rem] md:w-full w-full relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full h-[2rem] py-[1.2rem] px-[1rem] bg-white border border-1 rounded-lg text-h5r font-landingPrimary flex justify-between items-center"
        >
          {currentCategoryName}
          <DropDownArrowIcon fill="#00000040" />
        </button>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white rounded-md shadow-lg">
            {categories.map((category) => (
              <li
                key={category.id}
                onClick={() => handleItemClick(category)}
                className="cursor-pointer px-[12px] py-[5px] text-h5r font-landingPrimary hover:bg-info hover:text-white"
              >
                {category.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <nav className="hidden lg:flex justify-between items-center bg-[#FAFAFA] my-[2rem] lg:w-[23.563rem] w-full lg:h-[2.8rem] h-[4rem] lg:p-0 p-[0.4rem] rounded-lg text-center">
        <ul className="flex space-x-4 text-h5r font-landingPrimary text-neutrals-2 w-full h-full">
          {categories.map((category) => (
            <li
              key={category.id}
              onClick={() => handleItemClick(category)}
              className={`cursor-pointer flex items-center justify-center flex-grow rounded-lg ${selectedCategory === category.id ? 'text-h5r text-neutrals-4 bg-info' : ''}`}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </nav>
      {isLoading ? (
        <LoadingMessage message="Loading information ..." />
      ) : (
        <>
          {selectedCategory === 1 && (
            <PracticeTokenSection practiceTokens={packageInfo} />
          )}
          {selectedCategory === 2 && <MockTestSection mockTests={mockTests} />}
          {selectedCategory === 3 && (
            <YourPurchaseSection
              bundle={bundles}
              userTokenPacks={userTokenPacks}
              userTokenPackMocks={userTokenPackMocks}
            />
          )}
        </>
      )}
    </div>
  )
}

export default PurchaseSection
