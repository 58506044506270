import React from 'react'
import { Link } from 'react-router-dom'
import HowItWorksBanner from '../../images/Landingpage/how-it-works-banner.png'
import Vector from '../../images/Landingpage/vector.png'
import Path from '../../routes/Path'

const HowItWorksSection: React.FC = () => {
  return (
    <div className="hiw-section flex xl:flex-row flex-col-reverse w-full h-full bg-[#F5F5F5] lg:px-[4rem] px-[1rem] xl:py-0 py-[2rem]">
      <div className="w-full">
        <div className="bg-white lg:w-full w-full h-fit my-[3rem] sm:p-[2rem] rounded-lg flex justify-between items-center">
          <img
            src={HowItWorksBanner}
            alt="How it works banner"
            className="w-full"
          />
        </div>
      </div>
      <div className="w-full content-center lg:px-[6rem] md:px-[4rem] px-[2rem]">
        <p className="font-landingLogo text-[2.375rem] font-extrabold leading-[2.875rem]">
          How It Works
        </p>
        <p className="font-bodyr my-[1rem] text-justify">
          Our mock tests are built based on the original algorithms of the PTE
          Tests. All information about the Pearson's scoring system can be found
          at the end of this post. It has been a long time since anyone has come
          up with a simple enough system for students to simply take a test
          anywhere, anytime and receive their score automatically.
        </p>
        <Link
          to={Path.landing.howItWorks.path}
          className="lg:w-[8.5rem] w-1/2 text-sm text-white mt-6 py-3 px-2 rounded-xl flex items-center justify-center hover:scale-105"
          style={{
            background: 'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
          }}
        >
          <img src={Vector} alt="vector icon" className="mr-2" />
          See more
        </Link>
      </div>
    </div>
  )
}

export default HowItWorksSection
