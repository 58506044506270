import React from 'react'
import TextInput from '../../components/FormElements/TextInput'
import TextArea from '../../components/FormElements/TextArea'

const LandingSupportPage: React.FC = () => {
  return (
    <div className="my-[80px] flex justify-center items-center p-6">
      <div className="w-full lg:max-w-[70%] bg-white rounded-lg lg:p-8 flex flex-col lg:flex-row gap-8">
        {/* Contact Information Section */}
        <div className="w-full md:w-1/2">
          <h2 className="text-h1 mb-[32px]">Support</h2>
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/2">
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <TextInput placeholder="Full name" label="Full name" />
              <TextInput
                placeholder="Enter email address"
                type="email"
                label="Email"
              />
            </div>
            <TextArea label="Message" placeholder="Type here..." />

            <button
              type="submit"
              className="text-white !mt-[32px] px-6 py-[10px] rounded-[8px] w-full"
              style={{
                background:
                  'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
              }}
            >
              Submit Form
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LandingSupportPage
