import React from 'react'
import { Link } from 'react-router-dom'
import AcademicCTA from '../../../images/Landingpage/academic-cta.png'
import Path from '../../../routes/Path'

const SkillCTASection: React.FC = () => {
  return (
    <div
      className="max-w-[90%] lg:w-2/3 rounded-xl overflow-hidden mx-auto my-8"
      style={{
        background: 'linear-gradient(360deg, #2054E9 -0.54%, #2C62FF 100%)',
      }}
    >
      <div className="flex flex-col lg:flex-row justify-between items-center lg:px-12 lg:pr-0 px-6 lg:py-0 py-6  relative">
        {/* Text Content */}
        <div className="text-center lg:text-left lg:w-1/2">
          <p className="text-h1 text-white font-bold mb-4 capitalize">
            ONLINE PTE PRACTICE
          </p>
          <p className="text-bodyr text-[#BFBFBF] mb-6">
            One of the best PTE Academic Exam preparation tools to evaluate your
            performance
          </p>
          <Link
            to={Path.landing.login.path}
            className="inline-block bg-white text-blue-600 font-medium py-2 px-6 rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
          >
            Get Started Now
          </Link>
        </div>

        {/* Background Image */}
        <div className="lg:block hidden mt-6 lg:mt-0 lg:w-[250px] h-auto">
          <img
            src={AcademicCTA}
            alt="PTE Academic CTA"
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  )
}

export default SkillCTASection
