import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PlanPageSection from '../components/ShopPage/PlanPageSection'
import { getTokenPackage } from '../services/shop.services'
import { useAuth } from '../providers/AuthProvider'

const PlanPage = () => {
  const { userProfile } = useAuth()
  const [tokenPackage, setTokenPackage] = useState(null)

  useEffect(() => {
    const userId = userProfile?.userId

    if (userId) {
      getTokenPackage()
        .then((response) => {
          setTokenPackage(response.data)
        })
        .catch((error) => {
          console.error('Error fetching shop deals:', error)
        })
    }
  }, [userProfile?.userId])

  return (
    <>
      <div className="pl-6 navigation my-[1rem]">
        <p className="text-bodyr font-landingPrimary text-neutrals-2">
          <Link to="/homepage">Home</Link> / Plan
        </p>
      </div>
      <div className="w-full lg:min-h-[47.5rem] h-fit lg:p-[4rem] p-[1rem] bg-white rounded-l-lg">
        {tokenPackage && <PlanPageSection tokenPackage={tokenPackage} />}
      </div>
    </>
  )
}

export default PlanPage
